<template>
    <Header />
    <Loading v-if="$store.state.loading" :fixed="true"/>
    <router-view />
    <div class="mb-12"></div>
    <Modal z="1000" v-if="showModal" :iconClasses="modalInfo.danger? 'bg-red-100 text-red-500' : 'bg-green-100 text-green-500'" :confirm="modalInfo.confirm" @close="showModal = false" :header="modalInfo.header" :icon="danger? 'danger' : 'info'" :body="modalInfo.body" :mask-closable="!modalInfo.confirm" :ok-text="modalInfo.okText" :ok-class="modalInfo.danger ? 'bg-red-700 text-white  hover:bg-red-800' : 'bg-green-600 text-white  hover:bg-green-700'" @ok="modalCallback" />
</template>

<style lang="scss">
:root {
  --darkGreen: #36823f;
  --green: #419d48;
}
.mt-s-lg {
  margin-top: 6vw;
}
.text-green {
  color: var(--green);
}
header {
  padding: 20px 0;
  background: green;
  width: 100%;
  background: linear-gradient(to bottom, var(--darkGreen), var(--green));
}
    .tab-content {
        max-height: 0;
        -webkit-transition: max-height .35s;
        -o-transition: max-height .35s;
        transition: max-height .35s;
    }
    /* :checked - resize to full height */
    .tab > input:checked ~ .tab-content {
        max-height: 300vh;
    }
    /* Label formatting when open */
    .tab > input:checked + label{
        /*@apply text-xl p-5 border-l-2 border-indigo-500 bg-gray-100 text-indigo*/
        border-left-width: 2px; /*.border-l-2*/
        border-color: #4e9654; /*.border-indigo*/
        background-color: #e1ece2; /*.bg-gray-100 */
        color: #4e9654; /*.text-indigo*/
    }
    /* Icon */
    .tab > input + label::after {
        float:right;
        right: 0;
        top: 0;
        display: block;
        width: 1.5em;
        height: 1.5em;
        line-height: 1.5;
        font-size: 1.25rem;
        text-align: center;
        -webkit-transition: all .35s;
        -o-transition: all .35s;
        transition: all .35s;
    }
    /* Icon formatting - closed */
    .tab > input[type=checkbox] + label::after {
        content: "+";
        font-weight:bold; /*.font-bold*/
        border-width: 1px; /*.border*/
        border-radius: 9999px; /*.rounded-full */
        border-color: #b8c2cc; /*.border-grey*/
    }
    .tab > input[type=radio] + label::after {
        content: "\25BE";
        font-weight:bold; /*.font-bold*/
        border-width: 1px; /*.border*/
        border-radius: 9999px; /*.rounded-full */
        border-color: #b8c2cc; /*.border-grey*/
    }
    /* Icon formatting - open */
    .tab > input[type=checkbox]:checked + label::after {
        transform: rotate(315deg);
        background-color: #4e9654; /*.bg-indigo*/
        color: #f8fafc; /*.text-grey-lightest*/
    }
    .tab > input[type=radio]:checked + label::after {
        transform: rotateX(180deg);
        background-color: #4e9654; /*.bg-indigo*/
        color: #f8fafc; /*.text-grey-lightest*/
    }
</style>


<script>
import Header from "@/components/Header";
import Modal from "@/components/Modal";
import Loading from "@/components/Loading";
export default {
    components: {
        Header,
        Modal,
        Loading,
    },
    watch:{
        $route(val){
            // console.log('ROUTE CHANGED')
            window.scrollTo(0, 0)
        }
    },
    computed: {
        modalInfo(){
            return this.$store.state.Modal
        },
        showModal: {
            get() {
                return this.$store.state.Modal.show
            },
            set(val) {
                if (!val) this.$store.commit("CLOSE_MODALS")
            },
        },
    },
    methods:{
        modalCallback(){
            if (typeof this.modalInfo.callback === 'function') this.modalInfo.callback()
        }
    },
    data: () => ({}),
};
</script>

