export default [
  {
    path: "/dealer/home",
    name: "Dealer Home",
    component: "@/views/dealer/Home.vue",
    meta: { requiresAuth: true, storeAdminOnly: true },
  },
  {
    path: "/dealerorders",
    name: "Dealer Orders Portal",
    component: () =>
      import(/* webpackChunkName: "..." */ "@/views/DealerOrders.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: '/dealer/home',
    name: 'Dealer Home',
    component: '@/views/dealer/Home.vue',
    meta: { requiresAuth: true, storeAdminOnly: true  }
},
];
