<template>
    <PageTitle>
        <div class="flex justify-between items-center px-4 sm:px-0">
            <div>
                <router-link to="/products" class="text-blue-500 inline-flex cursor-pointer hover:text-blue-800">
                    <Icons i="arrow-left" class="w-4 h-4 hover:round" />
                </router-link>
                <span class="text-gray-400 ml-3" v-if="editMode">
                    Edit Product:
                    <span class="text-gray-500 ml-2">{{item.name}}</span>
                </span> 
                <span class="text-gray-400 ml-3" v-else>New Product</span>

            </div>
            <div v-if="editMode">
                <Button label="Delete Product" size="small" icon="remove" style="height:30px;" type="danger" @click="deleteProduct" />
            </div>
        </div>
    </PageTitle>

    <div class="mt-10 container px-4 mx-auto mb-24 " v-if="item.id || init">

        <div class="md:flex md:space-x-4" style="max-width:800px;">

            <div class="md:w-1/4 mb-10 flex-1">
                <FormSelect label="Category" v-model="category" :options="categories" :required="true" selectClass="bg-gray-300 text-gray-700" :show-errors="showErrors" />
            </div>


           <div class="md:w-1/4 mb-10 flex-1">
                <FormSelect label="Canonical (Advanced)" v-model="canonical" :options="allProducts" :required="true" selectClass="bg-gray-300 text-gray-700" :show-errors="showErrors" />
            </div>


        </div>
        
        <label class="block text-sm font-medium text-gray-700 text-xl mb-6">General Details</label>

        <div class="md:w-4/5">
            <ul class="text-gray-500 text-sm space-y-4">
                <li class="md:flex  space-x-3">
                    <div class="flex-1">
                        <FormInput :show-errors="showErrors" :required="true" label="Units of Measure" placeholder="UA,CY...etc." v-model="item.measure" />
                    </div>
                    <div class="flex-1">
                        <FormInput :show-errors="showErrors" :required="true" label="Product SKU#" v-model="item.sku" />
                    </div>
                    <div class="flex-1">
                        <FormInput :show-errors="showErrors" :required="true" label="Memo (Internal use only) " v-model="item.memo" />
                    </div>
                    
                </li>
                <li class="md:flex  space-x-3">
                    <div class="flex-1">
                        <FormInput :show-errors="showErrors" :required="true" label="Product Name" v-model="item.name" />
                    </div>
                    <div class="flex-1">
                        <FormInput type="number" :show-errors="showErrors" :required="true" label="Product Selling Price" v-model="item.price" />
                    </div>
                    
                </li>

                <li class="flex  space-x-3">
                    <div class="flex-1">
                        <label class="block text-sm font-medium text-gray-700">Description</label>
                        <textarea class="w-full inline-flex ring-green-500 mt-1 border-gray-300 rounded-md overflow-hidden " style="border-width:1px; border-style:solid;" v-model="item.description" />
                    </div>
                    <div class="flex-1">
                        <label class="block text-sm font-medium text-gray-700">Uses</label>
                        <textarea class="w-full inline-flex ring-green-500 mt-1 border-gray-300 rounded-md overflow-hidden " style="border-width:1px; border-style:solid;" v-model="item.uses" />
                    </div>
                </li>



                <li class="flex  space-x-3">
                    <div class="flex-1">
                        <FormInput :show-errors="showErrors" label="Amazon Sku" v-model="item.amazon_sku" />
                    </div>
                    <div class="flex-1">
                        <FormInput :show-errors="showErrors" label="Costco Sku" v-model="item.costco_sku" />
                    </div>
                </li>
                <li class="flex  space-x-3">
                    <div class="flex-1">
                        <FormInput :show-errors="showErrors" label="walmart_sku" v-model="item.walmart_sku" />
                    </div>
                </li>





                <li>
                    <div class="flex-1">
                        <div class="flex-1 mt-14">
                            <label class="block text-sm font-medium text-gray-700 text-xl mb-6 pb-2 border-gray-300" style="border-bottom-width:1px;">Product Setting</label>
                            <div class="mt-3 flex">
                                <div>
                                    <label class="block mb-2 text-sm font-medium text-gray-700">Multi-Bag Discount</label>                        
                                    <div>
                                        <Toggle v-model="item.bagDiscount" activeText="Yes" inactiveText="No" />
                                    </div>
                                </div>

                                <div class="ml-5">
                                    <label class="block mb-2 text-sm font-medium text-gray-700">Counts as a bag</label>                        
                                    <div>
                                        <Toggle v-model="item.countAsBag" activeText="Yes" inactiveText="No" />
                                    </div>
                                </div>

                                <div class="ml-5">
                                    <label class="block mb-2 text-sm font-medium text-gray-700">Show on front end</label>                        
                                    <div>
                                        <Toggle v-model="item.showOnFront" activeText="Yes" inactiveText="No" />
                                    </div>
                                </div>

                                <div class="ml-5">
                                    <label class="block mb-2 text-sm font-medium text-gray-700">Type of product</label> 
                                    <select v-model="item.vendor">
                                        <option value="LessMess" selected>Less mess</option>
                                        <option value="Salt">Salt</option>
                                    </select>
                                </div>

                            </div>
                        </div>
                    </div>
                </li>



            </ul>
        </div>
        
        <label class="block text-sm font-medium text-gray-700 text-xl mb-6 mt-10">General Details <span class="text-blue-700">(French)</span></label>

        <div class="md:w-4/5">
            <ul class="text-gray-500 text-sm space-y-4">
                <li class="md:flex  space-x-3">
                    <div class="flex-1">
                        <FormInput :show-errors="showErrors" :required="true" label="Product Name" v-model="item.name_fr" />
                    </div>
                </li>

                <li class="flex  space-x-3">
                    <div class="flex-1">
                        <label class="block text-sm font-medium text-gray-700">Description</label>
                        <textarea class="w-full inline-flex ring-green-500 mt-1 border-gray-300 rounded-md overflow-hidden " style="border-width:1px; border-style:solid;" v-model="item.description_fr" />
                    </div>
                    <div class="flex-1">
                        <label class="block text-sm font-medium text-gray-700">Uses</label>
                        <textarea class="w-full inline-flex ring-green-500 mt-1 border-gray-300 rounded-md overflow-hidden " style="border-width:1px; border-style:solid;" v-model="item.uses_fr" />
                    </div>
                    
                </li>
            </ul>
        </div>


        <label class="block text-sm font-medium text-gray-700 text-xl mb-6 mt-10">General Details <span class="text-blue-700">(USA)</span></label>

        <div class="md:w-4/5">
            <ul class="text-gray-500 text-sm space-y-4">
                <li class="md:flex space-x-3">
                    <div class="flex-1">
                        <FormInput :show-errors="showErrors" :required="true" label="Product Name" v-model="item.name_en_usa" />
                    </div>
                </li>

                <li class="flex  space-x-3">
                    <div class="flex-1">
                        <label class="block text-sm font-medium text-gray-700">Description</label>
                        <textarea class="w-full inline-flex ring-green-500 mt-1 border-gray-300 rounded-md overflow-hidden " style="border-width:1px; border-style:solid;" v-model="item.description_en_usa" />
                    </div>
                    <div class="flex-1">
                        <label class="block text-sm font-medium text-gray-700">Uses</label>
                        <textarea class="w-full inline-flex ring-green-500 mt-1 border-gray-300 rounded-md overflow-hidden " style="border-width:1px; border-style:solid;" v-model="item.uses_en_usa" />
                    </div>
                    
                </li>
            </ul>
        </div>
        

       
        <div class="my-10">
            <MultiImage v-model="images" :show-errors="showErrors"/>
        </div>

        <div class="mt-10">
            <Button :label="editMode ? 'Update Changes' : 'Create Product'" type="primary" @click="submit" />   
        </div>
    </div>
</template>

<script>

import PageTitle from '@/components/PageTitle'
import {formatPrice,validateEmail,baseURL,deleteFile,attachImage} from '@/assets/helper'
import FormInput from "@/components/Forms/FormInput";
import FormSelect from "@/components/Forms/FormSelect";
import MultiImage from "@/components/Forms/MultiImage";
import Button from "@/components/Button";
import Icons from "@/components/Icons";
import regions from '@/assets/resources/regions'

import Toggle from "@/components/Forms/Toggle";


export default {
    components:{
        FormInput,
        FormSelect,
        Button,
        Icons,
        PageTitle,
        MultiImage,
        Toggle
    },
    data:( () => ({
        showErrors:false,
        categories:[],
        init:false,
        item:{
            measure:'',
            memo:'',
            sku:'',
            name:'',
            description:'',
            uses:'',
            price:'',
            bagDiscount:true,
            countAsBag: true,
            showOnFront: true,
            vendor: 'lessMess',
            amazon_sku:'',
            costco_sku:'',
            walmart_sku: ''
        },
        category:'',
        images:[],
        allProducts:[],
        canonical: ''
    })),
    computed:{
        selectedCats(){
            return this.categories.filter(x => x.optIn).map(x => x.id)
        },
        editMode(){
            return Boolean(this.$route.params.id) && Boolean(this.item.id)
        }
    },
    methods:{
        async deleteConfirmed(){
            this.$store.commit('LOAD', {is:true})
            this.$api.delete(`/products/${this.item.id}`).then( ({data}) => {
                this.$store.commit('SET_MODAL',{
                    callback:() => this.$router.push('/products'),
                    danger:false,
                    confirm:false,
                    header:`Deleted`,
                    body:'This product has been deleted',
                })
            }).catch( () => {
                this.$store.commit('SET_MODAL',{
                    danger:true,
                    confirm:false,
                    header:`Uh oh,`,
                    body:'An error occured while trying to delete this product. Please try again.',
                })
            }).finally( () => {
                this.$store.commit('LOAD', {is:false})
            })
        },
        deleteProduct(){
                this.$store.commit('SET_MODAL',{
                    callback:this.deleteConfirmed,
                    danger:true,
                    confirm:true,
                    okText:'Delete',
                    header:`This cannot be undone`,
                    body:'Are you sure you want to delete this product?',
                })
        },
        validateEmail,
        isValid(){
            let valid = true
            
            if (!this.item.name.trim() || !this.category.trim() || this.images.length === 0) valid = false 

            return valid
        },
        dealWithImages(id){

            return new Promise( (resolve,reject) => {

                let promises = []
    
                for (const key in this.images) {
                    if (Object.hasOwnProperty.call(this.images, key)) {
                        const image = this.images[key];
                        
                        if(image.uploaded && !image.removed){
                            // console.log('ADDING', image.file.name)
                            promises.push(
                                attachImage({file:image.file,ref:'product',id,field:'image'})
                            )
                        } else if (!image.uploaded && image.removed){
                            // console.log('REMOVING', image.id)
                            promises.push(
                                deleteFile(image.id)
                            )
                        }
                    }
                }
                
                Promise.all(promises).then((resolved) => {
                    resolve()
                }).catch( () => {
                    reject()
                })

            })
        },
        async submit(){

            if (!this.isValid()) return this.showErrors = true
            // console.log(this.item,'this.item')
            let sendObj = JSON.parse(JSON.stringify(this.item))
            sendObj.categories = [this.category]

            if(this.canonical){
                sendObj.canonical = this.canonical
            }else{
                sendObj.canonical = null
            }

            if (this.editMode){
                this.$store.commit('LOAD',{is:true})
                await this.dealWithImages(sendObj.id)

                
                // console.log(sendObj,'sendObj')

             
                this.$api.put(`/products/${this.item.id}`, sendObj)
                    .then( ({data}) => {
                        this.$store.commit('SET_MODAL',{
                            callback: () => this.$router.push(`/products`),
                            danger:false,
                            confirm:false,
                            header:'Success',
                            body:`You have successfully updated Product: ${data.name}`
                        })
                    }).catch( () => {
                        this.$store.commit('SET_MODAL',{
                            danger:true,
                            confirm:false,
                            header:'Error',
                            body:`An error Occurred while updating. Please try again`
                        })
                    }).finally( () => {
                        this.$store.commit('LOAD',{is:false})
                    })

            } else {
                sendObj.isDefault = true
                this.$store.commit('LOAD',{is:true})
                this.$api.post(`/products`, sendObj).then( ({data}) => {
                    this.dealWithImages(data.id).then( () => {
                        this.$store.commit('SET_MODAL',{
                            callback: () => this.$router.push(`/products`),
                            danger:false,
                            confirm:false,
                            header:'Success',
                            body:`You have successfully created Product: ${data.name}`
                        })
                    }).catch( () => {
                        this.$store.commit('SET_MODAL',{
                            callback: () => this.$router.push(`/products/${data.id}`),
                            danger:true,
                            confirm:false,
                            header:'Problem uploading images,',
                            body:`Product: ${data.name} created successfully but there was a problem with uploading images. Please try again.`
                        })
                    }).finally( () => {
                        this.$store.commit('LOAD',{is:false})
                    })
                    
                    
                }).catch( () => {
                    this.$store.commit('SET_MODAL',{
                        danger:true,
                        confirm:false,
                        header:'Uh oh,',
                        body:`An error Occurred while updating. Please try again.`
                    })
                    this.$store.commit('LOAD',{is:false})
                })

            }
            
        },
        formatPrice,
    },
    async created(){

        let categories = []

        let {data:products} = await this.$api.get(`/products`)
        this.allProducts = products.map((element) => { 
            return {
                label: element.name + ' (' + element.memo + ' )',
                value: element.id
            }
        })

        let {data:cats} = await this.$api.get(`/categories`)
        this.categories = cats.map(x => ({label:x.name,value:x.id}))
        this.category = cats[0]?.id || ''

        if (this.$route.params.id){

            this.$store.commit('LOAD', {is:true,msg:'Retrieving Product Information'})

            this.allProducts = this.allProducts.filter((element) => { 
                return element.value !== this.$route.params.id
            })

            let {data} = await this.$api.get(`/products/${this.$route.params.id}`)
            this.category = data.categories[0].id
            delete data.categories
            if (!data.memo) data.memo = ''
            if (!data.measure) data.measure = ''
            
            if (!data.amazon_sku) data.amazon_sku = ''
            if (!data.costco_sku) data.costco_sku = ''
            if (!data.walmart_sku) data.walmart_sku = ''

            this.item = data
            this.images = data.image.map(x => ({id:x.id,url:`${baseURL}${x.url}`}))
            delete data.image
            

            this.canonical = data.canonical && data.canonical.id || '';

        } else {
            this.init = true
        }

        this.allProducts = [{
            label: 'None',
            value: ''
        },...this.allProducts]

        this.$store.commit('LOAD', {is:false,msg:''})
    

    }
}
</script>

<style>

</style>