<template>
    <PageTitle>
        <div class="flex justify-between items-center px-4">
            <div>
                <router-link to="/categories" class="text-blue-500 inline-flex cursor-pointer hover:text-blue-800">
                    <Icons i="arrow-left" class="w-4 h-4 hover:round" />
                </router-link>
                <span class="text-gray-400 ml-3" v-if="editMode">
                    Edit Category:
                    <span class="text-gray-500 ml-2">{{item.name}}</span>
                </span> 
                <span class="text-gray-400 ml-3" v-else>New Category</span>

            </div>
            <div v-if="editMode">
                <Button label="Delete Category" size="small" icon="remove" style="height:30px;" type="danger" @click="deleteItem" />
            </div>
        </div>
    </PageTitle>

    <div class="mt-10 container px-4 mx-auto mb-24 " v-if="item.id || init">

        <label class="block text-sm font-medium text-gray-700 text-xl mb-6">General Details</label>

        <div class="md:w-4/5">
            <ul class="text-gray-500 text-sm space-y-4">
                <li class="md:flex  space-x-3">
                    <div class="flex-1">
                        <FormInput :show-errors="showErrors" :required="true" label="Category Name" v-model="item.name" />
                    </div>
                    <div class="flex-1">
                        <FormInput :show-errors="showErrors" :required="true" label="Category Name (French)" v-model="item.name_fr" />
                    </div>


                    <div class="flex-1">
                        <FormInput :show-errors="showErrors" :required="true" label="Category Name (USA)" v-model="item.name_en_usa" />
                    </div>

                    
                </li>

                <li class="flex  space-x-3">
                    <div class="flex-1">
                        <label class="block text-sm font-medium text-gray-700">Title</label>
                        <textarea class="w-full inline-flex ring-green-500 mt-1 border-gray-300 rounded-md overflow-hidden " style="border-width:1px; border-style:solid;" v-model="item.title" />
                    </div>
                    
                    <div class="flex-1">
                        <label class="block text-sm font-medium text-gray-700">Title (French)</label>
                        <textarea class="w-full inline-flex ring-green-500 mt-1 border-gray-300 rounded-md overflow-hidden " style="border-width:1px; border-style:solid;" v-model="item.title_fr" />
                    </div>

                    <div class="flex-1">
                        <label class="block text-sm font-medium text-gray-700">Title (USA)</label>
                        <textarea class="w-full inline-flex ring-green-500 mt-1 border-gray-300 rounded-md overflow-hidden " style="border-width:1px; border-style:solid;" v-model="item.title_en_usa" />
                    </div>

                </li>

                <li class="flex  space-x-3">
                    <div class="flex-1">
                        <label class="block text-sm font-medium text-gray-700">Description</label>
                        <textarea class="w-full inline-flex ring-green-500 mt-1 border-gray-300 rounded-md overflow-hidden " style="border-width:1px; border-style:solid;" v-model="item.description" />
                    </div>
                    
                    <div class="flex-1">
                        <label class="block text-sm font-medium text-gray-700">Description (French)</label>
                        <textarea class="w-full inline-flex ring-green-500 mt-1 border-gray-300 rounded-md overflow-hidden " style="border-width:1px; border-style:solid;" v-model="item.description_fr" />
                    </div>

                    <div class="flex-1">
                        <label class="block text-sm font-medium text-gray-700">Description (USA)</label>
                        <textarea class="w-full inline-flex ring-green-500 mt-1 border-gray-300 rounded-md overflow-hidden " style="border-width:1px; border-style:solid;" v-model="item.description_en_usa" />
                    </div>

                </li>


                <li v-if="item.name == 'special products' || item.name == 'Special Products' || item.name == 'Special products' ">
                    <div class="flex-1">
                        <div class="flex-1 mt-14">
                            <label class="block text-sm font-medium text-gray-700 text-xl mb-6 pb-2 border-gray-300" style="border-bottom-width:1px;">Category Setting</label>
                            <!-- <div class="mt-3 flex">
                                <div>
                                    <label class="block mb-2 text-sm font-medium text-gray-700">Route by flatbed</label>                        
                                    <div>
                                        <Toggle v-model="item.routeFlatbed" activeText="Yes" inactiveText="No" />
                                    </div>
                                </div>

                                <div class="ml-5">
                                    <label class="block mb-2 text-sm font-medium text-gray-700">Route by courier</label>                        
                                    <div>
                                        <Toggle v-model="item.routeCourier" activeText="Yes" inactiveText="No" />
                                    </div>
                                </div>

                                <div class="ml-5">
                                    <label class="block mb-2 text-sm font-medium text-gray-700">No routing</label>                        
                                    <div>
                                        <Toggle v-model="item.noRouting" activeText="Yes" inactiveText="No" />
                                    </div>
                                </div>
                            </div> -->



                            <div>
                                <select v-model="item.selectedRoute">
                                    <option value="routeByFlatbed">Route by flatbed</option>
                                    <option value="routeByCourier">Route by courier</option>
                                    <option value="noRouting">No routing</option>
                                </select>
                            </div>



                        </div>
                    </div>
                </li>




            </ul>
        </div>
       
        <div class="mt-10">
            <Button :label="editMode ? 'Update Changes' : 'Create Product'" type="primary" @click="submit" />   
        </div>
    </div>
</template>

<script>

import PageTitle from '@/components/PageTitle'
import {formatPrice,validateEmail,baseURL,deleteFile,attachImage} from '@/assets/helper'
import FormInput from "@/components/Forms/FormInput";
import FormSelect from "@/components/Forms/FormSelect";
import MultiImage from "@/components/Forms/MultiImage";
import Button from "@/components/Button";
import Icons from "@/components/Icons";
import regions from '@/assets/resources/regions'

import Toggle from "@/components/Forms/Toggle";

import slugify from 'slugify'



export default {
    components:{
        FormInput,
        FormSelect,
        Button,
        Icons,
        PageTitle,
        MultiImage,
        Toggle
    },
    data:( () => ({
        showErrors:false,
        categories:[],
        init:false,
        item:{
            name:'',
            description:'',
            uses:'',
            price:'',
            selectedRoute: 'routeByFlatbed',
            slug:''

        },
        category:'',
        images:[]
    })),
    computed:{
        selectedCats(){
            return this.categories.filter(x => x.optIn).map(x => x.id)
        },
        editMode(){
            return Boolean(this.$route.params.id) && Boolean(this.item.id)
        },
    },
    methods:{
        async deleteConfirmed(){
            this.$store.commit('LOAD', {is:true})
            this.$api.delete(`/categories/${this.item.id}`).then( ({data}) => {
                this.$store.commit('SET_MODAL',{
                    callback:() => this.$router.push('/categories'),
                    danger:false,
                    confirm:false,
                    header:`Deleted`,
                    body:'This category has been deleted',
                })
            }).catch( () => {
                this.$store.commit('SET_MODAL',{
                    danger:true,
                    confirm:false,
                    header:`Uh oh,`,
                    body:'An error occured while trying to delete this category. Please try again.',
                })
            }).finally( () => {
                this.$store.commit('LOAD', {is:false})
            })
        },
        deleteItem(){
            
            // if (typeof(this.item.products === 'undefined')) {
            //     this.$store.commit('SET_MODAL',{
            //         callback:this.deleteConfirmed,
            //         danger:true,
            //         confirm:true,
            //         okText:'Delete',
            //         header:`This cannot be undone`,
            //         body:'Are you sure you want to delete this category?',
            //     })
            // }

            if (this.item.products.length){
                this.$store.commit('SET_MODAL',{
                    danger:true,
                    confirm:false,
                    okText:'Okay',
                    header:`Cannot delete`,
                    body:'Only categories without products can be deleted.',
                })
            } else {
                this.$store.commit('SET_MODAL',{
                    callback:this.deleteConfirmed,
                    danger:true,
                    confirm:true,
                    okText:'Delete',
                    header:`This cannot be undone`,
                    body:'Are you sure you want to delete this category?',
                })
            }
        },
        validateEmail,
        isValid(){
            let valid = true
            
            if (!this.item.name.trim() || !this.item.name_fr.trim() || !this.item.name_en_usa.trim()) valid = false 

            return valid
        },
        dealWithImages(id){

            return new Promise( (resolve,reject) => {

                let promises = []
    
                for (const key in this.images) {
                    if (Object.hasOwnProperty.call(this.images, key)) {
                        const image = this.images[key];
                        
                        if(image.uploaded && !image.removed){
                            // console.log('ADDING', image.file.name)
                            promises.push(
                                attachImage({file:image.file,ref:'product',id,field:'image'})
                            )
                        } else if (!image.uploaded && image.removed){
                            // console.log('REMOVING', image.id)
                            promises.push(
                                deleteFile(image.id)
                            )
                        }
                    }
                }
                
                Promise.all(promises).then((resolved) => {
                    resolve()
                }).catch( () => {
                    reject()
                })

            })
        },
        async submit(){

            if (!this.isValid()) return this.showErrors = true

            let sendObj = JSON.parse(JSON.stringify(this.item))

            sendObj.slug = slugify(sendObj.name)

            if (this.editMode){

                this.$store.commit('LOAD',{is:true})
                
                this.$api.put(`/categories/${this.item.id}`, sendObj)
                    .then( ({data}) => {
                        this.$store.commit('SET_MODAL',{
                            callback: () => this.$router.push(`/categories`),
                            danger:false,
                            confirm:false,
                            header:'Success',
                            body:`You have successfully updated Category: ${data.name}`
                        })
                    }).catch( () => {
                        this.$store.commit('SET_MODAL',{
                            danger:true,
                            confirm:false,
                            header:'Error',
                            body:`An error Occurred while updating. Please try again`
                        })
                    }).finally( () => {
                        this.$store.commit('LOAD',{is:false})
                    })

            } else {
                this.$store.commit('LOAD',{is:true})
                this.$api.post(`/categories`, sendObj).then( ({data}) => {
                    this.$store.commit('SET_MODAL',{
                        callback: () => this.$router.push(`/categories`),
                        danger:false,
                        confirm:false,
                        header:'Success',
                        body:`You have successfully created Category: ${data.name}`
                    })
                }).catch( () => {
                    this.$store.commit('SET_MODAL',{
                        danger:true,
                        confirm:false,
                        header:'Uh oh,',
                        body:`An error Occurred while updating. Please try again.`
                    })
                    this.$store.commit('LOAD',{is:false})
                })

            }
            
        },
        formatPrice,
    },
    async created(){
        let categories = []
        if (this.$route.params.id){
            this.$store.commit('LOAD', {is:true,msg:'Retrieving Category Information'})
            let {data} = await this.$api.get(`/categories/${this.$route.params.id}`)
            let {title = '',title_fr = '',title_en_usa = '',name = '',name_fr = '',name_en_usa = '',description = '',description_fr = '',description_en_usa = '',id} = data
            this.item = {title,title_fr,title_en_usa,name,name_fr,name_en_usa,description,description_fr,description_en_usa,id}
        } else {
            this.init = true
        }
        this.$store.commit('LOAD', {is:false,msg:''})
    }

}
</script>

<style>

</style>