<template>
  <div>
    Existing Dealer Invite

    <div class="mt-14 container px-4 mx-auto mb-24">
      <label
        class="
          block
          text-sm
          font-medium
          text-gray-700 text-xl
          mb-6
          border-gray-300
        "
        style="border-bottom-width: 1px"
        >General Details</label
      >

      <div class="md:w-2/3">
        <ul class="text-gray-500 text-sm space-y-4">
          <li>
            <FormInput
              :show-errors="showErrors"
              :required="true"
              label="Dealer Name"
              v-model="item.name"
            />
          </li>

          <li>
            <FormInput
              :show-errors="showErrors"
              :check="validateEmail"
              check-error="Not a valid Email"
              label="Email"
              v-model="item.email"
            />
          </li>

          <li>
            <FormInput label="Phone" v-model="item.phone" />
          </li>
          <li>
            <FormInput label="Address" v-model="item.address" />
          </li>
          <li>
            <FormSelect
              class="col-span-6 sm:col-span-3"
              label="Country"
              v-model="item.country"
              :options="[
                { label: 'United States', value: 'United States' },
                { label: 'Canada', value: 'CA' },
              ]"
              :required="true"
            />
          </li>
          <li>
            <FormSelect
              class="col-span-6 sm:col-span-3"
              :label="item.country === 'CA' ? 'Province' : 'State'"
              v-model="item.region"
              :options="regionOptions[item.country]"
              :required="true"
            />
          </li>
          <li>
            <FormInput label="City" v-model="item.city" />
          </li>
          <li>
            <FormInput label="Postal Code" v-model="item.code" />
          </li>
        </ul>
      </div>
      <div class="mt-10">
        <Button label="Invite Dealer" type="primary" @click="submit" />
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle";
import { formatPrice, validateEmail } from "@/assets/helper";
import FormInput from "@/components/Forms/FormInput";
import Toggle from "@/components/Forms/Toggle";
import FormSelect from "@/components/Forms/FormSelect";
import Button from "@/components/Button";
import Icons from "@/components/Icons";
import regions from "@/assets/resources/regions";

export default {
  components: {
    FormInput,
    FormSelect,
    Button,
    Icons,
    PageTitle,
    Toggle,
  },
  data: () => ({
    options: {},
    showErrors: false,
    original: {},
    allPromotions: [],
    item: {
      name: "",
      email: "",
      phone: "",
      city: "",
      region: "ON",
      country: "CA",
      code: "",
      address: "",
      role: 'StoreAdmin',

      deliveryOffset: 2,
    },
  }),
  computed: {
    backLink() {
      return this.editMode ? `/dealers/${this.$route.params.id}` : "/dealers";
    },
    editMode() {
      return Boolean(this.$route.params.id) && Boolean(this.original.id);
    },
    regionOptions() {
      return {
        CA: regions.CA,
        US: regions.US.map((d) => ({ label: d, value: d })),
      };
    },
  },
  methods: {
    isValid() {
      let valid = true;

      if (!this.item.name.trim() || !validateEmail(this.item.email))
        valid = false;

      return valid;
    },
    async submit() {
      if (!this.isValid()) return (this.showErrors = true);
      let sendObj = { ...this.item };
      let result = await this.$api.post("/auth/local/register", sendObj);
      if (result.data.jwt) {
        this.close();
      }
    },
    validateEmail,
    formatPrice,
  },
};
</script>

<style lang="scss" scoped></style>
