<template>
  <div>
    <PageTitle :border="false">
      <div
        class="container px-4 mx-auto flex justify-between"
        style="border-bottom: 2px solid #eaeaea"
      >
        <div>
          <ul class="flex cursor-pointer space-x-1">
            <li
              class="select-none py-2 px-6 rounded-t-lg"
              @click="handleTab('blogs')"
              :class="
                tab === 'blogs'
                  ? 'bg-green-100 text-green-600'
                  : 'bg-gray-100 text-gray-500'
              "
            >
              Blogs
            </li>
            <li
              class="select-none py-2 px-6 rounded-t-lg"
              @click="handleTab('pages')"
              :class="
                tab === 'pages'
                  ? 'bg-green-100 text-green-600'
                  : 'bg-gray-100 text-gray-500'
              "
            >
              Pages
            </li>
            <li
              class="select-none py-2 px-6 rounded-t-lg"
              @click="handleTab('slider')"
              :class="
                tab === 'slider'
                  ? 'bg-green-100 text-green-600'
                  : 'bg-gray-100 text-gray-500'
              "
            >
              Slider
            </li>
            <li
              class="select-none py-2 px-6 rounded-t-lg"
              @click="handleTab('locations')"
              :class="
                tab === 'locations'
                  ? 'bg-green-100 text-green-600'
                  : 'bg-gray-100 text-gray-500'
              "
            >
              Locations
            </li>
            <li
              class="select-none py-2 px-6 rounded-t-lg"
              @click="handleTab('provinces')"
              :class="
                tab === 'provinces'
                  ? 'bg-green-100 text-green-600'
                  : 'bg-gray-100 text-gray-500'
              "
            >
              Provinces
            </li>
            <li
              class="select-none py-2 px-6 rounded-t-lg"
              @click="handleTab('faq')"
              :class="
                tab === 'faq'
                  ? 'bg-green-100 text-green-600'
                  : 'bg-gray-100 text-gray-500'
              "
            >
              FAQ
            </li>
          </ul>
        </div>
      </div>
    </PageTitle>

    <div class="container px-4 mx-auto">
      <div class="flex justify-end my-4">
        <button
          @click="lang = 'en'"
          class="text-base  hover:scale-110 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer 
                    hover:bg-green-50
                    border duration-200 ease-in-out  
                    border-teal-600 transition"
          :class="lang === 'en' ? 'text-green-600' : 'text-gray-400'"
          style="border-top-right-radius:0; border-bottom-right-radius:0;"
        >
          <div class="flex leading-5">
            English
          </div>
        </button>
        <button
          @click="lang = 'fr'"
          class="text-base hover:scale-110 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer 
                    hover:bg-green-50
                    border duration-200 ease-in-out 
                    border-teal-600 transition "
          :class="lang === 'fr' ? 'text-green-600' : 'text-gray-400'"
          style="border-top-left-radius:0; border-bottom-left-radius:0; border-left:0;"
        >
          <div class="flex leading-5">
            French
          </div>
        </button>
        <button
          @click="lang = 'en_usa'"
          class="text-base hover:scale-110 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer 
                    hover:bg-green-50
                    border duration-200 ease-in-out 
                    border-teal-600 transition "
          :class="lang === 'en_usa' ? 'text-green-600' : 'text-gray-400'"
          style="border-top-left-radius:0; border-bottom-left-radius:0; border-left:0;"
        >
          <div class="flex leading-5">
            English(USA)
          </div>
        </button>
      </div>

      <Pages :lang="lang" v-if="tab === 'pages'" />
      <Slider :lang="lang" v-else-if="tab === 'slider'" />
      <Locations :lang="lang" v-else-if="tab === 'locations'" />
      <Provinces :lang="lang" v-else-if="tab === 'provinces'" />
      <Faq :lang="lang" v-else-if="tab === 'faq'" />
      <Blogs :lang="lang" v-else-if="tab === 'blogs'" />
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle";
import Icons from "@/components/Icons";
import Button from "@/components/Button";
import FormInput from "@/components/Forms/FormInput";
import { formatPrice, formatDate } from "@/assets/helper";
import Pages from "@/components/Web/Pages";
import Slider from "@/components/Web/Slider";
import Blogs from "@/components/Web/Blogs";
import Locations from "@/components/Web/Locations";
import Faq from "@/components/Web/Faq";
import Provinces from "@/components/Web/Provinces";
import $qs from "qs-stringify";

export default {
  components: {
    PageTitle,
    FormInput,
    Pages,
    Slider,
    Faq,
    Icons,
    Button,
    Blogs,
    Locations,
    Provinces,
  },
  data: () => ({
    lang: "en",
    query: "",
    items: [],
    filtered: [],
    tab: "blogs",
  }),
  watch: {
    async query(val) {
      if (val.length < 3) return (this.filtered = []);
      let query = $qs({
        _where: {
          _or: [
            { firstName_contains: val },
            { lastName_contains: val },
            { phone_contains: val },
            { email_contains: val },
          ],
        },
      });
      let { data } = await this.$api.get(`/customers?${query}`);

      if (data.length) this.filtered = data;
    },
  },
  methods: {
    handleTab(tab) {
      this.tab = tab;
      this.$router.replace({ query: null });
    },
    getName(item) {
      let { firstName = "", lastName = "" } = item;
      let name = `${firstName} ${lastName}`;
      if (!firstName && !lastName) name = "[No Name Given]";
      return name;
    },
    revertDealer() {
      this.dealerSearch = {
        query: "",
        results: [],
      };
      this.chosenDealer = {};
      this.filter.dealer = "";
    },
    hideResults() {
      this.dealerSearch.query = "";
      this.dealerSearch.results = [];
    },
    test(e) {
      // console.log("EEEE CLICK OUTSIDE", e);
    },
    formatPrice,
    formatDate,
    chooseDealer(dealer) {
      // console.log("CHOSEN DEALER", dealer);
      this.filter.dealer = dealer.id;
      this.chosenDealer = dealer;
      this.dealerSearch.results = [];
    },
  },
  computed: {
    queriedResults() {
      if (this.query.length > 2) return this.filtered;
      return this.items;
    },
    disabledDateFrom() {},
  },
  async created() {
    // let {data:about} = await this.$api.get('/aboutus')
    // // console.log('ABOUT us', about)

    // let {data:faq} = await this.$api.get('/faq')
    // // console.log('FAQQ', faq)

    // let {data:slider} = await this.$api.get('/slider')
    // // console.log('SLIDER', slider)
    if (this.$route.query.tab) {
      this.tab = this.$route.query.tab;
    }
  },
};
</script>

<style></style>
