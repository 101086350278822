<template>
  <div>
    <PageTitle>
      <div class="flex justify-between">
        <div>Customers</div>
      </div>
    </PageTitle>

    <div class="container px-4 mx-auto">
      <div class="grid grid-cols-6 gap-6 mt-3 mb-5">
        <!-- <div class="col-span-3 relative">
                    <FormInput class="col-span-6 sm:col-span-3" label="Search" placeholder=" " icon="search" v-model="query" type="text" />
                </div> -->
      </div>

      <div class="grid grid-cols-6 gap-6 mt-3 mb-5">
        <div class="flex space-x-4">
          <a
            href="#"
            class="bg-green-600 text-white px-5 py-2 rounded-md"
            @click.prevent="exportData"
            v-if="items.length > 0"
            >EXPORT DATA</a
          >
        </div>
      </div>

      <table class="table-auto w-full table-styled">
        <thead>
          <tr>
            <th class="bg-green-50">NAME</th>
            <th class="bg-green-50">PHONE</th>
            <th class="bg-green-50">EMAIL</th>
            <th class="bg-green-50">ADDRESS</th>
            <th class="bg-green-50">CONSENT</th>
          </tr>
        </thead>
        <tbody class="td-centers">
          <tr v-for="item in queriedResults" :key="item.id">
            <td>
              <router-link
                :to="`/customers/${item.id}`"
                class="text-green-600 hover:text-green-800 font-semibold"
                >{{ getName(item) }}</router-link
              >
            </td>
            <td>{{ item.phone }}</td>
            <td>{{ item.email }}</td>
            <td>{{ item.address }}</td>
            <td>{{ item.consent }}</td>
          </tr>
        </tbody>
      </table>
      <div
        class="w-full flex flex-col items-center justify-center py-7 text-gray-300"
        v-if="queriedResults.length === 0"
      >
        <Icons i="sadface" class="w-16" />
        <p class="text-gray-500 mt-3">
          Sorry, could not find any dealers in your system.
          {{ items.length === 0 ? "Please add dealers to get started." : "" }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle";
import Icons from "@/components/Icons";
import Button from "@/components/Button";
import FormInput from "@/components/Forms/FormInput";
import { formatPrice, formatDate } from "@/assets/helper";
import Datepicker from "vue3-datepicker";
import $qs from "qs-stringify";

export default {
  components: {
    PageTitle,
    FormInput,
    Datepicker,
    Icons,
    Button,
  },
  data: () => ({
    query: "",
    items: [],
    filtered: [],
  }),
  watch: {
    async query(val) {
      if (val.length < 3) return (this.filtered = []);
      let query = $qs({
        _where: {
          _or: [
            { firstName_contains: val },
            { lastName_contains: val },
            { phone_contains: val },
            { email_contains: val },
          ],
        },
      });
      let { data } = await this.$api.get(`/customers?${query}`);

      if (data.length) this.filtered = data;
    },
  },
  methods: {
    getName(item) {
      let { firstName = "", lastName = "" } = item;
      let name = `${firstName} ${lastName}`;
      if (!firstName && !lastName) name = "[No Name Given]";
      return name;
    },
    revertDealer() {
      this.dealerSearch = {
        query: "",
        results: [],
      };
      this.chosenDealer = {};
      this.filter.dealer = "";
    },
    hideResults() {
      this.dealerSearch.query = "";
      this.dealerSearch.results = [];
    },
    test(e) {
      // console.log("EEEE CLICK OUTSIDE", e);
    },
    formatPrice,
    formatDate,
    chooseDealer(dealer) {
      // console.log("CHOSEN DEALER", dealer);
      this.filter.dealer = dealer.id;
      this.chosenDealer = dealer;
      this.dealerSearch.results = [];
    },

    async exportData() {
      let url = "/customers/download";
      let { data } = await this.$api.get(url);
      download(data, "customers.csv", "text/csv");
    },
  },
  computed: {
    queriedResults() {
      if (this.query.length > 2) return this.filtered;
      return this.items;
    },
    disabledDateFrom() {},
  },
  async created() {
    this.$store.commit("LOAD", { is: true });
    let { data } = await this.$api.get("/customers");
    this.items = data;
    // console.log(this.items);
    this.$store.commit("LOAD", { is: false });
  },
};
</script>

<style></style>
