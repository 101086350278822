<template>
	<button
		v-if="!to"
		:disabled="disabled"
		class="butn flex items-center justify-center px-8 py-3 border text-base font-medium rounded-md  md:py-4 md:text-lg md:px-10"
		:class="classes"
		style="flex:0; "
	>
		<Icons
			v-if="icon"
			:i="icon"
			:class="iconRight ? 'pl-3 order-2' : 'pr-3'"
			style="height:20px;"
		/>
		<span>{{ label }}</span>
	</button>
	<router-link
		v-else
		:to="to"
		class="butn flex items-center justify-center px-8 py-3 border text-base font-medium rounded-md  md:py-4 md:text-lg md:px-10"
		:class="classes"
		style="flex:0; "
	>
		<Icons
			v-if="icon"
			:i="icon"
			:class="iconRight ? 'pl-3 order-2' : 'pr-3'"
			style="height:20px;"
		/>
		<span>{{ label }}</span>
	</router-link>
</template>

<script>
import Icons from '@/components/Icons';
export default {
	props: ['label', 'type', 'to', 'disabled', 'size', 'icon', 'iconRight'],
	components: {
		Icons,
	},
	computed: {
		classes() {
			let classes = '';
			if (this.type === 'primary')
				classes = 'text-white bg-green-600 hover:bg-green-700';
			else if (this.type === 'danger')
				classes = 'text-white bg-red-800 hover:bg-red-700';
			else if (this.type === 'outline-primary')
				classes =
					'text-green-800 border-green-800 hover:bg-green-700 hover:text-white';
			else if (this.type === 'outline')
				classes =
					'text-gray-600 border-gray-500 hover:bg-gray-700 hover:text-white';
			else classes = 'text-white bg-gray-500 hover:bg-gray-600';

			if (this.disabled)
				classes += ' opacity-30 pointer-events-none cursor-not-allowed';

			return classes;
		},
	},
};
</script>

<style></style>
