<template>
  <div
    class="top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-600 opacity-75 flex flex-col items-center justify-center"
    :class="fixed ? 'fixed' : 'absolute'"
    style="z-index: 10000"
  >
    <div
      class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"
    ></div>
    <h2 class="text-center text-white text-xl font-semibold">Loading...</h2>
    <p class="w-1/3 text-center text-white" v-if="msg">{{ msg }}</p>
  </div>
</template>

<script>
export default {
  props: {
    fixed: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    msg: "",
  }),
  computed: {
    loadingMsg() {
      return this.$store.state.loadingMsg;
    },
    show() {
      return this.$store.state.loading;
    },
  },
  created() {
    this.msg = this.loadingMsg;
    setTimeout(() => {
      if (this.show)
        this.msg =
          "This is taking longer than expected. Please refresh to try again.";
    }, 20000);
  },
  beforeMount() {
    document.body.style.overflow = "hidden";
  },
  beforeUnmount() {
    document.body.style.overflow = "auto";
  },
};
</script>

<style scoped>
.loader {
  border-top-color: #8bc34a;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
