<template>
    <div :key="fileKey">
        <label v-if="label" :for="id" class="block text-sm font-medium text-gray-700 mb-3">{{label}}&nbsp;</label>
        <template v-if="fileExists">

            <div v-if="image" :style="{backgroundImage:`url(${finalUrl})`}" class="file-img-holder" @click="remove">
                <Icons i="close" class="file-remove-icon" />
            </div>
            <div v-else>
                <div class="inline-flex items-center py-3 px-6 bg-green-100">File Added</div>
            </div>
        </template>
        <Upload v-else :label="label" @uploaded="handleUploaded" :image="image" :key="fileKey" />

    </div>
</template>

<script>
import Upload from '@/components/Forms/Upload'
import Icons from '@/components/Icons'

export default {
    components:{Upload,Icons,},
    props:{
        image:{
            type:Boolean,
            default:true
        },
        url:{
            type:Boolean,
            default:true
        },
        label:{
            type:String,
            default:'File',
        },
        disabled:{
            type:Boolean,
            default:false,
        },
        modelValue:{
            type:[String,Object],
            default:''
        },
        removable:{
            type:Boolean,
            default:false,
        }
    },
    watch:{
        // file(val){
        //     this.$emit('update:modelValue', val)
        // },
        // fileUrl(val){
        //     this.$emit('update:modelValue', val)
        // },
        modelValue(val){
            if (this.url) this.fileUrl = val 
            else this.file = val
        }
    },
    computed:{
        finalUrl(){
            let fileUrl = this.fileUrl
            if(!this.url && this.file?.url) {
                fileUrl = this.file.url
            }
            if (fileUrl && !fileUrl.includes('http')) return `${this.$api.defaults.baseURL}${fileUrl}`
            return fileUrl || ''
        },
        fileKey(){
            if (this.url) return this.fileUrl
            else if (this.file?.id) this.file.id
            else Date.now()
        },
        fileExists(){
            if (this.url) return Boolean(this.modelValue?.trim())
            else return Boolean(this.modelValue.id)
        }
    },
    data:( () => ({
        file:{},
        fileUrl:'',
    })),
    methods:{
        remove(){
            if (this.url){
                return this.$emit('update:modelValue', '')
            }
            else {
                return this.$emit('update:modelValue', {})
            }
        },
        imgType(url){
            if (!url || !url.trim || !url.trim().length) return false 

            let x = url
            let split = x.split('.')
            let ext = split[split.length-1].toLowerCase()
            let arr = ['jpg','png','jpeg']
            return arr.includes(ext)

        },
        handleUploaded(file){
            if (this.url){
                this.fileUrl = file.url
                return this.$emit('update:modelValue', file.url)
            }
            else {
                this.file = file
                return this.$emit('update:modelValue', file)
            }
        }
    },
    watch:{
        file(val){
            this.$emit('update:modelValue',val)
        }
    },
    created(){
        if (this.url) this.fileUrl =this.modelValue
        else this.file = this.modelValue
    }
    
   
}
</script>

<style scoped>
    .file-img-holder:hover .file-remove-icon{
        opacity:1;
        transform:translate3d(-50%,-50%,0);
        pointer-events: all;
    }
    .file-img-holder:hover:after{
        opacity:1;
    }
    .file-img-holder:after{
        content:'';
        background:rgba(255,0,0,0.3);
        left:0;
        top:0;
        right:0;
        bottom:0;
        z-index:1;
        position: absolute;
        opacity:0;
        transition: opacity .3s ease-out;
    }
    .file-img-holder{
        cursor: pointer;
        width:100px;
        height:100px;
        background-size:contain;
        border-radius:20px;
        overflow: hidden;
        background-position: center;
        background-repeat: no-repeat;
        background-color:white;
        box-shadow:0 0 2px rgba(0,0,0,0.2);
        position: relative;
    }
    .file-remove-icon{
        position: absolute;
        width:40px;
        height:40px;
        color:red;
        background:white;
        padding:5px;
        border-radius:50%;
        left:50%;
        top:50%;
        z-index:2;
        
        transition:transform .3s ease-out, opacity.3s ease-out;
        opacity:0;
        transform:translate3d(-50%,0%,0);
        
    }

</style>