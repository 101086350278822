<template>
  <PageTitle>
    <div class="flex justify-between items-center px-4 sm:px-0">
      <div>
        <router-link
          to="/cms?tab=locations"
          class="text-blue-500 inline-flex cursor-pointer hover:text-blue-800"
        >
          <Icons i="arrow-left" class="w-4 h-4 hover:round" />
        </router-link>
        <span class="text-gray-400 ml-3" v-if="editMode">
          City:
          <span class="text-gray-500 ml-2">{{ item.title }}</span>
        </span>
        <span class="text-gray-400 ml-3" v-else>New City</span>
      </div>
      <div class="d-flex flex-column">
        <div v-if="editMode">
          <Button
            label="Delete City"
            size="small"
            icon="remove"
            style="height: 30px"
            type="danger"
            @click="deleteCity"
          />
        </div>
        <div class="mt-2 ">
          <div
            class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in"
          >
            <input
              v-model="item.active"
              type="checkbox"
              name="toggle"
              id="toggle"
              class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
            />
            <label
              for="toggle"
              class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
            ></label>
          </div>
          <label
            for="toggle"
            class="text-xs"
            :class="item.active ? 'text-green-700' : 'text-red-600'"
            >{{ item.active ? "City is Active" : "City is Inactive" }}</label
          >
        </div>
      </div>
    </div>
  </PageTitle>

  <label
    class="mt-10 block text-sm font-medium text-gray-700 text-xl mb-2 container mx-auto px-4"
    >{{ editMode ? "Edit" : "New" }} City</label
  >

  <div class="container px-4 mx-auto mb-24 flex" v-if="item.id || init">
    <div class="flex-1">
      <div class="my-4">
        <FormInput
          v-model="item.title"
          label="City Name"
          class="w-full"
          :required="true"
          :show-errors="true"
        />
      </div>
      <div class="my-4">
        <FormSelect
          label="Country"
          v-model="selectedCountry"
          :options="countries"
          selectClass="block text-sm font-medium text-gray-700"
        />
      </div>
      <div class="my-4">
        <label :for="id" class="block text-sm font-medium text-gray-700"
          >State</label
        >
        <select
          v-model="selectedState"
          @change="handleStateChange()"
          class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        >
          <option
            v-for="province in provinces"
            :key="province.id"
            :value="province.id"
          >
            {{ province.title }}
          </option>
        </select>
      </div>

      <div class="my-4">
        <FormInput
          v-model="item.header"
          label="Header"
          class="w-full"
          :required="true"
          :show-errors="true"
          :maxlen="160"
        />
      </div>
      <div class="my-4">
        <FormInput
          v-model="item.header_description"
          label="Header Description"
          class="w-full"
          :required="true"
          :show-errors="true"
          :maxlen="500"
        />
      </div>
      <div class="my-4">
        <FormInput
          v-model="item.header_fr"
          label="Header FR"
          class="w-full"
          :required="true"
          :show-errors="true"
          :maxlen="160"
        />
      </div>
      <div class="my-4">
        <FormInput
          v-model="item.header_description_fr"
          label="Header Description FR"
          class="w-full"
          :required="true"
          :show-errors="true"
          :maxlen="500"
        />
      </div>

      <div class="my-4">
        <span class="block text-sm font-medium text-gray-700 mb-1">
          Custom Content
        </span>
        <Editor
          apiKey="1q5zhs44fg7b98vygm6hswlpqb8ajec9kc93tdist4q9hxjv"
          v-model="item.custom_content"
          :init="editorOptions"
        />
      </div>
      <div>
        <span class="block text-sm font-medium text-gray-700 mb-1">
          Custom Content FR
        </span>
        <Editor
          apiKey="1q5zhs44fg7b98vygm6hswlpqb8ajec9kc93tdist4q9hxjv"
          v-model="item.custom_content_fr"
          :init="editorOptions"
        />
      </div>
    </div>
    <div style="max-width: 300px; width: 30%" class="px-4 md:pl-10 md:pr-0">
      <!-- <div>
                <FormSelect label="Language" v-model="item.language" :options="languages" selectClass="bg-gray-300 text-gray-700" />
            </div> -->

      <!-- <div class="my-10">
                <FormSelect label="Category" v-model="item.type" :options="types" selectClass="bg-gray-300 text-gray-700" />
            </div> -->
      <div style="position: sticky; top: 10px" class="flex justify-center">
        <ImgSelector v-model="image" :url="false" label="Header Image" />
      </div>
      <div class="mt-10 justify-center flex">
        <Button
          :label="editMode ? 'Update Changes' : 'Create City'"
          type="primary"
          @click="submit"
          class="min-w-max"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle";
import {
  formatPrice,
  validateEmail,
  baseURL,
  deleteFile,
  attachImage,
} from "@/assets/helper";
import FormInput from "@/components/Forms/FormInput";
import ImgSelector from "@/components/Forms/ImgSelector";
import FormSelect from "@/components/Forms/FormSelect";
import Button from "@/components/Button";
import Icons from "@/components/Icons";
import Editor from "@tinymce/tinymce-vue";
import slugify from "slugify";
export default {
  components: {
    FormInput,
    FormSelect,
    Button,
    Icons,
    PageTitle,
    ImgSelector,
    Editor,
  },
  data: () => ({
    editorOptions: {},
    countries: [
      { id: 1, label: "Canada", value: "Canada" },
      { id: 2, label: "US", value: "US" },
    ],
    selectedCountry: null,
    initCountry: null,

    showErrors: false,
    init: false,
    item: {
      title: "",
      content: "",
      type: "blog",
      language: "en",
      slug: "",
      description: "",
	  active: null,
    },
    category: "",
    image: {},

    lang: null,
    provinces: [],
    cityUrl: "",
    selectedState: "",
  }),
  computed: {
    selectedCats() {
      return this.categories.filter((x) => x.optIn).map((x) => x.id);
    },
    editMode() {
      return Boolean(this.$route.params.id) && Boolean(this.item.id);
    },
  },
  watch: {
    selectedCountry(val) {
      this.provinces = this.allProvinces.filter((x) => x.Country === val);
    },
    selectedState(val) {
      // console.log(val, "vvvv");
      let p = this.provinces.find((x) => x.id === val).Country;
      if (p == "Canada") {
        this.cityUrl = "cities";
      } else {
        this.cityUrl = "cities-uses";
      }
      // console.log(p, this.provinces, "uuuuu");
    },
  },
  methods: {
    async deleteConfirmed() {
      this.$store.commit("LOAD", { is: true });
      this.$api
        .delete(`/${this.cityUrl}/${this.item.id}`)
        .then(({ data }) => {
          this.$store.commit("SET_MODAL", {
            callback: () => this.$router.push("/cms?tab=locations"),
            danger: false,
            confirm: false,
            header: `Deleted`,
            body: "This city has been deleted",
          });
        })
        .catch(() => {
          this.$store.commit("SET_MODAL", {
            danger: true,
            confirm: false,
            header: `Uh oh,`,
            body:
              "An error occured while trying to delete this blog. Please try again.",
          });
        })
        .finally(() => {
          this.$store.commit("LOAD", { is: false });
        });
    },
    deleteCity() {
      this.$store.commit("SET_MODAL", {
        callback: this.deleteConfirmed,
        danger: true,
        confirm: true,
        okText: "Delete",
        header: `This cannot be undone`,
        body: "Are you sure you want to delete this city?",
      });
    },
    handleStateChange() {
      this.item.province = this.provinces.find(
        (p) => p.id === this.selectedState
      );
      // console.log(this.item.province, "neeeewww");
    },
    async submit() {
      // console.log(this.item, "ittttt");
      let sendObj = JSON.parse(JSON.stringify(this.item));
      sendObj.header_image = this.image.id || null;
      sendObj.slug = slugify(sendObj.title);
      if (this.editMode) {
        this.$store.commit("LOAD", { is: true });
        // console.log(sendObj, "obobob");
        // if change country
        if (this.selectedCountry !== this.initCountry) {
          let deleteUrl;
          if (this.cityUrl === "cities") {
            deleteUrl = "cities-uses";
          } else {
            deleteUrl = "cities";
          }
          let deleted = await this.$api.delete(`${deleteUrl}/${this.item.id}`);
          // console.log(deleted, "obob");
          this.$api
            .post(`/${this.cityUrl}`, sendObj)
            .then(({ data }) => {
              this.$store.commit("SET_MODAL", {
                callback: () => this.$router.push(`/cms?tab=locations`),
                danger: false,
                confirm: false,
                header: "Success",
                body: `You have successfully updated City: ${data.title}`,
              });
            })
            .catch(() => {
              this.$store.commit("SET_MODAL", {
                danger: true,
                confirm: false,
                header: "Error",
                body: `An error Occurred while updating. Please try again`,
              });
            })
            .finally(() => {
              this.$store.commit("LOAD", { is: false });
            });
        } else {
          this.$api
            .put(`/${this.cityUrl}/${this.item.id}`, sendObj)
            .then(({ data }) => {
              this.$store.commit("SET_MODAL", {
                callback: () => this.$router.push(`/cms?tab=locations`),
                danger: false,
                confirm: false,
                header: "Success",
                body: `You have successfully updated City: ${data.title}`,
              });
            })
            .catch(() => {
              this.$store.commit("SET_MODAL", {
                danger: true,
                confirm: false,
                header: "Error",
                body: `An error Occurred while updating. Please try again`,
              });
            })
            .finally(() => {
              this.$store.commit("LOAD", { is: false });
            });
        }
      } else {
        sendObj.date = new Date().toISOString();

        this.$store.commit("LOAD", { is: true });

        this.$api
          .post(`/${this.cityUrl}`, sendObj)
          .then(({ data }) => {
            this.$store.commit("SET_MODAL", {
              callback: () => this.$router.push(`/cms?tab=locations`),
              danger: false,
              confirm: false,
              header: "Success",
              body: `You have successfully created City: ${data.title}`,
            });
          })
          .catch(() => {
            this.$store.commit("SET_MODAL", {
              danger: true,
              confirm: false,
              header: "Uh oh,",
              body: `An error Occurred while updating. Please try again.`,
            });
          })
          .finally(() => {
            this.$store.commit("LOAD", { is: false });
          });
      }
    },
    formatPrice,
  },
  async created() {
    let self = this;

    this.editorOptions = {
      height: 500,
      menubar: false,
      automatic_uploads: false,
      file_picker_callback(callback, value, meta) {
        var input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");

        /*
                Note: In modern browsers input[type="file"] is functional without
                even adding it to the DOM, but that might not be the case in some older
                or quirky browsers like IE, so you might want to add it to the DOM
                just in case, and visually hide it. And do not forget do remove it
                once you do not need it anymore.
                */

        input.onchange = function() {
          var file = this.files[0];
          // console.log("FILEEEEEE", file);
          const formData = new FormData();

          formData.append("files", file, file.name);

          self.$store.commit("LOAD", { is: true });

          self.$api
            .post("/upload", formData, {
              headers: { "Content-Type": "multipart/form-data" },
            })
            .then(({ data }) => {
              if (!data || !data[0] || !data[0].url) return;
              return callback(baseURL + data[0].url, { title: file.name });
            })
            .catch((err) => {})
            .finally(() => {
              self.$store.commit("LOAD", { is: false });
            });
          // var reader = new FileReader();
          // reader.onload = function () {
          //     /*
          //     Note: Now we need to register the blob in TinyMCEs image blob
          //     registry. In the next release this part hopefully won't be
          //     necessary, as we are looking to handle it internally.
          //     */

          //     var id = 'blobid' + (new Date()).getTime();
          //     var blobCache =  tinymce.activeEditor.editorUpload.blobCache;
          //     var base64 = reader.result.split(',')[1];
          //     var blobInfo = blobCache.create(id, file, base64);
          //     blobCache.add(blobInfo);

          //     /* call the callback and populate the Title field with the file name */
          //     callback('blobInfo.blobUri()', { title: file.name });
          // };
          reader.readAsDataURL(file);
        };

        input.click();
      },
      plugins: [
        "advlist autolink lists link image preview anchor",
        "searchreplace visualblocks fullscreen",
        "media",
      ],
      toolbar:
        "undo redo | formatselect | bold italic | \
                alignleft aligncenter alignright alignjustify | image | \
                bullist numlist outdent indent | removeformat | help",
    };
    let { data } = await this.$api.get("/provinces");

    this.provinces = data.provinces;
    this.allProvinces = this.provinces;
    // console.log(this.provinces, "ooooggg");

    if (this.$route.params.id) {
      this.$store.commit("LOAD", {
        is: true,
        msg: "Retrieving City Information",
      });
      this.lang = this.$route.query.lang;
      // console.log("lang", this.$route.params);

      if (this.lang == "en") {
        this.cityUrl = "cities";
      } else {
        this.cityUrl = "cities-uses";
      }

      let { data } = await this.$api.get(
        `/${this.cityUrl}/${this.$route.params.id}`
      );
      // console.log(data, "ooo");
      let { header_image, ...city } = data;

      if (!header_image) header_image = {};

      this.item = city;
      this.selectedState = this.item.province.id;
      this.selectedCountry = this.item.province.Country;
      this.initCountry = this.item.province.Country;
      this.image = header_image;
    }
	// console.log(this.item,'itemsss')
    this.init = true;
    this.$store.commit("LOAD", { is: false, msg: "" });
  },
  mounted() {
    setTimeout(() => {
      // this.$store.commit('LOAD', {is:true})
    }, 2000);
  },
};
</script>
