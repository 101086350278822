<template>
    <div>
        <div class="flex flex-wrap space-x-4">
            <template v-for="item in images">
                <div class="img-holder relative w-64 flex flex-col items-center px-2 py-4 bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue"  :key="item.id" v-if="!item.removed">
                    <img :src="item.url" class="w-32 h-24 object-cover"/>
                    <div class="img-holder-delete cursor-pointer">
                        <Icons i="remove" class="w-8 h-8 text-red-800" @click="remove(item.id)" />
                    </div>
                </div>
            </template>
            <div class="flex bg-grey-lighter">
                <label class="w-64 flex flex-col justify-center items-center px-2 py-4 bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-green-100">
                    <svg class="w-5 h-5" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                    </svg>
                    <span class="mt-2 text-base leading-normal">Add an image</span>
                    <input type='file' class="hidden" id="file" ref="file" v-on:change="readURL" />
                </label>
            </div>
            
            
        </div>
        <p v-if="images.length === 0 && showErrors" class="mt-3 text-red-800">You must add atleast one image</p>
    </div>
</template>

<script>
import Icons from '@/components/Icons'
export default {
    props:{
        modelValue:{
            type:Array,
            default:[]
        },
        showErrors:{
            type:Boolean,
            default:false,
        }
    },
    components:{
        Icons
    },
    data:( () => ({
        images:[],
        
    })),
    watch:{
        images(val){
            // console.log('CALLL', val)
            this.$emit('update:modelValue',val)
        }
    },
    methods:{
        async remove(id){
            
            this.images = this.images.map(x => {
                if (x.id === id) x.removed = true
                return x
            })
        },
        readURL(input) {
            if (input?.target?.files?.[0]) {
                let file = input.target.files[0]
                var reader = new FileReader();
                
                reader.onload = (e) => {
                    let url = e.target.result
                    this.images.push({id:'add_'+Date.now() + '', url,file,uploaded:true })
                }
                
                reader.readAsDataURL(input.target.files[0]); 
            }
        },
    },
    created(){
        this.images = JSON.parse(JSON.stringify(this.modelValue))
    }
}
</script>

<style>

    .img-holder-delete{
        position: absolute;
        left:50%;
        top:50%;
        transform:translate3d(-50%,-20%,0);        
        opacity:0;
        transition: transform .3s ease-out, opacity .3s ease-out;
        z-index:3;
    }
    .img-holder:hover:after{
        position: absolute;
        left:0;
        right:0;
        top:0;
        bottom:0;
        background:rgba(255,255,255,0.80);
        content:'';
        z-index:1;
    }
    .img-holder:hover .img-holder-delete{
        opacity:1;
        transform:translate3d(-50%,-50%,0);        
    }


</style>