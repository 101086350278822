import { createRouter, createWebHistory } from "vue-router";

import { Login, getCookie } from "../assets/helper";
import store from "../store";
import storeAdminPages from "@/assets/storeAdminPages";
import superPages from "@/assets/superPages";

let routes = [...superPages, ...storeAdminPages];

// console.log(routes);
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.afterEach(async (to, from, next) => {
  store.commit("LOAD", { is: false });
});
router.beforeEach(async (to, from, next) => {
  store.commit("LOAD", { is: true });
  // console.log("PAGE requiresAuth ", to.meta.requiresAuth);

  let isStoreAdmin = store.getters.isStoreAdmin;
  let isSuperAdmin = store.getters.isSuper;
  let isCSR = store.getters.isCSR;
  let requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  let superOnly = to.matched.some((record) => record.meta.superOnly);
  let storeAdminOnly = to.matched.some((record) => record.meta.storeAdminOnly);
  let csr = to.matched.some((record) => record.meta.csr);
  //   // console.log(superOnly, "from s");
  //   // console.log(isSuperAdmin, "from sss");
  let jwt = getCookie("lmjt");
  if (!requiresAuth) {
    // console.log("from sssupppp");
    next();
  } else if (!jwt) {
    next("/login");
  } else if (!isStoreAdmin && !isSuperAdmin && !isCSR) {
    next("/login");
  } else if (csr) {
    isCSR || isSuperAdmin ? next() : next("/login");
  } else if (superOnly) {
    isSuperAdmin ? next() : next("/login");
  } else if (storeAdminOnly) {
    isStoreAdmin ? next() : next("/login");
  } else {
    let user = await Login(store);
    if (user && user.id) next();
    else next("/login");
  }

  //   if (!to.meta.requiresAuth) return next();
  //   else if (!jwt) return next("/login");
  //   else if (store.state.user && store.state.user.id) return next();
  //   else {
  //     let user = await Login(store);
  //     if (user && user.id) return next();
  //     else return next("/login");
  //   }
});

export default router;
