<template>
  <PageTitle v-if="item.id">
    <div class="flex justify-between items-center px-4">
      <div>
        <router-link
          to="/dealers"
          class="text-blue-500 inline-flex cursor-pointer hover:text-blue-800"
        >
          <Icons i="arrow-left" class="w-4 h-4 hover:round" />
        </router-link>
        Dealer:
        <span class="text-gray-500 ml-2">{{ item.name }}</span>
        <span v-if="!item.active" class="text-red-700">(Inactive)</span>
      </div>
      <div>
        <Button
          :to="`/dealers/${item.id}/area`"
          label="Service Area"
          size="small"
          icon="pen"
          style="min-width: 100px; float: left"
          type="secondary"
          class="mr-2"
        />
        <Button
          :to="`/dealers/${item.id}/edit`"
          label="Edit"
          size="small"
          icon="pen"
          style="min-width: 100px; float: left"
          type="primary"
        />
      </div>
    </div>
  </PageTitle>

  <div
    class="grid grid-cols-12 gap-6 mt-3 mb-5 container mx-auto mt-12 px-4"
    v-if="item?.id"
  >
    <div class="col-span-12 sm:col-span-3 order-card-sm order-2">
      <h3 class="text-lg text-green-600 mb-4 flex">Details</h3>
      <ul class="text-gray-500 text-sm space-y-2">
        <li :class="item?.user?.confirmed ? 'text-green-600' : 'text-red-600'">
          Confirmed Email: <b>{{ item?.user?.confirmed ? true : false }}</b>
        </li>
        <li>
          Phone: <b>{{ item.phone }}</b>
        </li>
        <li>
          Email:
          <a :href="`mailto:${item.email}`"
            ><b>{{ item.email }}</b></a
          >
        </li>

        <li style="margin-top: 2rem">
          Dealer Name: <b>{{ item.name }}</b>
        </li>
        <li>
          Address: <b>{{ item.address }}</b>
        </li>
        <li>
          City: <b>{{ item.city }}</b>
        </li>
        <li>
          State/Province: <b>{{ item.regionLabel }}</b>
        </li>
        <li>
          Country: <b>{{ item.countryLabel }}</b>
        </li>
      </ul>

      <h3 class="text-md mb-4 flex mt-10">Delivery Availability</h3>
      <ul class="text-gray-500 text-sm space-y-2">
        <li :class="item.openMon ? 'text-green-600' : ''">
          Monday: <b>{{ item.openMon ? "Yes" : "No" }}</b>
        </li>
        <li :class="item.openTue ? 'text-green-600' : ''">
          Tuesday: <b>{{ item.openTue ? "Yes" : "No" }}</b>
        </li>
        <li :class="item.openWed ? 'text-green-600' : ''">
          Wednesday: <b>{{ item.openWed ? "Yes" : "No" }}</b>
        </li>
        <li :class="item.openThu ? 'text-green-600' : ''">
          Thursday: <b>{{ item.openThu ? "Yes" : "No" }}</b>
        </li>
        <li :class="item.openFri ? 'text-green-600' : ''">
          Friday: <b>{{ item.openFri ? "Yes" : "No" }}</b>
        </li>
        <li :class="item.openSat ? 'text-green-600' : ''">
          Saturday: <b>{{ item.openSat ? "Yes" : "No" }}</b>
        </li>
        <li :class="item.openSun ? 'text-green-600' : ''">
          Sunday: <b>{{ item.openSun ? "Yes" : "No" }}</b>
        </li>
      </ul>
    </div>

    <div class="col-span-12 order-card-lg">
      <table class="table-auto w-full table-styled">
        <thead>
          <tr>
            <th class="bg-green-50">PRODUCT</th>
            <th class="bg-green-50">DEALER COST</th>
            <th class="bg-green-50">SELLING PRICE</th>
          </tr>
        </thead>
        <tbody class="td-centers">
          <tr v-for="prd in products" :key="prd.id">
            <td class="flex items-center">
              <div class="flex-1 px-4 flex items-center">
                <img
                  :src="$url + prd.image.url"
                  class="w-12 h-12 md:w-32 md:h-32 object-cover"
                />
                <div class="ml-4">
                  <div class="text-gray-600 font-bold">
                    {{ prd.name }} <em v-if="prd.memo">({{ prd.memo }})</em>
                  </div>
                </div>
              </div>
            </td>
            <td>{{ formatPrice(prd.cost) }}</td>
            <td>{{ formatPrice(prd.price) }}</td>
          </tr>
        </tbody>
      </table>
      <div
        class="w-full flex flex-col items-center justify-center py-7 text-gray-300"
        v-if="!products.length"
      >
        <Icons i="sadface" class="w-16" />
        <p class="text-gray-500 mt-3">
          Sorry, No products were opted in. Please click edit to modify your
          Optins.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle";
import { formatPrice, formatDate, getRegion } from "@/assets/helper";
import FormSelect from "@/components/Forms/FormSelect";
import Button from "@/components/Button";
import Icons from "@/components/Icons";

export default {
  components: {
    PageTitle,
    FormSelect,
    Button,
    Icons,
  },
  computed: {
    products() {
      let { optIns } = this.item;

      return this.item.products
        .filter((x) => {
          if (x.isDefault) {
            let found = optIns.find((y) => y.product === x.id);
            if (found) {
              if (found.cost) x.cost = found.cost;
              if (!x.cost) x.cost = 0;
              x.price = found.price || 0;
              return true;
            }

            return false;
          }
          return true;
        })
        .map((prd) => {
          if (prd?.image?.length) {
            prd.image = prd.image[0];
            return prd;
          }

          return prd;
        });
    },
  },
  data: () => ({
    item: {},
    editMode: false,
  }),
  methods: {
    print() {
      window.print();
    },
    formatPrice,
    async confirmStatus(status) {
      this.$store.commit("LOAD", { is: true, msg: "Updating Order" });
      this.$api.put(`/orders/${this.order.id}`, { status }).then(({ data }) => {
        this.order.status = status;
        this.$store.commit("LOAD", { is: false, msg: "" });
      });
    },
    changeStatus(newStatus) {
      let status = this.orderStatuses[newStatus];
      this.$store.commit("SET_MODAL", {
        callback: () => this.confirmStatus(newStatus),
        danger: false,
        okText: "Change Status",
        confirm: true,
        header: `Status to ${status}`,
        body: `This will change the order status to ${status}.`,
      });
    },
    formatDate,
  },
  async created() {
    this.$store.commit("LOAD", {
      is: true,
      msg: "Retrieving Dealer Information",
    });
    let { data } = await this.$api.get(`/stores/${this.$route.params.id}`);

    if (data.id) {
      // if (data.region.length < 4){
      if (data.region && data.region.length < 4) {
        data.regionLabel = getRegion(data.country, data.region);
        data.countryLabel = data.country === "CA" ? "Canada" : "United States";
      }

      this.item = data;
    }
    this.$store.commit("LOAD", { is: false, msg: "" });
  },
};
</script>

<style lang="scss">
.order-card-lg {
  @media screen and (min-width: 640px) {
    grid-column: span 9 / span 9;
  }
}
</style>

<style scoped lang="scss">
@media screen {
  .print-show {
    display: none;
  }
}
@media print {
  .order-card-sm {
    width: 100%;
  }
  .print-hide {
    display: none;
  }
  .order-card-lg {
    width: 100%;
  }
  .print-font-12 {
    font-size: 12px;
  }
}
</style>
