<template>
    <div>
        <PageTitle>
            <div class="flex justify-between">
                <div>
                    Categories
                </div>
                <div class="flex justify-end flex-basis-fix">
                    <Button label="Edit Order" size="small" :icon="editOrderSeen ? 'remove' : 'plus'" style="height:30px;" @click="editOrder" type="primary" />
                    <Button label="Add Category" size="small" icon="plus" style="height:30px;" to="/newcategory" type="primary"/>
                </div>
            </div>
        </PageTitle>

        <div class="container px-4 mx-auto">

            <table class="table-auto w-full table-styled" v-if="items.length">
                <thead>
                    <tr>
                        <th class="bg-green-50" v-if="editOrderSeen">ORDER</th>
                        <th class="bg-green-50 text-left" style="padding-left:1rem;">CATEGORY NAME</th>
                        <th class="bg-green-50"># of PRODUCTS</th>
                    </tr>
                </thead>
                <!-- <tbody class="td-centers"> -->
                    <!-- <tr v-for="item in queriedResults" :key="item.id">
                        <td class="text-left" style="text-align:left; padding-left:1rem;">
                            <router-link class="text-green-700 hover:text-green-800 font-bold" :to="`/categories/${item.id}`">{{item.name}} </router-link>
                        </td>
                        <td style="padding:0 1rem;">{{item.products.length}}</td>
                    </tr> -->

                    <draggable @update="log" tag="tbody" class="td-centers" handle=".handle" v-model="items" >
                        <tr v-for="item in queriedResults" :key="item.id">
                            <td v-if="editOrderSeen"> 
                                <i class="fa fa-align-justify handle">
                                    <img src="@/assets/img/move.svg" alt=" ">
                                </i>
                            </td>
                            <td class="text-left" style="text-align:left; padding-left:1rem;">
                                <router-link class="text-green-700 hover:text-green-800 font-bold" :to="`/categories/${item.id}`">{{item.name}} </router-link>
                            </td>
                            <td style="padding:0 1rem;">{{item.products.length}}</td>
                        </tr>
                    </draggable>

                <!-- </tbody> -->
            </table>
            <div class="w-full flex flex-col items-center justify-center py-7 text-gray-300" v-else>
                <Icons i="sadface" class="w-16" />
                <p class="text-gray-500 mt-3">
                    Sorry, No Categories created yet. Please click Add to get started!
                </p>
            </div>

            <div class="flex flex-basis-fix">
                <Button label="Save Order" size="small" style="height:30px;" @click="saveOrder" type="primary"  v-if="editOrderSeen" class="mt-5"/>
                <Button label="Cancel Order" size="small" style="height:30px;" @click="cancelOrder" type="primary"  v-if="editOrderSeen" class="mt-5"/>
            
            </div>

        </div>

    </div>
</template>

<script>
import PageTitle from '@/components/PageTitle'
import Icons from '@/components/Icons'
import Button from '@/components/Button'
import FormInput from '@/components/Forms/FormInput'
import {formatPrice,formatDate,baseURL} from '@/assets/helper'
import Datepicker from 'vue3-datepicker'
import $qs from 'qs-stringify'

import { VueDraggableNext } from 'vue-draggable-next'


export default {
    components:{
        PageTitle,
        FormInput,
        Datepicker,
        Icons,
        Button,
        draggable: VueDraggableNext,

    },
    data:( () => ({
        query:'',
        items:[],
        filtered:[],
        editOrderSeen: false,
        order:[]
    })),
    watch:{
        async query(val){
            if (val.length < 3) return this.filtered = []
            let query = $qs({ _where: { _or: [{ name_contains: val }, { phone_contains: val }, { email_contains: val }] } })
            let {data} = await this.$api.get(`/stores/autocomplete?${query}`)
            if (data.length) this.filtered = data
        }
    },
    methods:{
        getImage(prd){
            if (prd.image?.url) return `${baseURL}${prd.image.url}`
            return 'https://picsum.photos/500/400'
        },
        formatPrice,
        formatDate,

        log(event) {
            // console.log('changed order');
        },
        editOrder() {
            this.editOrderSeen = !this.editOrderSeen;
        },
        saveOrder(){
            let sendObj = JSON.parse(JSON.stringify(this.items))
            // console.log('saved', sendObj)

            let changeorder = sendObj.map(el => {
                return el.id
            })

            this.$api.put(`/categories/changeorder`, changeorder)
                .then( () => {
                    this.$store.commit('SET_MODAL',{
                        callback: () => this.$router.push(`/categories`),
                        danger:false,
                        confirm:false,
                        header:'Success',
                        body:`You have successfully updated Category.`
                    }),
                    this.editOrderSeen = false
                }).catch( () => {
                    this.$store.commit('SET_MODAL',{
                        danger:true,
                        confirm:false,
                        header:'Error',
                        body:`An error Occurred while updating. Please try again`
                    })
                }).finally( () => {
                    this.$store.commit('LOAD',{is:false})
                })

        },
        cancelOrder() {
            this.items = this.order
        }

    },
    computed:{
        queriedResults(){
            let result = this.items
            if (this.query.length > 2) result = this.filtered
            return result
        },
        disabledDateFrom(){

        }
    },
    async created(){
        this.$store.commit('LOAD', {is:true})
        let {data} = await this.$api.get('/categories')
        this.items = data.sort((a, b) => { return a.order - b.order });
        this.order = data.sort((a, b) => { return a.order - b.order });
        this.$store.commit('LOAD', {is:false})
    }
}
</script>

<style scoped>
    i.handle{
        cursor:pointer;
        display: inline-block;
    }
    .flex-basis-fix>a, .flex-basis-fix>button{
        flex-basis: auto !important;
    }
</style>