<template>
    <PageTitle>
        <div class="flex justify-between items-center px-4">
            <div>
                <router-link to="/customers" class="text-blue-500 inline-flex cursor-pointer hover:text-blue-800">
                    <Icons i="arrow-left" class="w-4 h-4 hover:round" />
                </router-link>
                Customer:
                <span class="text-gray-500 ml-2">{{getName}}</span>
            </div>
        </div>
    </PageTitle>

    <div class="grid grid-cols-12 gap-6 mt-3 mb-5 container mx-auto mt-12 px-4" v-if="item?.id">

        <div class="col-span-4 sm:col-span-3 order-card-sm order-2">
            <h3 class="text-lg text-green-600 mb-4 flex">Details </h3>
            <ul class="text-gray-500 text-sm space-y-2">
                <li>Phone: <b>{{item.phone}}</b></li>
                <li>Email: <a :href="`mailto:${item.email}`"><b>{{item.email}}</b></a></li>
                <!-- <li style="margin-top:2rem">Dealer Name: <b>{{item.name}}</b></li> -->
                <li>Address: <b>{{item.address}}</b></li>
                <li>City: <b>{{item.city}}</b></li>
                <li>State/Province: <b>{{item.region}}</b></li>
                <li>Country: <b>{{item.country}}</b></li>
            </ul>
        </div>

        <div class="col-span-8 order-card-lg">
                <table class="table-auto w-full table-styled">
                    <thead>
                        <tr>
                            <th class="bg-green-50">ORDER#</th>
                            <th class="bg-green-50">ORDER DATE</th>
                            <th class="bg-green-50">DELIVERY DATE</th>
                            <th class="bg-green-50">AMOUNT</th>
                            <th class="bg-green-50">STATUS</th>
                        </tr>
                    </thead>
                    <tbody class="td-centers">
                        <tr v-for="ord in orders" :key="ord.id">
                            <td class="flex justify-center text-green">
                                <router-link :to="`/orders/${ord.id}`" class="hover:text-green-800">{{ord.orderId}}</router-link>
                            </td>
                            <td>{{formatDate(ord.createdAt)}}</td>
                            <td>{{formatDate(ord.deliveryDate)}}</td>
                            <td>{{formatPrice(ord.total)}}</td>
                            <td>{{ord.status}}</td>
                        </tr>
                    
                    </tbody>
                </table>
                <div class="w-full flex flex-col items-center justify-center py-7 text-gray-300" v-if="!orders.length">
                    <Icons i="sadface" class="w-16" />
                    <p class="text-gray-500 mt-3">
                        This customer has no orders.
                    </p>
                </div>
        </div>
        

    </div>


</template>

<script>

import PageTitle from '@/components/PageTitle'
import {formatPrice,formatDate} from '@/assets/helper'
import FormSelect from "@/components/Forms/FormSelect";
import Button from "@/components/Button";
import Icons from "@/components/Icons";

export default {
    components:{
        PageTitle,FormSelect,Button,Icons,
    },
    computed:{
        getName(){
            let {firstName = '', lastName = ''} = this.item 
            let name = `${firstName} ${lastName}`
            if (!firstName && !lastName) name = '[No Name Given]'
            return name
        },

    },
    data:( () => ({
        item:{},
        orders:''
    })),
    methods:{
        print(){
            window.print()
        },
        formatPrice,
        formatDate,
    },
    async created(){
        this.$store.commit('LOAD', {is:true,msg:'Retrieving Dealer Information'})
        let {data} = await this.$api.get(`/customers/${this.$route.params.id}`)
        if (data.id) this.item = data
        this.item = data.user
        this.orders = data.orders
        // let {data:orders} = await this.$api.get(`/orders/?user=${this.$route.params.id}`)
        // this.orders = orders
        this.$store.commit('LOAD', {is:false,msg:''})
    }
}
</script>
