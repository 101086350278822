<template>
    <div class="w-full md:w-3/5 mx-auto mb-10">

        <div class="shadow-md">

            <template v-if="faq[lang] && faq[lang].length">

                <template v-for="(qa,qaI) in faq[lang]" :key="qaI">

                    <div class="tab w-full overflow-hidden border-t" >

                        <input class="absolute opacity-0" :id="qa.id" type="radio" name="accordionPages">
                        <label class="block p-5 leading-normal cursor-pointer" :for="qa.id">#{{qaI + 1}}. {{qa.question?.trim()? qa.question : 'FAQ' }}</label>

                        <div class="tab-content overflow-hidden border-l-2 bg-gray-100 border-green-200 leading-normal">
                            <div class="p-5">
                                <div>
                                    <FormInput v-model="qa.question" label="Question" style="max-width:600px" :maxlen="200"/>
                                </div>
                                <div class="mt-4">
                                    <p class="text-gray-600 mb-2">Answer</p>
                                    <textarea v-model="qa.answer" class="w-full md:w-1/2 min-h-ta border-gray-400" />
                                </div>
                                <hr class="my-5"/>

                                <div class="flex justify-between flex-wrap">
                                    <Button label="Delete this FAQ" type="danger" @click="deleteFAQ(qa)" icon="close" class="min-w-max" style="font-size:15px; padding:1em 2em;" />
                                </div>
                                <!-- <Button label="Save" type="primary" style="padding:10px 30px" @click="updateFields(section)" /> -->
                            </div>
                        </div>

                    </div>

                </template>

            </template>
            
           
        </div>
        <div class="w-full flex flex-col items-center justify-center py-7 text-gray-300" v-if="!faq[lang] || !faq[lang].length">
            <Icons i="sadface" class="w-16" />
            <p class="text-gray-500 mt-3">
                You have no FAQ added for {{langName}}
            </p>
        </div>
        <div class="my-10 w-full text-center justify-between flex space-x-4">
            <Button :label="`Save FAQs (${langName})`" type="outline-primary" @click="save" class="min-w-max" />
            <Button label="Add FAQ" type="primary" @click="addFaq(lang)" icon="plus" class="min-w-max" />
        </div>

    </div>
</template>

<script>
import Button from '@/components/Button'
import Icons from '@/components/Icons'
import FormInput from "@/components/Forms/FormInput";
import { nanoid } from 'nanoid'

export default {
    props:['lang'],
    components:{
        Button,
        FormInput,
        Icons,
    },
    computed:{
        langName(){
            // console.log(this.lang)
            return this.lang === 'en' ? 'English' : this.lang === 'en_usa' ? 'English(USA)' : 'French'
        }
    },
    data:( () => ({
        
        faq:{
            en:[],
            fr:[],
            en_usa: []
        },

    })),
    methods:{
        deleteFAQ(qa){
            
            if (this.faq[this.lang]?.length){
                this.faq[this.lang] = this.faq[this.lang].filter(x => x.id !== qa.id)
            }

        },
        async saveConfirmed(saveObj){


            // console.log(saveObj)

            this.$store.commit('LOAD', {is:true})
            let {data} = await this.$api.put('/faq',saveObj)
            this.faq[this.lang] = data[this.lang]
            this.$store.commit('LOAD', {is:false})
            
        },
        save(){
            
            let lenBefore = this.faq[this.lang].length 

            // console.log(lenBefore,'lenBefore')
            
            let saveObj = this.faq[this.lang]
                .filter(x => {
                    return x.answer.trim() && x.question.trim()
                })

            let lenAfter = saveObj.length 
            
            saveObj = {[this.lang]:saveObj}

            if (lenBefore > lenAfter){
                return this.$store.commit('SET_MODAL',{
                    callback:() =>  this.saveConfirmed(saveObj),
                    danger:false,
                    confirm:true,
                    okText:'Continue',
                    header:'Some FAQs are incomplete',
                    body:`They will be removed when saving.`
                })
            } 
            this.saveConfirmed(saveObj)
            

            

            // console.log('SAVEDDDD OBJJJ', saveObj)
        },
        addFaq(lang){
            if (!this.faq[lang]) this.faq[lang] = []
            this.faq[lang].push({
                id:nanoid(),
                question:'',
                answer:'',
            })
        },
        async updateFields(section){
            let {fields:sectionFields,slug} = section
            let page = this.pageFields[slug]
            if (!page) return 

            let pageFields = page.fields

            let updateObj = {}
            sectionFields.forEach(secField => {
                if (pageFields.hasOwnProperty(secField.model)){
                    updateObj[secField.model] = pageFields[secField.model]
                }
            })
            // console.log('UPDATEOBJ url => ' + page.url, updateObj)
            
            if (!page.url || !page.url.trim()) return 

            this.$store.commit('LOAD', {is:true})
            await this.$api.put(page.url, updateObj)
            this.$store.commit('LOAD', {is:false})
        }
    },
    async created(){
        this.$store.commit('LOAD', {is:true})

        let {data:faq} = await this.$api.get('/faq')

        // console.log(faq,'faq');
        
        let {en,fr,en_usa} = faq
        if (!en) en = []
        if (!fr) fr = []
        if (!en_usa) en_usa = []
        this.faq.en = en 
        this.faq.fr = fr
        this.faq.en_usa = en_usa

        this.$store.commit('LOAD', {is:false})
    }

}
</script>
