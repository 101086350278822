<template>
  <div>
    <div
      class="container mx-auto px-4"
      style="max-width: 600px; margin-top:5vw"
    >
      <div class="mb-8">
        <h4 class="mb-4 block text-lg font-medium text-gray-700">
          Forgot Password?
        </h4>
        <p class=" block text-lg font-medium text-gray-700">
          Please enter your Email Address
        </p>
      </div>
      <FormInput
        type="email"
        label="Email"
        v-model="email"
        icon="search"
        class="w-full mb-6"
      />

      <div class="grid grid-cols-2 gap-6 ">
        <Button
          label="Go Back To Login"
          type="primary"
          to="/login"
          class="w-full sm:w-auto mb-8 sm:mb-auto mt-3 sm:mt-auto"
        />
        <Button
          label="Reset"
          type="primary"
          @click="reset"
          class="w-full sm:w-auto mb-8 sm:mb-auto mt-3 sm:mt-auto"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle";
import Icons from "@/components/Icons";
import Button from "@/components/Button";
import FormInput from "@/components/Forms/FormInput";
export default {
  components: {
    PageTitle,
    FormInput,

    Icons,
    Button,
  },
  data() {
    return {
      showErrors: false,
      email: "",
    };
  },
  methods: {
    reset() {
      //   this.showErrors = false;
      //   if (this.hasErrors) {
      //     notify({
      //       title: "Valid Email Required",
      //       color: "danger",
      //       iconPack: "feather",
      //       icon: "icon-alert-circle",
      //       position: "bottom-center",
      //     });
      //     return (this.showErrors = true);
      //   }

      let email = this.email.trim();
      this.$store.commit("LOAD", { is: true });
      this.$api
        .post("/auth/forgot-password", { email })
        .then(({ data }) => {
          // console.log(data);
          this.$store.commit("SET_MODAL", {
            callback: () => this.$router.push("/login"),
            danger: false,
            confirm: false,
            header: "Success",
            body: `Please check your email for further instructions.`,
          });
        })
        .catch(() => {
          this.$store.commit("SET_MODAL", {
            danger: true,
            confirm: false,
            header: "Error",
            body: `An error Occurred while resetting password. Please try again`,
          });
        })
        .finally(() => {
          this.$store.commit("LOAD", { is: false });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
