<template>
  <PageTitle>
    <div class="flex justify-between items-center px-4 sm:px-0">
      <div>
        <router-link
          to="/cms?tab=blogs"
          class="text-blue-500 inline-flex cursor-pointer hover:text-blue-800"
        >
          <Icons i="arrow-left" class="w-4 h-4 hover:round" />
        </router-link>
        <span class="text-gray-400 ml-3" v-if="editMode">
          Blog:
          <span class="text-gray-500 ml-2">{{ item.title }}</span>
        </span>
        <span class="text-gray-400 ml-3" v-else>New Blog</span>
      </div>
      <div class="d-flex flex-column">
        <div v-if="editMode">
          <Button
            label="Delete Blog"
            size="small"
            icon="remove"
            style="height:30px;"
            type="danger"
            @click="deleteBlog"
          />
        </div>
        <div class="mt-2 ">
          <div
            class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in"
          >
            <input
              v-model="item.active"
              type="checkbox"
              name="toggle"
              id="toggle"
              class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
            />
            <label
              for="toggle"
              class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
            ></label>
          </div>
          <label
            for="toggle"
            class="text-xs"
            :class="item.active ? 'text-green-700' : 'text-red-600'"
            >{{ item.active ? "City is Active" : "City is Inactive" }}</label
          >
        </div>
      </div>
    </div>
  </PageTitle>

  <label
    class="mt-10 block text-sm font-medium text-gray-700 text-xl mb-2 container mx-auto px-4"
    >{{ editMode ? "Edit" : "New" }} Blog</label
  >

  <div class="container px-4 mx-auto mb-24 flex" v-if="item.id || init">
    <div class="flex-1">
      <div class="my-4">
        <FormInput
          v-model="item.title"
          label="Blog Title"
          class="w-full"
          :required="true"
          :show-errors="true"
        />
      </div>

      <div class="my-4">
        <FormInput
          v-model="item.description"
          label="Short Description"
          class="w-full"
          :required="true"
          :show-errors="true"
          :maxlen="160"
        />
      </div>

      <Editor
        apiKey="1q5zhs44fg7b98vygm6hswlpqb8ajec9kc93tdist4q9hxjv"
        v-model="item.content"
        :init="editorOptions"
      />
    </div>
    <div style="max-width:300px;width:30%;" class="px-4 md:pl-10 md:pr-0">
      <div>
        <FormSelect
          label="Language"
          v-model="item.language"
          :options="languages"
          selectClass="bg-gray-300 text-gray-700"
        />
      </div>

      <div class="my-10">
        <FormSelect
          label="Category"
          v-model="item.type"
          :options="types"
          selectClass="bg-gray-300 text-gray-700"
        />
      </div>
      <div style="position:sticky; top:10px;" class="flex justify-center">
        <ImgSelector v-model="image" :url="false" label="Featured Image" />
      </div>
      <div class="mt-10 justify-center flex">
        <Button
          :label="editMode ? 'Update Changes' : 'Create Blog'"
          type="primary"
          @click="submit"
          class="min-w-max"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle";
import {
  formatPrice,
  validateEmail,
  baseURL,
  deleteFile,
  attachImage,
} from "@/assets/helper";
import FormInput from "@/components/Forms/FormInput";
import ImgSelector from "@/components/Forms/ImgSelector";
import FormSelect from "@/components/Forms/FormSelect";
import Button from "@/components/Button";
import Icons from "@/components/Icons";
import Editor from "@tinymce/tinymce-vue";
import slugify from "slugify";
export default {
  components: {
    FormInput,
    FormSelect,
    Button,
    Icons,
    PageTitle,
    ImgSelector,
    Editor,
  },
  data: () => ({
    editorOptions: {},
    languages: [
      { label: "English", value: "en" },
      { label: "French", value: "fr" },
      { label: "English(USA)", value: "en_usa" },
    ],
    types: [
      { label: "Blogs", value: "blog" },
      { label: "News", value: "news" },
    ],
    showErrors: false,
    init: false,
    item: {
      title: "",
      content: "",
      type: "blog",
      language: "en",
      slug: "",
      description: "",
      active: null,
    },
    category: "",
    image: {},
  }),
  computed: {
    selectedCats() {
      return this.categories.filter((x) => x.optIn).map((x) => x.id);
    },
    editMode() {
      return Boolean(this.$route.params.slug) && Boolean(this.item.id);
    },
  },
  methods: {
    async deleteConfirmed() {
      this.$store.commit("LOAD", { is: true });
      this.$api
        .delete(`/blogs/${this.item.id}`)
        .then(({ data }) => {
          this.$store.commit("SET_MODAL", {
            callback: () => this.$router.push("/cms"),
            danger: false,
            confirm: false,
            header: `Deleted`,
            body: "This product has been deleted",
          });
        })
        .catch(() => {
          this.$store.commit("SET_MODAL", {
            danger: true,
            confirm: false,
            header: `Uh oh,`,
            body:
              "An error occured while trying to delete this blog. Please try again.",
          });
        })
        .finally(() => {
          this.$store.commit("LOAD", { is: false });
        });
    },
    deleteBlog() {
      this.$store.commit("SET_MODAL", {
        callback: this.deleteConfirmed,
        danger: true,
        confirm: true,
        okText: "Delete",
        header: `This cannot be undone`,
        body: "Are you sure you want to delete this blog?",
      });
    },
    async submit() {
      let sendObj = JSON.parse(JSON.stringify(this.item));
      sendObj.categories = [this.category];
      sendObj.image = [this.image.id];
      sendObj.slug = slugify(sendObj.title);
      if (this.editMode) {
        this.$store.commit("LOAD", { is: true });

        this.$api
          .put(`/blogs/${this.item.id}`, sendObj)
          .then(({ data }) => {
            this.$store.commit("SET_MODAL", {
              callback: () => this.$router.push(`/cms`),
              danger: false,
              confirm: false,
              header: "Success",
              body: `You have successfully updated Blog: ${data.title}`,
            });
          })
          .catch(() => {
            this.$store.commit("SET_MODAL", {
              danger: true,
              confirm: false,
              header: "Error",
              body: `An error Occurred while updating. Please try again`,
            });
          })
          .finally(() => {
            this.$store.commit("LOAD", { is: false });
          });
      } else {
        sendObj.date = new Date().toISOString();

        this.$store.commit("LOAD", { is: true });

        this.$api
          .post(`/blogs`, sendObj)
          .then(({ data }) => {
            this.$store.commit("SET_MODAL", {
              callback: () => this.$router.push(`/cms`),
              danger: false,
              confirm: false,
              header: "Success",
              body: `You have successfully created Blog: ${data.title}`,
            });
          })
          .catch(() => {
            this.$store.commit("SET_MODAL", {
              danger: true,
              confirm: false,
              header: "Uh oh,",
              body: `An error Occurred while updating. Please try again.`,
            });
          })
          .finally(() => {
            this.$store.commit("LOAD", { is: false });
          });
      }
    },
    formatPrice,
  },
  async created() {
    let self = this;

    this.editorOptions = {
      height: 500,
      menubar: false,
      automatic_uploads: false,
      file_picker_callback(callback, value, meta) {
        var input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");

        /*
                Note: In modern browsers input[type="file"] is functional without
                even adding it to the DOM, but that might not be the case in some older
                or quirky browsers like IE, so you might want to add it to the DOM
                just in case, and visually hide it. And do not forget do remove it
                once you do not need it anymore.
                */

        input.onchange = function() {
          var file = this.files[0];
          // console.log("FILEEEEEE", file);
          const formData = new FormData();

          formData.append("files", file, file.name);

          self.$store.commit("LOAD", { is: true });

          self.$api
            .post("/upload", formData, {
              headers: { "Content-Type": "multipart/form-data" },
            })
            .then(({ data }) => {
              if (!data || !data[0] || !data[0].url) return;
              return callback(baseURL + data[0].url, { title: file.name });
            })
            .catch((err) => {})
            .finally(() => {
              self.$store.commit("LOAD", { is: false });
            });
          // var reader = new FileReader();
          // reader.onload = function () {
          //     /*
          //     Note: Now we need to register the blob in TinyMCEs image blob
          //     registry. In the next release this part hopefully won't be
          //     necessary, as we are looking to handle it internally.
          //     */

          //     var id = 'blobid' + (new Date()).getTime();
          //     var blobCache =  tinymce.activeEditor.editorUpload.blobCache;
          //     var base64 = reader.result.split(',')[1];
          //     var blobInfo = blobCache.create(id, file, base64);
          //     blobCache.add(blobInfo);

          //     /* call the callback and populate the Title field with the file name */
          //     callback('blobInfo.blobUri()', { title: file.name });
          // };
          reader.readAsDataURL(file);
        };

        input.click();
      },
      plugins: [
        "advlist autolink lists link image preview anchor",
        "searchreplace visualblocks fullscreen",
        "media",
      ],
      toolbar:
        "undo redo | formatselect | bold italic | \
                alignleft aligncenter alignright alignjustify | image | \
                bullist numlist outdent indent | removeformat | help",
    };

    if (this.$route.params.slug) {
      // console.log(this.$route.params.slug, "idddd");
      this.$store.commit("LOAD", {
        is: true,
        msg: "Retrieving Product Information",
      });

      let { data } = await this.$api.get(`/blogs/${this.$route.params.slug}`);

      let { image, ...blog } = data;

      if (!image) image = {};
      else if (image.length) {
        image = image.map((x) => ({ id: x.id, url: `${baseURL}${x.url}` }))[0];
      }

      this.item = blog;
      this.image = image;
    } else {
      if (this.$route.query?.lang) {
        let lang = this.$route.query.lang;
        // console.log(lang, "langlang");
        if (lang === "en" || lang === "fr" || lang === "en_usa")
          this.item.language = lang;
      }
      this.init = true;
    }

    this.$store.commit("LOAD", { is: false, msg: "" });
  },
  mounted() {
    setTimeout(() => {
      // this.$store.commit('LOAD', {is:true})
    }, 2000);
  },
};
</script>
