<template>
  <PageTitle>
    <div class="flex justify-between items-center px-4 sm:px-0">
      <div>
        <router-link
          to="/cms?tab=provinces"
          class="text-blue-500 inline-flex cursor-pointer hover:text-blue-800"
        >
          <Icons i="arrow-left" class="w-4 h-4 hover:round" />
        </router-link>
        <span class="text-gray-400 ml-3" v-if="editMode">
          Province:
          <span class="text-gray-500 ml-2">{{ item.title }}</span>
        </span>
        <span class="text-gray-400 ml-3" v-else>New Province</span>
      </div>
      <div v-if="editMode">
        <Button
          label="Delete Province"
          size="small"
          icon="remove"
          style="height: 30px"
          type="danger"
          @click="deleteProvince"
        />
      </div>
    </div>
  </PageTitle>

  <label
    class="mt-10 block text-sm font-medium text-gray-700 text-xl mb-2 container mx-auto px-4"
    >{{ editMode ? "Edit" : "New" }} Province</label
  >

  <div class="container px-4 mx-auto mb-24 " v-if="item.id || init">
    <div class="flex-1">
      <div class="my-4">
        <FormInput
          v-model="item.title"
          label="Province Name"
          class="w-full"
          :required="true"
          :show-errors="true"
        />
      </div>
      <div class="my-4">
        <label :for="id" class="block text-sm font-medium text-gray-700"
          >Country</label
        >
        <select
          :name="item.Country"
          v-model="selectedCountry"
          @change="handleCountryChange()"
          class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        >
          <option
            v-for="country in countries"
            :key="country.id"
            :value="country.value"
          >
            {{ country.value }}
          </option>
        </select>
      </div>
    </div>
    <div style="max-width: 300px; width: 30%" class="">
      <div class="mt-10 ">
        <Button
          :label="editMode ? 'Update Changes' : 'Create City'"
          type="primary"
          @click="submit"
          class="min-w-max"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle";
import {
  formatPrice,
  validateEmail,
  baseURL,
  deleteFile,
  attachImage,
} from "@/assets/helper";
import FormInput from "@/components/Forms/FormInput";
import ImgSelector from "@/components/Forms/ImgSelector";
import FormSelect from "@/components/Forms/FormSelect";
import Button from "@/components/Button";
import Icons from "@/components/Icons";
import Editor from "@tinymce/tinymce-vue";
import slugify from "slugify";
export default {
  components: {
    FormInput,
    FormSelect,
    Button,
    Icons,
    PageTitle,
    ImgSelector,
    Editor,
  },
  data: () => ({
    editorOptions: {},
    languages: [
      { label: "English", value: "en" },
      { label: "French", value: "fr" },
      { label: "English(USA)", value: "en_usa" },
    ],

    showErrors: false,
    init: false,
    item: {
      title: "",
      content: "",
      type: "blog",
      language: "en",
      slug: "",
      description: "",
    },
    category: "",
    image: {},
    countries: [
      { id: 1, value: "Canada" },
      { id: 2, value: "US" },
    ],
    lang: null,
    selectedState: "",
  }),
  computed: {
    selectedCats() {
      return this.categories.filter((x) => x.optIn).map((x) => x.id);
    },
    editMode() {
      return Boolean(this.$route.params.id) && Boolean(this.item.id);
    },
  },
  watch: {
    // lang(newVal) {
    // 	if (newVal == 'en' || newVal == 'fr') {
    // 		this.cityUrl = 'cities';
    // 	} else {
    // 		this.cityUrl = 'cities-uses';
    // 	}
    // },
  },
  methods: {
    async deleteConfirmed() {
      this.$store.commit("LOAD", { is: true });
      this.$api
        .delete(`/provinces/${this.item.id}`)
        .then(({ data }) => {
          this.$store.commit("SET_MODAL", {
            callback: () => this.$router.push("/cms?tab=provinces"),
            danger: false,
            confirm: false,
            header: `Deleted`,
            body: "This province has been deleted",
          });
        })
        .catch(() => {
          this.$store.commit("SET_MODAL", {
            danger: true,
            confirm: false,
            header: `Uh oh,`,
            body:
              "An error occured while trying to delete this province. Please try again.",
          });
        })
        .finally(() => {
          this.$store.commit("LOAD", { is: false });
        });
    },
    deleteProvince() {
      this.$store.commit("SET_MODAL", {
        callback: this.deleteConfirmed,
        danger: true,
        confirm: true,
        okText: "Delete",
        header: `This cannot be undone`,
        body: "Are you sure you want to delete this province?",
      });
    },
    handleCountryChange() {
      this.item.Country = this.countries.find(
        (p) => p.value === this.selectedCountry
      ).value;
      // console.log(this.item.country, "neeeewww");
    },
    async submit() {
      // console.log(this.item, "ittttt");
      let sendObj = JSON.parse(JSON.stringify(this.item));
      // sendObj.image = [this.image.id];
      sendObj.slug = slugify(sendObj.title);
      if (this.editMode) {
        this.$store.commit("LOAD", { is: true });
        // console.log(sendObj, "obobob");
        this.$api
          .put(`/provinces/${this.item.id}`, sendObj)
          .then(({ data }) => {
            this.$store.commit("SET_MODAL", {
              callback: () => this.$router.push(`/cms?tab=provinces`),
              danger: false,
              confirm: false,
              header: "Success",
              body: `You have successfully updated City: ${data.title}`,
            });
          })
          .catch(() => {
            this.$store.commit("SET_MODAL", {
              danger: true,
              confirm: false,
              header: "Error",
              body: `An error Occurred while updating. Please try again`,
            });
          })
          .finally(() => {
            this.$store.commit("LOAD", { is: false });
          });
      } else {
        this.$store.commit("LOAD", { is: true });

        this.$api
          .post(`/provinces`, sendObj)
          .then(({ data }) => {
            this.$store.commit("SET_MODAL", {
              callback: () => this.$router.push(`/cms?tab=provinces`),
              danger: false,
              confirm: false,
              header: "Success",
              body: `You have successfully created Province: ${data.title}`,
            });
          })
          .catch(() => {
            this.$store.commit("SET_MODAL", {
              danger: true,
              confirm: false,
              header: "Uh oh,",
              body: `An error Occurred while updating. Please try again.`,
            });
          })
          .finally(() => {
            this.$store.commit("LOAD", { is: false });
          });
      }
    },
    formatPrice,
  },
  async created() {
    let self = this;
    // console.log(this.$route.params.id);

    if (this.$route.params.id) {
      this.$store.commit("LOAD", {
        is: true,
        msg: "Retrieving City Information",
      });
      this.lang = this.$route.query.lang;
      // console.log("lang", this.lang);
      if (this.lang == "en" || this.lang == "fr") {
        this.cityUrl = "cities";
      } else {
        this.cityUrl = "cities-uses";
      }

      let { data } = await this.$api.get(`/provinces/${this.$route.params.id}`);
      // console.log(data, "ooo");
      let { ...province } = data;

      this.item = province;
      this.selectedCountry = this.item.Country;
      // console.log(this.selectedCountry, "cccc");
    } else {
      if (this.$route.query?.lang) {
        let lang = this.$route.query.lang;
        // console.log(lang, "langlang");
        if (lang === "en" || lang === "fr" || lang === "en_usa")
          this.item.language = lang;
      }
    }
    this.init = true;
    this.$store.commit("LOAD", { is: false, msg: "" });
  },
  mounted() {
    setTimeout(() => {
      // this.$store.commit('LOAD', {is:true})
    }, 2000);
  },
};
</script>
