import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

export default createStore({
  plugins: [
    createPersistedState({
      storage: window.localStorage,
    }),
  ],
  state: {
    user: {},
    store: {},
    loading: false,
    loadingMessage: "",
    Modal: {
      show: false,
      callback: null,
      info: true,
      danger: false,
      okText: "Okay",
      confirm: false,
    },
    search: {
      page: 1,
      filter: false,
    },
  },
  mutations: {
    LOAD: (state, { is, msg, showMsg = false }) => {
      state.loading = is;
      if (!is) return (state.loadingMessage = ``);
      if (!showMsg) state.loadingMessage = "";
      else if (msg) state.loadingMessage = msg;
    },
    SET_STORE: (state, store) => (state.store = store),
    SET_USER: (state, user) => (state.user = user),
    SET_MODAL: (state, data) => {
      let obj = {
        show: true,
        callback: data.callback,
        danger: data.danger,
        okText: data.okText || "Okay",
        confirm: Boolean(data.confirm),
      };
      if (data.header) obj.header = data.header.trim();
      if (data.body) obj.body = data.body.trim();
      state.Modal = obj;
    },
    CLOSE_MODALS: (state, data) => {
      state.Modal = {
        show: false,
        callback: null,
        danger: false,
        okText: "Okay",
        confirm: false,
      };
    },
    savePage: (state, page) => {
      state.search.page = page;
    },
    saveFilters: (state, filter) => {
      state.search.filter = filter;
    },
  },
  actions: {},
  getters: {
    isStoreAdmin: (state) => {
      return (
        state.user && state.user.role && state.user.role.type == "storeadmin"
      );
    },
    isSuper: (state) => {
      return (
        state.user && state.user.role && state.user.role.type == "superadmin"
      );
    },
    isCSR: (state) => {
      // console.log(state.user.role);
      return state.user && state.user.role && state.user.role.type == "csr";
    },
  },
  modules: {},
});
