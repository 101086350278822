<template>
  <div>
    <div
      class="container mx-auto px-4"
      style="max-width: 600px; margin-top:5vw"
    >
      <div class="mb-8">
        <h4 class="mb-4 mb-4 block text-lg font-medium text-gray-700">
          Welcome,
        </h4>
        <p>
          <span
            class="mb-4 block text-lg font-medium text-gray-700"
            v-if="hasErrors"
            >Passwords does not match. Please try again</span
          >
          <span v-else>Please enter your password.</span>
        </p>
      </div>

      <FormInput
        type="password"
        label="Password"
        v-model="pass"
        class="w-full mb-6 "
      />
      <FormInput
        type="password"
        label="Confirm Password"
        v-model="cPass"
        class="w-full mb-8"
      />

      <div class="flex flex-wrap justify-between flex-col-reverse sm:flex-row">
        <Button
          label="Create"
          type="primary"
          @click="validate"
          class="w-full sm:w-auto mb-8 sm:mb-auto mt-3 sm:mt-auto"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle";
import Icons from "@/components/Icons";
import Button from "@/components/Button";
import FormInput from "@/components/Forms/FormInput";
export default {
  components: {
    PageTitle,
    FormInput,

    Icons,
    Button,
  },
  data() {
    return {
      user: {},

      pass: "",
      cPass: "",
    };
  },

  methods: {
    // register() {
    //   this.showErrors = false;
    //   let token = this.$route.params.token;
    //   let pass = this.pass.trim();
    //   if (this.hasErrors) return (this.showErrors = true);
    //   this.$update(`/accounts/newacct/${token}`, { pass }).then(({ data }) => {
    //     // console.log('DATA", data', data);
    //     this.$router.push("/pages/login");
    //   });
    // },
    validate() {
      let passwordMatch = false;
      if (this.pass.trim() && this.cPass.trim()) {
        passwordMatch = this.pass.trim() !== this.cPass.trim();
        passwordMatch
          ? this.$store.commit("SET_MODAL", {
              danger: true,
              confirm: false,
              header: "Error",
              body: `Password does not match`,
            })
          : this.create();
      }
    },
    async create() {
      this.showErrors = false;

      // console.log(this.$route, "gggg");
      //   if (this.hasErrors) return (this.showErrors = true);

      const code = this.$route.query.code;
      if (!code) {
        this.$store.commit("SET_MODAL", {
          danger: true,
          confirm: false,
          header: "Error",
          body: `Not a valid Token`,
        });
      }

      try {
        let data = await this.$api.post("/auth/reset-password", {
          code,
          password: this.pass,
          passwordConfirmation: this.cPass,
        });
        // console.log(data, "reset password data");
        this.$store.commit("SET_MODAL", {
          callback: () => this.$router.push("/login"),
          danger: false,
          confirm: false,
          header: "Success",
          body: `Successfully reset password.`,
        });
      } catch (error) {
        // console.log(error);
        this.$store.commit("SET_MODAL", {
          danger: true,
          confirm: false,
          header: "Error",
          body: error,
        });
      }
    },
  },
};
</script>
