<template>
  <div>
    <PageTitle>
      <div class="flex justify-between">
        <div>Dealers</div>
        <div>
          <Button
            label="Add Dealer"
            size="small"
            icon="plus"
            style="height: 30px"
            to="/newdealer"
            type="primary"
          />
        </div>
      </div>
    </PageTitle>

    <div class="container px-4 mx-auto mb-10">
      <div class="grid grid-cols-6 gap-6 mt-3 mb-5">
        <div class="col-span-3 relative">
          <FormInput
            class="col-span-6 sm:col-span-3"
            label="Search"
            placeholder=" "
            icon="search"
            v-model="query"
            type="text"
          />
        </div>
      </div>

      <table class="table-auto w-full table-styled">
        <thead>
          <tr>
            <th class="bg-green-50">NAME</th>
            <th class="bg-green-50">PHONE</th>
            <!-- <th class="bg-green-50">EMAIL</th> -->
            <th class="bg-green-50">CITY</th>
            <th class="bg-green-50">REGION</th>
            <th class="bg-green-50">ACTIVE STATUS</th>
          </tr>
        </thead>
        <tbody class="td-centers">
          <tr v-for="item in queriedResults" :key="item.id">
            <td>
              <router-link
                :to="`/dealers/${item.id}`"
                class="font-semibold text-green-600 hover:text-green-800"
                >{{ item.name }}</router-link
              >
            </td>
            <td>{{ item.phone }}</td>
            <!-- <td>{{item.email}}</td> -->
            <td>{{ item.city }}</td>
            <td>{{ item.region }}</td>
            <td :class="item.active ? '' : 'bg-red-50'">
              <div class="text-center flex justify-center">
                <Icons
                  :i="item.active ? 'check' : 'close'"
                  class="w-5"
                  :class="item.active ? 'text-green-800' : 'text-red-800'"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        class="w-full flex flex-col items-center justify-center py-7 text-gray-300"
        v-if="queriedResults.length === 0"
      >
        <Icons i="sadface" class="w-16" />
        <p class="text-gray-500 mt-3">
          Sorry, could not find any dealers in your system.
          {{ items.length === 0 ? "Please add dealers to get started." : "" }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle";
import Icons from "@/components/Icons";
import Button from "@/components/Button";
import FormInput from "@/components/Forms/FormInput";
import { formatPrice, formatDate } from "@/assets/helper";
import Datepicker from "vue3-datepicker";
import $qs from "qs-stringify";

export default {
  components: {
    PageTitle,
    FormInput,
    Datepicker,
    Icons,
    Button,
  },
  data: () => ({
    query: "",
    items: [],
    filtered: [],
  }),
  watch: {
    async query(val) {
      if (val.length < 3) {
        if (this.timerId) clearTimeout(this.timerId);
        return (this.filtered = []);
      }
      const makeAPICall = async () => {
        let query = $qs({
          _where: {
            _or: [
              { name_contains: val },
              { phone_contains: val },
              { email_contains: val },
            ],
          },
        });
        let { data } = await this.$api.get(`/stores/autocomplete?${query}`);
        if (data.length) this.filtered = data;
      };

      if (this.timerId) clearTimeout(this.timerId);

      this.timerId = setTimeout(function () {
        makeAPICall();
        this.timerId = undefined;
      }, 500);
    },
  },
  methods: {
    search() {},

    revertDealer() {
      this.dealerSearch = {
        query: "",
        results: [],
      };
      this.chosenDealer = {};
      this.filter.dealer = "";
    },
    hideResults() {
      this.dealerSearch.query = "";
      this.dealerSearch.results = [];
    },
    test(e) {
      // console.log("EEEE CLICK OUTSIDE", e);
    },
    formatPrice,
    formatDate,
    chooseDealer(dealer) {
      // console.log("CHOSEN DEALER", dealer);
      this.filter.dealer = dealer.id;
      this.chosenDealer = dealer;
      this.dealerSearch.results = [];
    },
  },
  computed: {
    queriedResults() {
      if (this.query.length > 2) return this.filtered;
      return this.items;
    },
    disabledDateFrom() {},
  },
  async created() {
    this.$store.commit("LOAD", { is: true });
    let { data } = await this.$api.get("/stores");
    this.items = data;
    this.$store.commit("LOAD", { is: false });
  },
};
</script>

<style></style>
