<template>
    <div class="w-full md:w-3/5 mx-auto mb-10">

        <div class="shadow-md">

            <template v-if="slides[lang] && slides[lang].length">

                <template v-for="(slide,slideI) in slides[lang]" :key="slide.id">

                    <div class="tab w-full overflow-hidden border-t" >

                        <input class="absolute opacity-0" :id="slide.id" type="radio" name="accordionPages">
                        <label class="block p-5 leading-normal cursor-pointer" :for="slide.id">#{{slideI + 1}}. {{slide.title?.trim()? slide.title : 'Slide' }} <span class="text-red-800" v-if="!slide.active">(Disabled)</span></label>

                        <div class="tab-content overflow-hidden border-l-2 bg-gray-100 border-green-200 leading-normal">
                            <div class="p-5">


                            <!-- new slider -->
                                <div class="mt-4 mb-10">
                                    <p class="text-gray-600 mb-2">Select Style</p>
                                    <select name="selectStyle" id="selectStyle" v-model="slide.style">
                                        <option value="img-only">image only</option>
                                        <option value="image-and-content">image and content</option>
                                    </select>
                                </div>
                                <section  v-if="slide.style == 'img-only' ">
                                    <div class="mt-5 mb-10 space-x-4">
                                        <div>
                                            <ImgSelector v-model="slide.bg" :removable="true" :url="true" label="Desktop Image"  />
                                        </div>
                                    </div>
                                    <div class="mt-5 mb-10  space-x-4">
                                        <div>
                                            <ImgSelector v-model="slide.img" :removable="true" :url="true" label="Mobile Image"  />
                                        </div>
                                    </div>
                                    <div class="mt-5 mb-10 space-x-4">
                                        <div>
                                            <FormInput v-model="slide.button.link" label="Image Link" placeholder="eg. /products/, /about" style="max-width:600px"/>
                                        </div>
                                    </div>

                                    <hr class="my-5"/>

                                    <div class="flex justify-between flex-wrap">
                                        <Button label="Delete Slide" type="danger" @click="deleteSlide(slide)" icon="close" class="min-w-max" style="font-size:15px; padding:1em 2em;" />
                                        <Toggle label="Enabled" type="danger" v-model="slide.active" style="font-size:15px; padding:1em 2em;" />
                                    </div>

                                </section>

                                <section  v-if="slide.style == 'image-and-content' ">
                                    <div class="mt-5 mb-10 space-x-4">
                                        <div>
                                            <ImgSelector v-model="slide.bg" :removable="true" :url="true" label="Upload Image"  />
                                        </div>
                                    </div>
                                    <div class="mt-4">
                                        <p class="text-gray-600 mb-2">Image Position</p>
                                        <select name="imgPosition" id="imgPosition" v-model="slide.imgPosition">
                                            <option value="left" selected>Left</option>
                                            <option value="right">Right</option>
                                        </select>
                                    </div>


                                    <div class="mt-4">
                                        <p class="text-gray-600 mb-2">Text Align</p>
                                        <select name="textAlign" id="textAlign" v-model="slide.textAlign">
                                            <option value="left" selected>Left</option>
                                            <option value="center">Center</option>
                                            <option value="right">Right</option>
                                        </select>
                                    </div>


                                    <div class="mt-4">
                                        <p class="text-gray-600 mb-2">Subtitle</p>
                                        <textarea v-model="slide.sub" class="w-full md:w-1/2 min-h-ta border-gray-400" />
                                    </div>
                                    <div class="mt-4">
                                        <p class="text-gray-600 mb-2">Body</p>
                                        <textarea v-model="slide.body" class="w-full md:w-1/2 min-h-ta border-gray-400" />
                                    </div>
                                    <div class="mt-4">
                                        <FormInput v-model="slide.button.label" label="Button Label" placeholder="Order Now" style="max-width:600px"/>
                                    </div>
                                    <div class="mt-4">
                                        <FormInput v-model="slide.button.link" label="Button Link" placeholder="eg. /products/, /about" style="max-width:600px"/>
                                    </div>
                                    <div class="mt-4">
                                        <p class="text-gray-600 mb-2">Disclaimer</p>
                                        <textarea v-model="slide.disclaimer" class="w-full md:w-1/2 min-h-ta border-gray-400" />
                                    </div>

                                    <hr class="my-5"/>

                                    <div class="flex justify-between flex-wrap">
                                        <Button label="Delete Slide" type="danger" @click="deleteSlide(slide)" icon="close" class="min-w-max" style="font-size:15px; padding:1em 2em;" />
                                        <Toggle label="Enabled" type="danger" v-model="slide.active" style="font-size:15px; padding:1em 2em;" />
                                    </div>

                                </section>
                            <!-- end new slider -->


                            <!--
                                <div class="mt-3 mb-10 flex space-x-4">
                                    <div >
                                        <ImgSelector v-model="slide.bg" :removable="true" :url="true" label="Background Image" />
                                    </div>
                                    <div >
                                        <ImgSelector v-model="slide.img" :removable="true" :url="true" label="Inner Image (optional)" />
                                    </div>
                                </div>

                                <div>
                                    <FormInput v-model="slide.title" label="Title" style="max-width:600px"/>
                                </div>
                                <div class="mt-4">
                                    <p class="text-gray-600 mb-2">Subtitle</p>
                                    <textarea v-model="slide.sub" class="w-full md:w-1/2 min-h-ta border-gray-400" />
                                </div>
                                <div class="mt-4">
                                    <p class="text-gray-600 mb-2">Body</p>
                                    <textarea v-model="slide.body" class="w-full md:w-1/2 min-h-ta border-gray-400" />
                                </div>

                                <hr class="my-3" />

                                <p class="my-3 text-green-600 font-bold">Button for your Slider. Leave them blank to hide the button.</p>

                                <div>
                                    <FormInput v-model="slide.button.label" label="Button Label" placeholder="Order Now" style="max-width:600px"/>
                                </div>
                                <div>
                                    <FormInput v-model="slide.button.link" label="Button Link" placeholder="eg. /products/, /about" style="max-width:600px"/>
                                </div>

                                <hr class="my-5"/>

                                <div class="flex justify-between flex-wrap">
                                    <Button label="Delete Slide" type="danger" @click="deleteSlide(slide)" icon="close" class="min-w-max" style="font-size:15px; padding:1em 2em;" />
                                    <Toggle label="Enabled" type="danger" v-model="slide.active" style="font-size:15px; padding:1em 2em;" />
                                </div>
                            -->

                            </div>
                        </div>

                    </div>

                </template>

            </template>
            
           
        </div>
        <div class="w-full flex flex-col items-center justify-center py-7 text-gray-300" v-if="!slides[lang] || !slides[lang].length">
            <Icons i="sadface" class="w-16" />
            <p class="text-gray-500 mt-3">
                You have no Slides added for {{langName}}
            </p>
        </div>
        <div class="my-10 w-full text-center justify-between flex space-x-4">
            <Button :label="`Save Slides (${langName})`" type="outline-primary" @click="save" class="min-w-max" />
            <Button label="Add Slide" type="primary" @click="addSlide" icon="plus" class="min-w-max" />
        </div>

    </div>
</template>

<script>


import ImgSelector from '@/components/Forms/ImgSelector'
import Toggle from '@/components/Forms/Toggle'
import Button from '@/components/Button'
import Icons from '@/components/Icons'
import FormInput from "@/components/Forms/FormInput";
import { nanoid } from 'nanoid'

export default {
    props:['lang'],
    components:{
        Button,
        FormInput,
        Icons,
        ImgSelector,
        Toggle,
    },
    computed:{
        langName(){
            return this.lang === 'en' ? 'English' : this.lang === 'en_usa' ? 'English(USA)' : 'French'
        }
    },
    data:( () => ({
        
        slides:{
            en:[],
            fr:[],
            en_usa: []
        }

    })),
    methods:{
        deleteSlide(slide){
            // this.$store.commit('SET_MODAL',{
            //     callback:() => this.deleteSlide(slide),
            //     danger:true,
            //     confirm:true,
            //     header:`Deleted`,
            //     body:'This product has been deleted',
            // })
            if (this.slides[this.lang]?.length){
                this.slides[this.lang] = this.slides[this.lang].filter(x => x.id !== slide.id)
            }

        },
        async save(){

            let key = 'slides'
            if (this.lang === 'fr') key = 'slides_fr'

            if (this.lang === 'en_usa') key = 'slides_en_usa'

            let saveObj = {[key]:this.slides[this.lang]}

            this.$store.commit('LOAD', {is:true})

            let {data} = await this.$api.put('/slider',saveObj)
            this.slides[this.lang] = data[key]

            this.$store.commit('LOAD', {is:false})
            
        },
        addSlide(){
            let lang = this.lang
            if (!this.slides[lang]) this.slides[lang] = []
            this.slides[lang].push({
                id:nanoid(),
                style:'img-only',
                title:'',
                sub:'',
                body:'',
                bg:'',
                img:'',
                imgPosition: 'left',
                textAlign: 'left',
                button:{
                    link:'',
                    label:'',
                }
            })
        },
        async updateFields(section){
            let {fields:sectionFields,slug} = section
            let page = this.pageFields[slug]
            if (!page) return 

            let pageFields = page.fields

            let updateObj = {}
            sectionFields.forEach(secField => {
                if (pageFields.hasOwnProperty(secField.model)){
                    updateObj[secField.model] = pageFields[secField.model]
                }
            })
            // console.log('UPDATEOBJ url => ' + page.url, updateObj)
            
            if (!page.url || !page.url.trim()) return 

            this.$store.commit('LOAD', {is:true})
            await this.$api.put(page.url, updateObj)
            this.$store.commit('LOAD', {is:false})
        }
    },
    async created(){
        this.$store.commit('LOAD', {is:true})

        let {data:slider} = await this.$api.get('/slider')

        
        let {slides,slides_fr,slides_en_usa} = slider

        if (!slides) slides = []
        if (!slides_fr) slides_fr = []
        if (!slides_en_usa) slides_en_usa = []


        this.slides.en = slides.map(x => {
                            if (!x.hasOwnProperty('active')) x.active = true
                            return x
                        }) 
        this.slides.fr = slides_fr.map(x => {
                            if (!x.hasOwnProperty('active')) x.active = true
                            return x
                        })
        this.slides.en_usa = slides_en_usa.map(x => {
                            if (!x.hasOwnProperty('active')) x.active = true
                            return x
                        })

        this.$store.commit('LOAD', {is:false})
    }

}
</script>
