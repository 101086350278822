<template>
    <div class="col-span-6 sm:col-span-3">
        <label :for="id" class="block text-sm font-medium text-gray-700">{{label}}</label>
        <select :class="selectClass" :name="id" :id="id" :required="isReq" @change="this.$emit('update:modelValue',$event.target.value)" :value="modelValue" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
            <option v-for="opt in options" :key="opt.value" :value="opt.value">{{opt.label}}</option>
        </select>
        
    </div>
</template>

<script>
import { nanoid } from 'nanoid'

export default {
    props:{
        id:{
            type:String,
            default:nanoid(5)
        },
        label:{
            type:String,
            default:'',
        },
        modelValue:{
            type:[String,Number],
            default:''
        },
        options:{
            type:Array,
            default: () => []
        },
        rules:{
            type:Object,
            default:{}
        },
        selectClass:{
            type:String,
            default:'',
        }
    },
    computed:{
        isReq(){
            return this.rules.req || this.rules.required || false
        },
        min(){
            if (rules.min) return rules.min 
            else return 0
        },
        max(){
            if (rules.min) return rules.min 
            else return 0
        },
        isValid(){
        }
    }

  

}
</script>

<style>

</style>