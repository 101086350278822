<template>
  <div>
    <div
      class="container mx-auto px-4"
      style="max-width: 600px; margin-top: 5vw"
    >
      <form>
        <div class="grid grid-cols-4 gap-6">
          <FormInput
            :show-errors="showErrors"
            class="col-span-4 sm:col-span-3"
            label="Username / Email"
            placeholder=" "
            v-model="username"
            type="text"
            :required="true"
          />
          <FormInput
            placeholder=" "
            :show-errors="showErrors"
            class="col-span-4 sm:col-span-3"
            label="Password"
            v-model="password"
            type="password"
            :required="true"
            @keyup.enter="login"
          />
        </div>
      </form>

      <div>
        <p v-if="showErrors" class="errorMessage">
          Something went wrong please try again
        </p>
      </div>

      <div class="flex justify-start gap-6 mt-10">
        <Button
          :disabled="disableButtons"
          label="Login"
          type="primary"
          class="sm:order-2"
          @click="login"
        />
        <Button
          label="Forgot Password"
          type="primary"
          class="sm:order-2 text-center"
          to="/forget"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle";
import Icons from "@/components/Icons";
import FormInput from "@/components/Forms/FormInput";
import Button from "@/components/Button";
import { saveAuth } from "@/assets/helper";

export default {
  name: "Home",
  components: {
    PageTitle,
    Icons,
    FormInput,
    Button,
  },
  data: () => ({
    disableButtons: false,
    username: "",
    password: "",
    showErrors: false,
    loadPage: false,
  }),
  methods: {
    login() {
      this.showErrors = false;
      this.loadPage = true;
      if (!this.username.trim() || !this.password.trim())
        return (this.showErrors = true);
      this.$store.commit("LOAD", { is: true });
      this.$api
        .post("/auth/local", {
          identifier: this.username.trim(),
          password: this.password,
        })
        .then((res) => {
          let { data } = res;
          if (data.jwt) {
            this.$router.push("/");
            saveAuth(data, this.$store);
          }
        })
        .catch((err) => {
          this.showErrors = true;
        })
        .finally(() => {
          this.loadPage = false;
          this.$store.commit("LOAD", { is: false });
        });
    },
  },
};
</script>

<style lang="scss">
.errorMessage {
  font-size: 1rem;
  margin: 2rem 0;
  font-weight: bold;
}
.home-page-grid {
  margin-top: 3vw;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5vw;
  @media screen and (min-width: 968px) {
    grid-template-columns: repeat(4, 1fr);
  }
}
.dash-item {
  cursor: pointer;
  &:before {
    content: "";
  }
  border: 1px solid var(--green);
  min-height: 200px;
  font-weight: bold;
  svg {
    margin: 0 auto 5px;
    width: 40%;
  }
  &:hover {
    background: #fffbdb;
  }
}
</style>
