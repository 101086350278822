import Home from "../views/Home.vue";
export default [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { requiresAuth: true },
  },
  {
    path: "/cms",
    name: "Web Content",
    component: () =>
      import(/* webpackChunkName: "newordd33der" */ "@/views/CMS.vue"),
    meta: { requiresAuth: true, superOnly: true },
  },
  {
    path: "/cms/newblog",
    name: "New Blogs",
    component: () =>
      import(/* webpackChunkName: "blogsadds" */ "@/views/Blog.vue"),
    meta: { requiresAuth: true, superOnly: true },
  },
  {
    path: "/cms/blog/:slug",
    name: "Edit Blogs",
    component: () =>
      import(/* webpackChunkName: "blogsadd" */ "@/views/Blog.vue"),
    meta: { requiresAuth: true, superOnly: true },
  },
  {
    path: "/cms/newCity",
    name: "New City",
    component: () =>
      import(/* webpackChunkName: "blogsadds" */ "@/views/City.vue"),
    meta: { requiresAuth: true, superOnly: true },
  },
  {
    path: "/cms/city/:id",
    name: "Edit Cities",
    component: () =>
      import(/* webpackChunkName: "blogsadd" */ "@/views/City.vue"),
    meta: { requiresAuth: true, superOnly: true },
  },
  {
    path: "/cms/newProvince",
    name: "New Province",
    component: () =>
      import(/* webpackChunkName: "blogsadds" */ "@/views/Province.vue"),
    meta: { requiresAuth: true, superOnly: true },
  },
  {
    path: "/cms/province/:id",
    name: "Edit Province",
    component: () =>
      import(/* webpackChunkName: "blogsadd" */ "@/views/Province.vue"),
    meta: { requiresAuth: true, superOnly: true },
  },
  {
    path: "/neworder",
    name: "New Order",
    component: () =>
      import(/* webpackChunkName: "neworder" */ "@/views/NewOrder.vue"),
    meta: { requiresAuth: true, csr: true },
  },
  {
    path: "/orders",
    name: "View Orders",
    component: () =>
      import(/* webpackChunkName: "viewrders" */ "@/views/ViewOrders.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/orders/:id",
    name: "Order Card",
    component: () =>
      import(/* webpackChunkName: "order" */ "@/views/Order.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/customers",
    name: "View Customers",
    component: () =>
      import(/* webpackChunkName: "viewrders" */ "@/views/ViewCustomers.vue"),
    meta: { requiresAuth: true, superOnly: true },
  },
  {
    path: "/customers/:id",
    name: "Customer Card",
    component: () =>
      import(/* webpackChunkName: "zzzz" */ "@/views/Customer.vue"),
    meta: { requiresAuth: true, superOnly: true },
  },
  {
    path: "/categories",
    name: "View Categories",
    component: () =>
      import(
        /* webpackChunkName: "viedafdwrders" */ "@/views/ViewCategories.vue"
      ),
    meta: { requiresAuth: true, superOnly: true },
  },
  {
    path: "/categories/:id",
    name: "Edit Categories",
    component: () =>
      import(/* webpackChunkName: "dafadsfasdf" */ "@/views/EditCategory.vue"),
    meta: { requiresAuth: true, superOnly: true },
  },
  {
    path: "/newcategory",
    name: "Create Categories",
    component: () =>
      import(/* webpackChunkName: "viewrders" */ "@/views/EditCategory.vue"),
    meta: { requiresAuth: true, superOnly: true },
  },
  {
    path: "/products",
    name: "View Products",
    component: () =>
      import(/* webpackChunkName: "viewrders" */ "@/views/ViewProducts.vue"),
    meta: { requiresAuth: true, superOnly: true },
  },
  {
    path: "/newproduct",
    name: "New Product",
    component: () =>
      import(/* webpackChunkName: "adfadsfassss" */ "@/views/EditProduct.vue"),
    meta: { requiresAuth: true, superOnly: true },
  },
  {
    path: "/products/:id",
    name: "Edit Product",
    component: () =>
      import(/* webpackChunkName: "wwwwwxxxx" */ "@/views/EditProduct.vue"),
    meta: { requiresAuth: true, superOnly: true },
  },
  {
    path: "/dealers",
    name: "View Dealers",
    component: () =>
      import(/* webpackChunkName: "viewrders" */ "@/views/ViewDealers.vue"),
    meta: { requiresAuth: true, superOnly: true },
  },

  {
    path: "/dealers/:id",
    name: "Dealer Card",
    component: () =>
      import(/* webpackChunkName: "qweqwe22" */ "@/views/Dealer.vue"),
    meta: { requiresAuth: true, superOnly: true },
  },
  {
    path: "/dealers/:id/edit",
    name: "Edit Dealer",
    component: () =>
      import(/* webpackChunkName: "order" */ "@/views/EditDealer.vue"),
    meta: { requiresAuth: true, superOnly: true },
  },
  {
    path: "/newdealer",
    name: "New Dealer",
    component: () =>
      import(/* webpackChunkName: "zxcvzsda" */ "@/views/EditDealer.vue"),
    meta: { requiresAuth: true, superOnly: true },
  },
  {
    path: "/newDealerInvite",
    name: "New Dealer Invite",
    component: () =>
      import(/* webpackChunkName: "zxcvzsda" */ "@/views/NewDealer.vue"),
    meta: { requiresAuth: true, superOnly: true },
  },
  {
    path: "/dealers/:id/area",
    name: "Edit Dealer Area",
    component: () =>
      import(/* webpackChunkName: "aa33" */ "@/views/EditDealerArea.vue"),
    meta: { requiresAuth: true, superOnly: true },
  },
  {
    path: "/promotions",
    name: "View Promo",
    component: () =>
      import(/* webpackChunkName: "cc44" */ "@/views/ViewPromotions.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/Login.vue"),
  },
  {
    path: "/forget",
    name: "Forget",
    component: () =>
      import(/* webpackChunkName: "forget" */ "@/views/Forget.vue"),
  },
  {
    path: "/reset",
    name: "Reset",
    component: () =>
      import(/* webpackChunkName: "reset" */ "@/views/Reset.vue"),
  },
  {
    path: "/newacc/:token",
    name: "New Account",
    component: () =>
      import(/* webpackChunkName: "reset" */ "@/views/NewAcc.vue"),
  },
  {
    path: "/partners",
    name: "View Partners",
    component: () =>
      import(
        /* webpackChunkName: "viedafdwrders" */ "@/views/ViewPartners.vue"
      ),
    meta: { requiresAuth: true, superOnly: true },
  },
  {
    path: "/partners/:id",
    name: "Edit Partner",
    component: () =>
      import(/* webpackChunkName: "dafadsfasdf" */ "@/views/EditPartner.vue"),
    meta: { requiresAuth: true, superOnly: true },
  },
  {
    path: "/newpartner",
    name: "Create Partners",
    component: () =>
      import(/* webpackChunkName: "viewrders" */ "@/views/EditPartner.vue"),
    meta: { requiresAuth: true, superOnly: true },
  },
  {
    path: "/edi",
    name: "Edi Upload",
    component: () => import(/* webpackChunkName: "..." */ "@/views/Edi.vue"),
    meta: { requiresAuth: true, superOnly: true },
  },
];
