
export default {
    US:[
        `Alabama`,
            `Alaska`,
            `American Samoa`,
            `Arizona`,
            `Arkansas`,
            `California`,
            `Colorado`,
            `Connecticut`,
            `Delaware`,
            `District of Columbia`,
            `Federated States of Micronesia`,
            `Florida`,
            `Georgia`,
            `Guam`,
            `Hawaii`,
            `Idaho`,
            `Illinois`,
            `Indiana`,
            `Iowa`,
            `Kansas`,
            `Kentucky`,
            `Louisiana`,
            `Maine`,
            `Marshall Islands`,
            `Maryland`,
            `Massachusetts`,
            `Michigan`,
            `Minnesota`,
            `Mississippi`,
            `Missouri`,
            `Montana`,
            `Nebraska`,
            `Nevada`,
            `New Hampshire`,
            `New Jersey`,
            `New Mexico`,
            `New York`,
            `North Carolina`,
            `North Dakota`,
            `Northern Mariana Islands`,
            `Ohio`,
            `Oklahoma`,
            `Oregon`,
            `Palau`,
            `Pennsylvania`,
            `Puerto Rico`,
            `Rhode Island`,
            `South Carolina`,
            `South Dakota`,
            `Tennessee`,
            `Texas`,
            `Utah`,
            `Vermont`,
            `Virgin Islands`,
            `Virginia`,
            `Washington`,
            `West Virginia`,
            `Wisconsin`,
            `Wyoming`,
            `Armed Forces Africa`,
            `Armed Forces Americas (except Canada)`,
            `Armed Forces Canada`,
            `Armed Forces Europe`,
            `Armed Forces Middle East`,
            `Armed Forces Pacific`,
    ],
    CA:[
        {label:'Newfoundland and Labrador'  , value:'NL'},
        {label:'Prince Edward Island'  , value:'PE'},
        {label:'Nova Scotia' , value:'NS'},
        {label:'New Brunswick' , value:'NB'},
        {label:'Quebec' , value:'QC'},
        {label:'Ontario' , value:'ON'},
        {label:'Manitoba' , value:'MB'},
        {label:'Saskatchewan' , value:'SK'},
        {label:'Alberta' , value:'AB'},
        {label:'British Columbia' , value:'BC'},
        {label:'Yukon' , value:'YT'},
        {label:'Northwest Territories'  , value:'NT'},
        {label:'Nunavut' , value:'NU'}
    ]
}