<template>
  <PageTitle>
    <div class="flex justify-between items-center px-4 sm:px-0">
      <div>
        <router-link
          :to="backLink"
          class="text-blue-500 inline-flex cursor-pointer hover:text-blue-800"
        >
          <Icons i="arrow-left" class="w-4 h-4 hover:round" />
        </router-link>
        <span class="text-gray-400 ml-3" v-if="editMode">
          Edit Dealer:
          <span class="text-gray-500 ml-2">{{ item.name }}</span>
        </span>
        <span class="text-gray-400 ml-3" v-else>New Dealer</span>
      </div>
      <div class="">
        <div
          class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in"
        >
          <input
            v-model="item.active"
            type="checkbox"
            name="toggle"
            id="toggle"
            class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
          />
          <label
            for="toggle"
            class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
          ></label>
        </div>
        <label
          for="toggle"
          class="text-xs"
          :class="item.active ? 'text-green-700' : 'text-red-600'"
          >{{ item.active ? "Dealer is Active" : "Dealer is Inactive" }}</label
        >
      </div>
    </div>
  </PageTitle>
  <div class="mt-14 container px-4 mx-auto mb-24">
    <label
      class="block text-sm font-medium text-gray-700 text-xl mb-6 border-gray-300"
      style="border-bottom-width: 1px"
      >General Details</label
    >

    <div class="md:w-2/3">
      <ul class="text-gray-500 text-sm space-y-4">
        <li>
          <FormInput
            :show-errors="showErrors"
            :required="true"
            label="Dealer Name"
            v-model="item.name"
          />
        </li>

        <li>
          <FormInput
            :show-errors="showErrors"
            :check="validateEmail"
            check-error="Not a valid Email"
            label="Email"
            v-model="item.email"
          />
        </li>
        <li>
          <FormInput
            :show-errors="showErrors"
            :check="validateEmail"
            check-error="Not a valid Email"
            label="Second Email"
            v-model="item.emailSecond"
          />
        </li>
        <li>
          <FormInput label="Phone" v-model="item.phone" />
        </li>
        <li>
          <FormInput label="Address" v-model="item.address" />
        </li>
        <li>
          <FormSelect
            class="col-span-6 sm:col-span-3"
            label="Country"
            v-model="item.country"
            :options="[
              { label: 'United States', value: 'United States' },
              { label: 'Canada', value: 'CA' },
            ]"
            :required="true"
          />
        </li>
        <li>
          <FormSelect
            class="col-span-6 sm:col-span-3"
            :label="item.country === 'CA' ? 'Province' : 'State'"
            v-model="item.region"
            :options="regionOptions[item.country]"
            :required="true"
          />
        </li>
        <li>
          <FormInput label="City" v-model="item.city" />
        </li>
        <li>
          <FormInput label="Postal Code" v-model="item.code" />
        </li>
      </ul>
    </div>

    <label
      class="block text-sm font-medium text-gray-700 text-xl mt-10 mb-6 border-gray-300"
      style="border-bottom-width: 1px"
      >Tax Details</label
    >

    <div class="md:w-2/3">
      <div class="text-gray-500 text-sm space-x-4 flex">
        <div>
          <FormInput
            :show-errors="showErrors"
            :required="true"
            label="HST"
            type="number"
            v-model="item.hst"
            style="max-width: 200px"
          />
        </div>

        <div>
          <FormInput
            :show-errors="showErrors"
            :required="true"
            label="GST"
            type="number"
            v-model="item.gst"
            style="max-width: 200px"
          />
        </div>
        <div>
          <FormInput
            :show-errors="showErrors"
            :required="true"
            label="PST"
            type="number"
            v-model="item.pst"
            style="max-width: 200px"
          />
        </div>
        <div>
          <FormInput
            :show-errors="showErrors"
            :required="true"
            label="QST"
            type="number"
            v-model="item.qst"
            style="max-width: 200px"
          />
        </div>
        <div>
          <FormInput
            :show-errors="showErrors"
            :required="true"
            label="Other"
            type="number"
            v-model="item.taxpercentage"
            style="max-width: 200px"
          />
        </div>
      </div>
    </div>

    <label
      class="block text-sm font-medium text-gray-700 text-xl mt-10 mb-6 border-gray-300"
      style="border-bottom-width: 1px"
      >Order Details</label
    >

    <div class="md:w-2/3">
      <div class="text-gray-500 text-sm space-x-4 flex">
        <div>
          <FormInput
            :show-errors="showErrors"
            :required="true"
            label="Pit"
            type="text"
            v-model="item.pit"
            style="max-width: 200px"
          />
        </div>

        <div>
          <label class="block text-sm font-medium text-gray-700"
            >Delivery Offset (in Days)</label
          >
          <div
            class="flex flex-row h-10 w-full rounded-lg relative bg-transparent mt-1"
            style="max-width: 200px"
          >
            <button
              data-action="decrement"
              class="bg-gray-100 text-gray-600 hover:text-gray-700 hover:bg-gray-200 h-full w-20 rounded-l cursor-pointer outline-none"
              @click="decreaseDelivery"
            >
              <span class="m-auto text-2xl font-thin">−</span>
            </button>
            <input
              type="number"
              class="border-none outline-none focus:outline-none text-center w-full bg-gray-50 font-semibold text-md hover:text-black focus:text-black md:text-basecursor-default flex items-center text-gray-700 outline-none"
              name="custom-input-number"
              v-model.number="item.deliveryOffset"
            />
            <button
              data-action="increment"
              class="bg-gray-100 text-gray-700 hover:text-gray-700 hover:bg-gray-200 h-full w-20 rounded-r cursor-pointer"
              @click="increaseDelivery"
            >
              <span class="m-auto text-2xl font-thin">+</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="flex-1 mt-14">
      <label
        class="block text-sm font-medium text-gray-700 text-xl mb-6 pb-2 border-gray-300"
        style="border-bottom-width: 1px"
        >Delivery Availability
        <em class="text-red-700" v-if="!item.active">(Closed for Delivery)</em>
      </label>
      <div
        class="mt-3 flex flex-wrap space-x-3"
        :class="!item.active ? 'opacity-40 pointer-events-none' : ''"
      >
        <label
          for="monday"
          class="px-4 py-3 rounded-md inline-flex items-center mb-3"
          :class="item.openMon ? 'bg-green-200' : ''"
        >
          <input type="checkbox" id="monday" v-model="item.openMon" />
          <span class="ml-3"> Monday </span>
        </label>
        <label
          for="tuesday"
          class="px-4 py-3 rounded-md inline-flex items-center mb-3"
          :class="item.openTue ? 'bg-green-200' : ''"
        >
          <input type="checkbox" id="tuesday" v-model="item.openTue" />
          <span class="ml-3"> Tuesday </span>
        </label>
        <label
          for="wed"
          class="px-4 py-3 rounded-md inline-flex items-center mb-3"
          :class="item.openWed ? 'bg-green-200' : ''"
        >
          <input type="checkbox" id="wed" v-model="item.openWed" />
          <span class="ml-3"> Wednesday </span>
        </label>
        <label
          for="thu"
          class="px-4 py-3 rounded-md inline-flex items-center mb-3"
          :class="item.openThu ? 'bg-green-200' : ''"
        >
          <input type="checkbox" id="thu" v-model="item.openThu" />
          <span class="ml-3"> Thursday </span>
        </label>
        <label
          for="fri"
          class="px-4 py-3 rounded-md inline-flex items-center mb-3"
          :class="item.openFri ? 'bg-green-200' : ''"
        >
          <input type="checkbox" id="fri" v-model="item.openFri" />
          <span class="ml-3"> Friday </span>
        </label>
        <label
          for="sat"
          class="px-4 py-3 rounded-md inline-flex items-center mb-3"
          :class="item.openSat ? 'bg-green-200' : ''"
        >
          <input type="checkbox" id="sat" v-model="item.openSat" />
          <span class="ml-3"> Saturday </span>
        </label>
        <label
          for="sun"
          class="px-4 py-3 rounded-md inline-flex items-center mb-3"
          :class="item.openSun ? 'bg-green-200' : ''"
        >
          <input type="checkbox" id="sun" v-model="item.openSun" />
          <span class="ml-3"> Sunday </span>
        </label>
      </div>
    </div>

    <div class="flex-1 mt-14">
      <label
        class="block text-sm font-medium text-gray-700 text-xl mb-6 pb-2 border-gray-300"
        style="border-bottom-width: 1px"
        >Date Ranges<em class="text-red-700" v-if="!item.active"
          >(Closed for Delivery)</em
        >
      </label>
      <div class="mt-3 flex inline-flex items-center">
        <DatePicker
          v-model="date"
          color="green"
          :min-date="new Date()"
          :model-config="modelConfig"
        />
        <div class="ml-5">Start Date Selected: {{ this.date }}</div>
      </div>

      <br />

      <div class="mt-3 flex inline-flex items-center">
        <DatePicker
          v-model="eDate"
          color="green"
          :min-date="date ? date : new Date()"
          :model-config="modelConfig"
        />
        <div class="ml-5">End Date Selected: {{ this.eDate }}</div>
      </div>
    </div>

    <!-- <div class="flex-1 mt-14">
            <label class="block text-sm font-medium text-gray-700 text-xl mb-6 pb-2 border-gray-300" style="border-bottom-width:1px;">Automatic Promotions Opt-ins </label>
            <div class="mt-3">
                <div>
                    <label class="block mb-2 text-sm font-medium text-gray-700">$10 off Additional Bags ?</label>                        
                    <div>
                        <Toggle v-model="options.bagDiscount" activeText="Yes" inactiveText="No" />
                    </div>
                </div>
            </div>
        </div> -->

    <div class="flex-1 mt-14">
      <label
        class="block text-sm font-medium text-gray-700 text-xl mb-6 pb-2 border-gray-300"
        style="border-bottom-width: 1px"
        >Global Promo Codes</label
      >
      <div class="mt-3 flex flex-wrap space-x-3">
        <label
          :for="'w' + promo.id"
          class="px-4 py-3 rounded-md inline-flex items-center mb-3"
          :class="promo.optIn ? 'bg-green-200' : ''"
          v-for="promo in allPromotions"
          :key="promo.id"
        >
          <input type="checkbox" :id="'w' + promo.id" v-model="promo.optIn" />
          <span class="ml-3">
            {{ promo.code.toUpperCase() }}
            <small class="block text-sm text-gray-400">{{
              promo.valueLabel
            }}</small>
          </span>
        </label>
      </div>
    </div>
    <div class="flex-1 mt-14">
      <label
        class="block text-sm font-medium text-gray-700 text-xl mb-6 pb-2 border-gray-300"
        style="border-bottom-width: 1px"
        >Dealer Promo Codes</label
      >
      <div class="mt-3 flex flex-wrap space-x-3">
        <label
          :for="'w' + promo.id"
          class="px-4 py-3 rounded-md inline-flex items-center mb-3"
          :class="promo.optIn ? 'bg-green-200' : ''"
          v-for="promo in storePromotions"
          :key="promo.id"
        >
          <input type="checkbox" :id="'w' + promo.id" v-model="promo.optIn" />
          <span class="ml-3">
            {{ promo.code.toUpperCase() }}
            <small class="block text-sm text-gray-400">{{
              promo.valueLabel
            }}</small>
          </span>
        </label>
      </div>
    </div>

    <label
      class="block text-sm font-medium text-gray-700 text-xl mb-6 mt-10 border-gray-300"
      >Opt-in Products</label
    >
    <table class="table-auto w-full table-styled">
      <thead>
        <tr>
          <th class="bg-green-50">PRODUCT</th>
          <th class="bg-green-50">DEALER COST</th>
          <th class="bg-green-50">SELLING PRICE</th>
          <th class="bg-green-50">OPT-IN</th>
        </tr>
      </thead>
      <tbody class="td-centers">
        <tr
          v-for="prd in defaultProducts"
          :key="prd.id"
          :class="prd.optIn ? 'active-tr' : ''"
        >
          <td class="flex items-center">
            <div class="flex-1 px-4 flex items-center">
              <img
                :src="$url + prd.image.url"
                class="w-12 h-12 md:w-32 md:h-32 object-cover"
              />
              <div class="ml-4">
                <div
                  class="font-bold"
                  :class="prd.optIn ? 'text-green-600' : 'text-gray-600'"
                >
                  {{ prd.name }} <em v-if="prd.memo">({{ prd.memo }})</em>
                </div>
              </div>
            </div>
          </td>
          <td>
            {{ formatPrice(prd.cost) }}
          </td>
          <td>
            <div class="inline-flex items-center" style="gap: 1ch">
              $<FormInput
                v-model="prd.price"
                label=""
                style="width: 100px; margin: 0 auto"
                :disabled="!prd.optIn"
                @change="opted($event, prd)"
              />
            </div>
          </td>
          <td>
            <input type="checkbox" v-model="prd.optIn" />
          </td>
        </tr>
      </tbody>
    </table>
    <div class="mt-10">
      <Button
        :label="editMode ? 'Update Changes' : 'Create Dealer'"
        type="primary"
        @click="submit"
      />
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle";
import { formatPrice, validateEmail } from "@/assets/helper";
import FormInput from "@/components/Forms/FormInput";
import Toggle from "@/components/Forms/Toggle";
import FormSelect from "@/components/Forms/FormSelect";
import Button from "@/components/Button";
import Icons from "@/components/Icons";
import regions from "@/assets/resources/regions";

import { Calendar, DatePicker } from 'v-calendar';

export default {
  components: {
    FormInput,
    FormSelect,
    Button,
    Icons,
    PageTitle,
    Toggle,

    // Calendar,
    DatePicker
  },
  data: () => ({
    options: {},
    showErrors: false,
    original: {},
    allPromotions: [],
    storePromotions: [],
    item: {
      name: "",
      email: "",
      emailSecond: "",
      phone: "",
      city: "",
      region: "ON",
      country: "CA",
      code: "",
      address: "",
      openMon: false,
      openTue: false,
      openWed: false,
      openThu: false,
      openFri: false,
      openSat: false,
      openSun: false,
      taxpercentage: 0,
      pit: "",
      deliveryOffset: 2,
    },
    showErrors: false,
    optIns: [],
    products: [],
    areas: [],
    promotions: [],
    defaultProducts: [],
    deliveryOverrides: [],

    date: "",
    eDate: "",
    modelConfig: {
      type: "string",
      //mask: 'YYYY/MM/DD',
      mask: "MM/DD/YYYY",
    },
  }),
  computed: {
    backLink() {
      return this.editMode ? `/dealers/${this.$route.params.id}` : "/dealers";
    },
    editMode() {
      return Boolean(this.$route.params.id) && Boolean(this.original.id);
    },
    regionOptions() {
      return {
        CA: regions.CA,
        US: regions.US.map((d) => ({ label: d, value: d })),
      };
    },
  },
  watch: {
    // 'item.country'(val){
    //     this.item.region = val === 'CA' ? 'ON' : 'CA'
    // },
  },
  methods: {
    increaseDelivery() {
      this.item.deliveryOffset++;
    },
    decreaseDelivery() {
      this.item.deliveryOffset =
        this.item.deliveryOffset <= 1 ? 0 : this.item.deliveryOffset - 1;
    },
    validateEmail,
    isValidPostal: (postal) =>
      /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/.test(postal),
    isValidZip: (zip) => /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zip),
    isValid() {
      let valid = true;

      if (!this.item.name.trim() || !validateEmail(this.item.email))
        valid = false;

      return valid;
    },
    submit() {
      if (!this.isValid()) return (this.showErrors = true);

      let optedProducts = this.defaultProducts
        .filter((x) => x.optIn)
        .map((x) => ({
          cost: parseFloat(x.cost),
          price: parseFloat(x.price),
          product: x.id,
          store: this.original.id,
        }));

      let productsToAdd = this.products
        .filter((x) => !x.isDefault)
        .map((x) => x.id);
      productsToAdd = [
        ...productsToAdd,
        ...optedProducts.map((x) => x.product),
      ];

      let globalPromotions = this.allPromotions
        .filter((x) => x.optIn)
        .map((x) => x.id);
      let storePromotions = this.storePromotions
        .filter((x) => x.optIn)
        .map((x) => x.id);

      let startDate = this.date;

      let endDate = this.eDate;

      let sendObj = {
        ...this.item,
        optIns: optedProducts,
        products: productsToAdd,
        promotions: globalPromotions.concat(storePromotions),
        options: this.options,
        startDate,
        endDate,
      };
      // console.log(sendObj, "sendObj");

      if (this.editMode) {
        this.$api
          .put(`/stores/${this.original.id}`, sendObj)
          .then(({ data }) => {
            this.$store.commit("SET_MODAL", {
              callback: () => this.$router.push(`/dealers/${data.id}`),
              danger: false,
              confirm: false,
              header: "Success",
              body: `You have successfully updated this dealer ${data.name}`,
            });
          })
          .catch(() => {
            this.$store.commit("SET_MODAL", {
              danger: true,
              confirm: false,
              header: "Error",
              body: `An error Occurred while updating. Please try again`,
            });
          });
      } else {
        this.$api
          .post(`/stores`, sendObj)
          .then(({ data }) => {
            // console.log(data, "created store data");
            this.$store.commit("SET_MODAL", {
              callback: () => this.$router.push(`/dealers/${data.store.id}`),
              danger: false,
              confirm: false,
              header: "Success",
              body: `You have successfully created Dealer: ${data.store.name}`,
            });
          })
          .catch((err) => {
            // console.log("Response");
            this.$store.commit("SET_MODAL", {
              danger: true,
              confirm: false,
              header: "Uh oh,",
              body:
                `An error Occurred while updating. ` +
                err.response.data.message,
            });
          });
      }
    },
    opted(e, f) {
      // console.log("EEEEEEE", e);
      // console.log("FFFFFFFFF", f);
      // console.log("************");
    },
    formatPrice,
  },
  async created() {
    if (this.$route.params.id) {
      this.$store.commit("LOAD", {
        is: true,
        msg: "Retrieving Dealer Information",
      });

      let { data } = await this.$api.get(`/stores/${this.$route.params.id}`);
      // // console.log(data);
      if (data.id) {
        if (!data.options) data.options = { bagDiscount: false };

        let {
          products,
          optIns,
          areas,
          promotions,
          deliveryOverrides,
          options,
          ...dealer
        } = data;
        this.deliveryOverrides = deliveryOverrides;
        this.products = products;
        this.optIns = optIns;
        this.promotions = promotions;

        delete dealer.categories;
        delete dealer.created_by;
        delete dealer.updated_by;

        this.original = dealer;
        this.options = options;
        let {
          name,
          email,
          emailSecond,
          phone,
          city,
          region,
          country,
          code,
          address,
          openMon,
          openTue,
          openWed,
          openThu,
          openFri,
          openSat,
          openSun,
          taxpercentage,
          active,
          deliveryOffset,
          pit = "",
          hst = 0,
          gst = 0,
          qst = 0,
          pst = 0,
          startDate,
          endDate,
        } = dealer;
        if (typeof active !== "boolean") active = true;
        this.item = {
          name,
          email,
          emailSecond,
          phone,
          city,
          region,
          country,
          code,
          address,
          openMon,
          openTue,
          openWed,
          openThu,
          openFri,
          openSat,
          openSun,
          taxpercentage,
          active,
          deliveryOffset,
          pit,
          hst,
          pst,
          gst,
          qst,
          startDate,
          endDate,
        };

        if (new Date(this.item.startDate) < new Date()) {
          this.date = new Date().toLocaleString().split(",")[0];
        } else {
          this.date = this.item.startDate;
        }

        if (new Date(this.item.endDate) < new Date()) {
          this.eDate = new Date().toLocaleString().split(",")[0];
        } else {
          this.eDate = this.item.endDate;
        }
      }
    }

    let dealerProducts = this.products;
    let optIns = this.optIns;
    // // console.log(this.promotions,'promotionsssss')
    let promotions = this.promotions.map((x) => x.id);

    let { data: products } = await this.$api.get(`/products/?isDefault=1`);
    let { data: allPromotions } = await this.$api.get(`/promotions?_limit=-1`);

    allPromotions = allPromotions.map((x) => {
      x.optIn = promotions.includes(x.id);
      let { value, type } = x;
      let valueLabel = value + "%";
      if (type === "dollar") valueLabel = formatPrice(value);
      x.valueLabel = `${valueLabel}`;
      return x;
    });
    let storePromotions = this.promotions.filter((x) => !x.createdBySuper);
    storePromotions = storePromotions.map((x) => {
      x.optIn = promotions.includes(x.id);
      let { value, type } = x;
      let valueLabel = value + "%";
      if (type === "dollar") valueLabel = formatPrice(value);
      x.valueLabel = `${valueLabel}`;
      return x;
    });

    this.storePromotions = storePromotions;
    this.allPromotions = allPromotions;

    products = products.map((prd) => {
      let dPrd = dealerProducts.find((x) => x.id === prd.id);
      let optIn = optIns.find((x) => x.product === prd.id);
      if (!prd.cost) prd.cost = 0;
      if (dPrd?.id && optIn?.id) {
        prd.optIn = true;
        // prd.cost = optIn.cost
        prd.price = optIn.price;
      } else {
        prd.optIn = false;
      }

      prd.image = prd.image[0];
      if (!prd.image) prd.image = { url: "" };
      return prd;
    });

    this.defaultProducts = products;

    this.$store.commit("LOAD", { is: false, msg: "" });
  },
};
</script>
