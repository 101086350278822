<template>
  <table class="table-auto w-full table-styled">
    <thead>
      <tr>
        <th class="bg-green-50">Province Name</th>
        <th class="bg-green-50">
          <a
            href="#"
            class="flex justify-center items-center cursor-pointer sort-by relative"
            @click.prevent="sortBy('createdAt')"
          >
            <span class="mr-2">Country</span>
          </a>
        </th>
        <th class="bg-green-50">&nbsp;</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="province in provinces" :key="province.id">
        <td style="padding-left: 0.5rem">
          <router-link
            :to="`/cms/province/${province.id}?lang=${this.lang}`"
            class="text-green-600 hover:text-green-700"
            >{{ province.title }}</router-link
          >
        </td>
        <td>{{ province.Country }}</td>
        <td>
          <router-link
            :to="`/cms/province/${province.id}?lang=${this.lang}`"
            class="text-green-600 hover:text-green-700"
          >
            <Icons i="pen" class="w-4 h-4" />
          </router-link>
        </td>
      </tr>
    </tbody>
  </table>

  <div
    class="w-full flex flex-col items-center justify-center py-7 text-gray-300"
    v-if="!provinces.length"
  >
    <Icons i="sadface" class="w-16" />
    <p class="text-gray-500 mt-3">
      You have no Cities added for {{ langName }}
    </p>
  </div>
  <div class="flex justify-center mt-8">
    <Pagination
      :totalPages="maxPages"
      :perPage="limit"
      :currentPage="currentPage"
      @pagechanged="onPageChange"
    />
  </div>
  <div class="my-10 w-full text-center justify-end flex space-x-4">
    <Button
      label="New province"
      to="/cms/newProvince"
      type="primary"
      icon="plus"
      class="min-w-max"
    />
  </div>
</template>

<script>
import { formatDate } from "@/assets/helper";
import Icons from "@/components/Icons";
import Button from "@/components/Button";
import Pagination from "@/components/Pagination";
export default {
  components: {
    Icons,
    Button,
    Pagination,
  },
  props: {
    lang: {
      type: String,
      default: "en",
    },
  },
  data() {
    return {
      currentPage: 1,
      provinces: [],
      limit: 50,
      total: null,
    };
  },
  async created() {
    this.$store.commit("LOAD", { is: true });

    await this.updateProvinces();

    this.$store.commit("LOAD", { is: false });
  },
  methods: {
    formatDate,
    async onPageChange(page) {
      // console.log(page);
      this.currentPage = page;
      await this.updateProvinces();
    },
    async updateProvinces() {
      let { data } = await this.$api.get(
        `/provinces?locale=${this.langName}&page=${this.currentPage}`
      );
      this.provinces = data.provinces;
	  this.total = data.count
    },
  },
  computed: {
    langName() {
      return this.lang === "en" || this.lang === "fr" ? "Canada" : "US";
    },
    maxPages() {
      if (this.total === 0) return 0;
      if (this.limit > this.total) return 1;
      // console.log(this.total, "ttt");
      return Math.ceil(this.total / this.limit);
    },
  },
  watch: {
    async lang(newVal) {
      this.currentPage = 1;
      await this.updateProvinces();
    },
  },
};
</script>

<style></style>
