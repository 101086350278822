<template>
  <div>
    <PageTitle>
      <div class="flex justify-between">
        <div>New Order</div>
      </div>
    </PageTitle>

    <div
      class="steps-wrapper flex gap-5 px-4 container mx-auto mb-10 pb-3 pt-10 pointer-events-none"
    >
      <div class="step" :class="step === 0 ? 'active' : ''">
        <div class="step-label">Step 1</div>
        <span>Client Information</span>
      </div>
      <div class="step arrow" :class="step === 0 ? 'active text-gray-500' : ''">
        <svg
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          style="width: 50px"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 5l7 7-7 7"
          />
        </svg>
      </div>
      <div class="step" :class="step === 1 ? 'active' : ''">
        <div class="step-label">Step 2</div>
        <span>Choose Products</span>
      </div>
      <div class="step arrow" :class="step === 1 ? 'active text-gray-500' : ''">
        <svg
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          style="width: 50px"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 5l7 7-7 7"
          />
        </svg>
      </div>
      <div class="step" :class="step === 2 ? 'active' : ''">
        <div class="step-label">Step 3</div>
        <span>Review Order</span>
      </div>
    </div>

    <div class="container mx-auto px-4">
      <template v-if="step === 0">
        <template v-if="clientSearch">
          <div class="grid grid-cols-3 gap-6">
            <div class="col-span-3 sm:col-span-2">
              <h3 class="sub-heading text-green-600">Customer Lookup</h3>
              <p class="text-sm text-gray-500">
                Please enter the email to search for customer
              </p>
              <div class="mt-4 flex rounded-md shadow-sm">
                <input
                  v-model="clientEmail"
                  type="text"
                  name="company_website"
                  id="company_website"
                  class="flex-1 block w-full rounded-none rounded-l-md sm:text-sm border-gray-300"
                  :class="
                    showClientEmailError && !validateEmail(clientEmail)
                      ? 'border-red-100'
                      : ''
                  "
                  placeholder="john@domain.com"
                />
                <span
                  @click="search"
                  class="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-green-50 text-green-500 text-sm cursor-pointer hover:bg-green-200"
                  style="border-left: 0"
                >
                  Search
                </span>
              </div>
              <p class="text-red-800 text-sm mt-2" v-if="showClientEmailError">
                Please enter a valid Email
              </p>
              <p class="text-red-800 text-sm mt-2" v-else-if="noClientFound">
                Could not find a customer with that Email address. Please click
                Manual Entry to create a new one.
              </p>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="flex justify-end" v-if="dev">
            <button
              v-text="`Dummy Data`"
              @click="test"
              class="my-3 py-1 px-6 bg-blue-200 rounded-lg text-blue-800"
            />
          </div>
          <form style="max-width: 900px">
            <div class="grid grid-cols-6 gap-6">
              <FormInput
                :show-errors="showErrors"
                class="col-span-6 sm:col-span-3"
                label="First Name"
                v-model="client.firstName"
                type="text"
                maxlen="25"
                :required="true"
              />
              <FormInput
                :show-errors="showErrors"
                class="col-span-6 sm:col-span-3"
                label="Last Name"
                 maxlen="25"
                v-model="client.lastName"
                type="text"
                :required="true"
              />
              <FormInput
                :show-errors="showErrors"
                class="col-span-6 sm:col-span-3"
                label="Email"
                v-model="client.email"
                type="email"
                :required="true"
                :check="validateEmail"
                check-error="Not a valid Email"
                :disabled="Boolean(client.id.trim())"
              />
              <FormInput
                :show-errors="showErrors"
                class="col-span-6 sm:col-span-3"
                label="Confirm Email"
                v-model="client.cEmail"
                type="email"
                :required="true"
                :check="
                  (c) =>
                    c.toLowerCase().trim() === client.email.trim().toLowerCase()
                "
                check-error="Email does not match"
                :disabled="Boolean(client.id.trim())"
              />
              <FormInput
                :show-errors="showErrors"
                class="col-span-6 sm:col-span-3"
                label="Phone"
                v-model="client.phone"
                type="text"
                :required="true"
              />
            </div>

            <hr class="my-8" />

            <div class="grid grid-cols-6 gap-6">
              <FormInput
                :show-errors="showErrors"
                class="col-span-6 sm:col-span-3"
                label="Address"
                v-model="client.address"
                type="text"
                :required="true"
              />
              <FormInput
                :show-errors="showErrors"
                class="col-span-6 sm:col-span-3"
                label="City"
                v-model="client.city"
                type="text"
                :required="true"
              />
              <FormSelect
                :show-errors="showErrors"
                class="col-span-6 sm:col-span-3"
                label="Country"
                v-model="client.country"
                :options="[
                  { label: 'United States', value: 'US' },
                  { label: 'Canada', value: 'CA' },
                ]"
                :required="true"
              />
              <FormSelect
                :show-errors="showErrors"
                class="col-span-6 sm:col-span-3"
                :label="client.country === 'CA' ? 'Province' : 'State'"
                v-model="client.region"
                :options="regionOptions[client.country]"
                :required="true"
              />
              <FormInput
                :show-errors="showErrors"
                class="col-span-6 sm:col-span-3"
                :label="client.country === 'CA' ? 'Postal Code' : 'Zipcode'"
                v-model="client.code"
                type="text"
                :check="client.country === 'CA' ? isValidPostal : isValidZip"
                :check-error="
                  client.country === 'CA'
                    ? 'Postal code is invalid'
                    : 'Zip code is invalid'
                "
                :required="true"
              />
            </div>
          </form>
        </template>
      </template>

      <template v-else-if="step === 1">
        <h3>
          Client's {{ areaLabel }}:
          <span class="text-green uppercase">{{ client.code }}</span>
        </h3>
        <template v-if="loadingDealer">
          <p>Loading Dealer ....</p>
        </template>
        <template v-else-if="!dealer.name">
          <h2 class="text-red-800">
            Sorry could not find a Dealer for this {{ areaLabel }}
          </h2>
        </template>
        <template v-else>
          <h2>
            Dealer Name:
            <span class="text-green font-bold">{{ dealer.name }}</span>
          </h2>
          <h2
            v-if="bagDiscount"
            class="text-white bg-blue-500 inline-block px-2 py-1 rounded-md text-xs"
            :class="promotion ? 'line-through' : ''"
          >
            THIS DEALER HAS AUTOMATIC PROMOTION: $10 OFF ADDITIONAL BAGS
          </h2>

          <div class="flex mt-10">
            <div class="flex-1">
              <div class="">
                <div style="max-width: 300px">
                  <FormSelect
                    :show-errors="showErrors"
                    class="col-span-6 sm:col-span-3"
                    label="Choose Promotion Code"
                    v-model="promotion"
                    :options="promotions"
                  />
                </div>
                <p
                  v-if="
                    discountCode.minPurchase &&
                    discountCode.minError &&
                    showErrors
                  "
                  class="text-sm mt-2 text-red-700"
                >
                  This promotion requires a minimum purchase of
                  <span class="text-red-800 font-semibold">{{
                    formatPrice(discountCode.minPurchase)
                  }}</span>
                </p>
              </div>
              <div class="mt-5">
                <label class="block text-sm font-medium text-gray-700"
                  >Choose Delivery Date</label
                >

                <Datepicker
                  class="mt-1 ring-green-500 block shadow-sm sm:text-sm border-gray-300 rounded-md"
                  v-model="deliveryDate"
                  :disabledDates="disabledDates"
                  :lower-limit="dateLimit.from"
                  :upper-limit="dateLimit.to"
                />
                <p
                  class="text-red-800 text-sm mt-2"
                  v-if="showErrors && !deliveryDate"
                >
                  Please choose a valid delivery Date
                </p>
                <p
                  class="text-green-800 text-sm mt-2"
                  v-if="earliestDate && latestDate"
                >
                  <span class="block"
                    >Earliest Delivery:
                    {{ formatDate(earliestDate, "MMM/dd/yyyy") }}</span
                  >
                  <span class="block"
                    >Latest Delivery:
                    {{ formatDate(latestDate, "MMM/dd/yyyy") }}</span
                  >
                </p>
              </div>
              <div class="mt-5">
                <label class="block text-sm font-medium text-gray-700"
                  >Permission to use driveway ?</label
                >
                <div class="flex items-start mt-2">
                  <div class="flex items-center">
                    <input
                      v-model="paved"
                      id="copyShip"
                      name="copyShip"
                      type="checkbox"
                      class="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300 rounded"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label for="copyShip" class="font-medium text-gray-700">
                      <span class="text-green-700">{{
                        paved ? "Yes" : "No"
                      }}</span></label
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="flex-1 flex flex-col justify-end">
              <div class="flex flex-col justify-end">
                <div style="max-width: 300px">
                  <FormSelect
                    :show-errors="showErrors"
                    class="col-span-6 sm:col-span-3"
                    label="Choose Partner"
                    v-model="partner"
                    :options="partners"
                  />
                </div>
                <div class="mt-5" style="max-width: 300px">
                  <FormInput
                    :disabled="partner === ''"
                    v-model="orderID"
                    :show-errors="showErrors"
                    label="P.O. Number"
                    :check="(e) => checkOrderID"
                    check-error="P.O. # required for Partner orders"
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            class="product-list mt-10 border-green-500 rounded overflow-hidden divide-y divide-fuchsia-300"
            style="border-width: 1px"
          >
            <div
              class="cat-header grid grid-cols-6 gap-6 bg-green-100 product-table-row"
            >
              <div
                class="col-span-3 text-green-600 py-3 px-4 product-row-item font-bold"
              >
                PRODUCTS
              </div>
              <div
                class="col-span-2 text-green-600 py-3 px-4 product-row-item font-bold"
              >
                QUANTITY
              </div>
              <div
                class="col-span-1 text-green-600 py-3 px-4 product-row-item font-bold"
              >
                PRICE
              </div>
            </div>
            <div
              class="grid grid-cols-6 gap-6 product-table-row"
              v-for="prd in products"
              :key="prd.id"
            >
              <div
                class="col-span-3 py-3 px-4 product-row-item flex items-center"
              >
                <img
                  :src="$url + prd.image.url"
                  class="h-12 w-12 rounded-full object-cover"
                />
                <div class="ml-4">
                  <div class="text-green-600 font-bold">
                    {{ prd.name }}
                  </div>
                  <span class="text-xs text-gray-400">
                    {{ prd.description }}
                  </span>
                </div>
              </div>
              <div
                class="col-span-2 text-green-600 py-3 px-4 product-row-item font-bold flex items-center"
              >
                <!-- <FormInput v-model="prd.qty" type="number" :step="true" /> -->
                <div
                  class="flex flex-row h-10 w-full rounded-lg relative bg-transparent mt-1"
                >
                  <button
                    data-action="decrement"
                    class="bg-gray-100 text-gray-600 hover:text-gray-700 hover:bg-gray-200 h-full w-20 rounded-l cursor-pointer outline-none"
                    @click="decrease(prd)"
                  >
                    <span class="m-auto text-2xl font-thin">−</span>
                  </button>
                  <input
                    type="number"
                    class="border-none outline-none focus:outline-none text-center w-full bg-gray-50 font-semibold text-md hover:text-black focus:text-black md:text-basecursor-default flex items-center text-gray-700 outline-none"
                    name="custom-input-number"
                    v-model.number="prd.qty"
                  />
                  <button
                    data-action="increment"
                    class="bg-gray-100 text-gray-700 hover:text-gray-700 hover:bg-gray-200 h-full w-20 rounded-r cursor-pointer"
                    @click="increase(prd)"
                  >
                    <span class="m-auto text-2xl font-thin">+</span>
                  </button>
                </div>
              </div>
              <div
                class="col-span-1 text-green-600 py-3 px-4 product-row-item font-bold flex items-center"
              >
                {{ formatPrice(prd.price) }}
              </div>
            </div>
          </div>
          <div class="grid grid-cols-6 gap-6 mt-3">
            <div
              class="col-span-3 py-1 px-4 product-row-item flex items-center"
            >
              <span
                v-if="showErrors && orderedProducts.length === 0"
                class="text-red-800"
                >You have no products added</span
              >
            </div>
            <div
              class="col-span-2 text-gray-600 py-1 px-4 product-row-item flex items-center justify-end"
            >
              Products Total
            </div>
            <div
              class="col-span-1 text-green-600 py-1 px-4 product-row-item flex items-center"
            >
              {{ formatPrice(subTotal) }}
            </div>
          </div>
          <div class="grid grid-cols-6 gap-6">
            <div
              class="col-span-3 py-1 px-4 product-row-item flex items-center"
            ></div>
            <div
              class="col-span-2 text-gray-600 py-1 px-4 product-row-item flex items-center justify-end"
            >
              Discount
            </div>
            <div
              class="col-span-1 text-green-600 py-1 px-4 product-row-item flex items-center"
            >
              {{ formatPrice(discountedAmount) }}
            </div>
          </div>
          <div class="grid grid-cols-6 gap-6">
            <div
              class="col-span-3 py-1 px-4 product-row-item flex items-center"
            ></div>
            <div
              class="col-span-2 text-gray-600 py-1 px-4 product-row-item flex items-center justify-end"
            >
              Sub Total
            </div>
            <div
              class="col-span-1 text-green-600 py-1 px-4 product-row-item flex items-center"
            >
              {{ formatPrice(newSubTotal) }}
            </div>
          </div>
          <template v-for="item in taxRate" :key="item.label">
            <div class="grid grid-cols-6 gap-6" v-if="item.value">
              <div
                class="col-span-3 py-1 px-4 product-row-item flex items-center"
              ></div>
              <div
                class="col-span-2 text-gray-600 py-1 px-4 product-row-item flex items-center justify-end"
              >
                {{ item.label }}
              </div>
              <div
                class="col-span-1 text-green-600 py-1 px-4 product-row-item flex items-center"
              >
                {{ formatPrice(item.amount) }}
              </div>
            </div>
          </template>

          <div class="grid grid-cols-6 gap-6">
            <div
              class="col-span-3 py-3 px-4 product-row-item flex items-center"
            ></div>
            <div
              class="col-span-2 text-gray-600 py-3 px-4 product-row-item font-bold flex items-center justify-end"
            >
              Grand Total
            </div>
            <div
              class="col-span-1 text-green-600 py-3 px-4 product-row-item font-bold flex items-center"
            >
              {{ formatPrice(total) }}
            </div>
          </div>
        </template>
      </template>

      <template v-else-if="step === 2">
        <h3 class="sub-heading text-gray-600">Order Details</h3>

        <div
          class="product-list mt-8 border-gray-200 rounded overflow-hidden divide-y divide-fuchsia-300"
          style="border-width: 1px"
        >
          <div
            class="cat-header grid grid-cols-6 gap-6 bg-gray-100 product-table-row"
          >
            <div
              class="col-span-3 text-gray-600 py-3 px-4 product-row-item font-bold"
            >
              PRODUCTS
            </div>
            <div
              class="col-span-1 text-gray-600 py-3 px-4 product-row-item font-bold"
            >
              QUANTITY
            </div>
            <div
              class="col-span-2 text-gray-600 py-3 px-4 product-row-item font-bold"
            >
              PRICE
            </div>
          </div>
          <div
            class="grid grid-cols-6 gap-6 product-table-row"
            v-for="prd in orderedProducts"
            :key="prd.id"
          >
            <div
              class="col-span-3 py-3 px-4 product-row-item flex items-center"
            >
              <img
                :src="$url + prd.image.url"
                class="w-12 h-12 md:w-32 md:h-32 object-cover"
              />
              <div class="ml-4">
                <div class="text-green-600 font-bold">
                  {{ prd.name }}
                </div>
              </div>
            </div>
            <div
              class="col-span-1 text-green-600 py-3 px-4 product-row-item font-bold flex items-center justify-center"
            >
              <div class="text-gray-600 font-bold">
                {{ prd.qty }}
              </div>
            </div>
            <div
              class="col-span-2 text-gray-600 py-3 px-4 product-row-item font-bold flex items-center"
            >
              {{ formatPrice(prd.price) }}
            </div>
          </div>
        </div>
        <div class="grid grid-cols-6 gap-6 mt-3">
          <div class="col-span-3 py-1 px-4 flex"></div>
          <div
            class="col-span-1 text-gray-600 py-1 px-4 product-row-item flex items-center justify-end"
          >
            Products Total
          </div>
          <div
            class="col-span-2 text-green-600 py-1 px-4 product-row-item flex items-center"
          >
            {{ formatPrice(subTotal) }}
          </div>
        </div>
        <div class="grid grid-cols-6 gap-6">
          <div
            class="col-span-3 py-1 px-4 product-row-item flex items-center"
          ></div>
          <div
            class="col-span-1 text-gray-600 py-1 px-4 product-row-item flex items-center justify-end"
          >
            Discount
          </div>
          <div
            class="col-span-2 text-green-600 py-1 px-4 product-row-item flex items-center"
          >
            {{ formatPrice(discountedAmount) }}
          </div>
        </div>
        <div class="grid grid-cols-6 gap-6">
          <div
            class="col-span-3 py-1 px-4 product-row-item flex items-center"
          ></div>
          <div
            class="col-span-1 text-gray-600 py-1 px-4 product-row-item flex items-center justify-end"
          >
            Sub Total
          </div>
          <div
            class="col-span-2 text-green-600 py-1 px-4 product-row-item flex items-center"
          >
            {{ formatPrice(newSubTotal) }}
          </div>
        </div>
        <template v-for="item in taxRate" :key="item.label">
          <div class="grid grid-cols-6 gap-6" v-if="item.value">
            <div
              class="col-span-3 py-1 px-4 product-row-item flex items-center"
            ></div>
            <div
              class="col-span-1 text-gray-600 py-1 px-4 product-row-item flex items-center justify-end"
            >
              {{ item.label }}
            </div>
            <div
              class="col-span-2 text-green-600 py-1 px-4 product-row-item flex items-center"
            >
              {{ formatPrice(item.amount) }}
            </div>
          </div>
        </template>
        <div class="grid grid-cols-6 gap-6">
          <div
            class="col-span-3 py-3 px-4 product-row-item flex items-center"
          ></div>
          <div
            class="col-span-1 text-gray-600 py-3 px-4 product-row-item font-bold flex items-center justify-end"
          >
            Grand Total
          </div>
          <div
            class="col-span-2 text-green-600 py-3 px-4 product-row-item font-bold flex items-center"
          >
            {{ formatPrice(total) }}
          </div>
        </div>

        <h3 class="sub-heading text-gray-600 mt-10">
          Comments / Special Instructions
        </h3>
        <div style="max-width: 900px" class="mt-2">
          <textarea
            v-model="comments"
            class="resize-none border-gray-300 w-full shadow-sm sm:text-sm border-gray-300 rounded-md py-4"
            placeholder="Type in your comments/special instructions here..."
          ></textarea>
        </div>

        <template v-if="partner.length === 0">
          <h3 class="sub-heading text-gray-600 mt-10">Payment Details</h3>
          <div class="flex choose-payment mt-7" style="pointer-events: none">
            <label for="visa" :class="ccBrand === 'VISA' ? 'active' : ''"
              ><img src="@/assets/img/visa.png" class="w-12"
            /></label>
            <label for="mc" :class="ccBrand === 'MASTERCARD' ? 'active' : ''"
              ><img src="@/assets/img/mc.png" class="w-12 ml-4"
            /></label>
            <label for="amex" :class="ccBrand === 'AMEX' ? 'active' : ''"
              ><img src="@/assets/img/amex.png" class="w-12 ml-4"
            /></label>
          </div>
          <div class="grid grid-cols-6 gap-6 mt-4" style="max-width: 800px">
            <FormInput
              :show-errors="showErrors"
              class="col-span-5 sm:col-span-3"
              label="Cardholder Name"
              placeholder="Full Name"
              v-model="payment.chName"
              :required="true"
            />
            <FormInput
              :show-errors="showErrors"
              class="col-span-5 sm:col-span-3"
              label="Credit Card"
              placeholder="#### #### #### ####"
              :max="9999999999999999"
              :maxlen="16"
              v-model="payment.cc"
              :required="true"
              :check="checkCC"
            />
            <FormInput
              :show-errors="showErrors"
              class="col-span-3 sm:col-span-1"
              label="Expiry"
              placeholder="MM"
              :maxlen="2"
              v-model="payment.expMM"
              :max="12"
              :required="true"
              :check="(num) => checkDigits(num, 'mm')"
            />
            <FormInput
              :show-errors="showErrors"
              class="col-span-3 sm:col-span-1"
              label=" "
              placeholder="YY"
              :maxlen="2"
              :max="99"
              v-model="payment.expYY"
              :required="true"
              :check="checkDigits"
            />
            <FormInput
              :show-errors="showErrors"
              class="col-span-2 sm:col-span-1"
              label="CVV"
              placeholder="####"
              :max="99999"
              :maxlen="5"
              v-model="payment.cvv"
              :required="true"
              :check="checkDigits"
            />
          </div>
          <h3 class="sub-heading text-gray-600 mt-5 sm:mt-10">
            Billing Details
          </h3>

          <div class="flex items-start mt-5">
            <div class="flex items-center">
              <input
                v-model="billing.copyShipping"
                id="copyShip"
                name="copyShip"
                type="checkbox"
                class="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300 rounded"
              />
            </div>
            <div class="ml-3 text-sm">
              <label for="copyShip" class="font-medium text-gray-700"
                >Same as Shipping Address ?</label
              >
            </div>
          </div>
          <div
            class="grid grid-cols-6 gap-6 mt-5"
            :class="
              billing.copyShipping ? 'pointer-events-none opacity-30' : ''
            "
          >
            <FormInput
              :show-errors="showErrors && !billing.copyShipping"
              class="col-span-6 sm:col-span-3"
              label="First Name"
              v-model="billing.firstName"
              type="text"
              :required="true"
            />
            <FormInput
              :show-errors="showErrors && !billing.copyShipping"
              class="col-span-6 sm:col-span-3"
              label="Last Name"
              v-model="billing.lastName"
              type="text"
              :required="true"
            />
            <FormInput
              :show-errors="showErrors && !billing.copyShipping"
              class="col-span-6 sm:col-span-3"
              label="Phone Number"
              v-model="billing.phone"
              type="text"
              :required="true"
            />
            <FormInput
              :show-errors="showErrors && !billing.copyShipping"
              class="col-span-6 sm:col-span-3"
              label="Email"
              v-model="billing.email"
              type="email"
              :required="true"
            />

            <FormInput
              :show-errors="showErrors && !billing.copyShipping"
              class="col-span-6 sm:col-span-3"
              label="Address"
              v-model="billing.address"
              type="text"
              :required="true"
            />
            <FormInput
              :show-errors="showErrors && !billing.copyShipping"
              class="col-span-6 sm:col-span-3"
              label="City"
              v-model="billing.city"
              type="text"
              :required="true"
            />
            <FormSelect
              :show-errors="showErrors && !billing.copyShipping"
              class="col-span-6 sm:col-span-3"
              label="Country"
              v-model="billing.country"
              :options="[
                { label: 'United States', value: 'US' },
                { label: 'Canada', value: 'CA' },
              ]"
              :required="true"
            />
            <FormSelect
              :show-errors="showErrors && !billing.copyShipping"
              class="col-span-6 sm:col-span-3"
              :label="billing.country === 'CA' ? 'Province' : 'State'"
              v-model="billing.region"
              :options="regionOptions[billing.country]"
              :required="true"
            />
            <FormInput
              :show-errors="showErrors && !billing.copyShipping"
              class="col-span-6 sm:col-span-3"
              :label="billing.country === 'CA' ? 'Postal Code' : 'Zipcode'"
              v-model="billing.code"
              type="text"
              :check="billing.country === 'CA' ? isValidPostal : isValidZip"
              :check-error="
                billing.country === 'CA'
                  ? 'Postal code is invalid'
                  : 'Zip code is invalid'
              "
              :required="true"
            />
          </div>
        </template>

        <h3 class="sub-heading text-gray-600 mt-5 sm:mt-10">
          Shipping Details
        </h3>
        <div style="max-width: 900px">
          <div class="grid grid-cols-6 gap-6 mt-5">
            <div class="col-span-6 sm:col-span-3">
              <label class="text-gray-400">First Name</label>
              <div>{{ client.firstName }}</div>
            </div>
            <div class="col-span-6 sm:col-span-3">
              <label class="text-gray-400">Last Name</label>
              <div>{{ client.lastName }}</div>
            </div>
            <div class="col-span-6 sm:col-span-3">
              <label class="text-gray-400">Email</label>
              <div>{{ client.email }}</div>
            </div>
            <div class="col-span-6 sm:col-span-3">
              <label class="text-gray-400">Phone</label>
              <div>{{ client.phone || "N/A" }}</div>
            </div>
          </div>
          <hr class="my-4" />
          <div class="grid grid-cols-6 gap-6 mt-5">
            <div class="col-span-6 sm:col-span-3">
              <label class="text-gray-400">Address</label>
              <div>{{ client.address }}</div>
            </div>
            <div class="col-span-6 sm:col-span-3">
              <label class="text-gray-400">City</label>
              <div>{{ client.city }}</div>
            </div>
            <div class="col-span-6 sm:col-span-3">
              <label class="text-gray-400">Country</label>
              <div>{{ client.country }}</div>
            </div>
            <div class="col-span-6 sm:col-span-3">
              <label class="text-gray-400">{{
                billing.country === "CA" ? "Province" : "State"
              }}</label>
              <div>{{ client.region }}</div>
            </div>
            <div class="col-span-6 sm:col-span-3">
              <label class="text-gray-400">{{
                billing.country === "CA" ? "Postal Code" : "Zipcode"
              }}</label>
              <div>{{ client.code || "N/A" }}</div>
            </div>
          </div>
        </div>
      </template>

      <div class="flex justify-start my-10 space-x-3">
        <Button
          :disabled="disableButtons"
          to="/"
          label="Cancel"
          type="secondary"
          class="order-2 sm:order-1"
          v-if="step === 0 && clientSearch"
        />
        <Button
          :disabled="disableButtons"
          label="Back"
          type="secondary"
          class="order-2 sm:order-1"
          v-else
          @click="back"
        />
        <Button
          :disabled="disableButtons"
          label="Manual Entry"
          type="primary"
          class="sm:order-2"
          @click="manualEntry"
          v-if="clientSearch && step === 0"
        />
        <Button
          :disabled="disableButtons"
          :label="step === maxStep ? 'Create Order' : 'Next'"
          type="primary"
          class="sm:order-2 min-w-max"
          @click="next"
          v-else-if="!hideNext"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle";
import FormInput from "@/components/Forms/FormInput";
import Button from "@/components/Button";
import FormSelect from "@/components/Forms/FormSelect";
import Modal from "@/components/Modal";
import Icons from "@/components/Icons";
import regions from "@/assets/resources/regions";
import {
  validateEmail,
  cc_brand_id,
  formatPrice,
  formatDate,
} from "@/assets/helper";
import Datepicker from "vue3-datepicker";
import {
  add as addDate,
  sub as subDate,
  addDays,
  subDays,
  compareDesc,
} from "date-fns";
import isMonday from "date-fns/isMonday";
import isTuesday from "date-fns/isTuesday";
import isWednesday from "date-fns/isWednesday";
import isThursday from "date-fns/isThursday";
import isFriday from "date-fns/isFriday";
import isSaturday from "date-fns/isSaturday";
import isSunday from "date-fns/isSunday";

export default {
  name: "Home",
  components: {
    PageTitle,
    Icons,
    FormInput,
    FormSelect,
    Button,
    Datepicker,
    Modal,
  },
  computed: {
    checkOrderID() {
      if (!this.partner.trim()) return true;
      return Boolean(this.orderID.trim());
    },
    minDate() {
      return addDays(new Date(), 1);
    },
    earliestDate() {
      if (!this.deliveryDate) return "";
      let date = this.deliveryDate;
      let deliveryOffset = this.dealer.deliveryOffset;
      let earliest = subDays(date, deliveryOffset);
      let min = addDays(new Date(), 1);

      if (compareDesc(earliest, min) === 1) return min;
      return earliest;
    },
    latestDate() {
      if (!this.deliveryDate) return "";
      let date = this.deliveryDate;
      let deliveryOffset = this.dealer.deliveryOffset;
      return addDays(date, deliveryOffset);
    },
    discountedAmount() {
      if (this.discountCode.discAmount) return this.discountCode.discAmount;
      else if (this.bagDiscount) {
        let bags = 0;
        if (this.orderedProducts.length === 0) return 0;

        let skipProducts = 0;

        this.orderedProducts.forEach((prd) => {
          if (prd.vendor === "LessMess") {
            bags += prd.qty || 0;
          }

          if (
            prd.name === "Restocking Fee" ||
            prd.name === "Empty Less Mess Bag" ||
            prd.name === "Waste Pick-up"
          ) {
            skipProducts += prd.qty || 0;
          }
        });
        // console.log(skipProducts, "*x*");

        bags = bags - skipProducts;

        if (bags != 0) {
          return 20 * (bags - 1);
        }
      }
      return 0;
    },
    bagDiscount() {
      return this.dealer?.options?.bagDiscount;
    },
    sendOBJ() {
      if (this.step < 2) return {};
      let { cEmail, ...client } = this.client;
      // console.log(formatDate(this.earliestDate, 'MMM/dd/yyyy'), "testttttt");
      return {
        partner: this.partner,
        orderID: this.orderID,
        client,
        products: this.orderedProducts.map((x) => ({ id: x.id, qty: x.qty })),
        promotion: this.promotion,
        store: this.dealer.id,
        payment: this.payment,
        billing: this.billing,
        comments: this.comments,
        deliveryDate: this.deliveryDate.getTime(),
        taxed: this.taxed,
        subTotal: this.newSubTotal,
        total: this.total,
        paved: this.paved,
        earliest: formatDate(this.earliestDate, "MMM/dd/yyyy"),
        latest: formatDate(this.latestDate, "MMM/dd/yyyy"),
      };
    },
    ccBrand() {
      return cc_brand_id(this.payment.cc);
    },
    dateLimit() {
      let from = subDate(new Date(), { days: 0.5 });

      return {
        from,
        to: addDate(new Date(), { days: this.maxDays }),
      };
    },
    disabledDates() {
      let maxDays = this.maxDays;
      let dates = [];
      let { openFri, openMon, openSat, openSun, openThu, openTue, openWed } =
        this.dealer;

      for (let days = 0; days < maxDays; days++) {
        let today = addDate(new Date(), { days });

        if (isMonday(today) && !openMon) {
          dates.push(today);
        } else if (isTuesday(today) && !openTue) {
          dates.push(today);
        } else if (isWednesday(today) && !openWed) {
          dates.push(today);
        } else if (isThursday(today) && !openThu) {
          dates.push(today);
        } else if (isFriday(today) && !openFri) {
          dates.push(today);
        } else if (isSaturday(today) && !openSat) {
          dates.push(today);
        } else if (isSunday(today) && !openSun) {
          dates.push(today);
        }
      }

      return { dates };
    },
    datepickerSetting() {
      return {
        id: "birthday",
        name: "birthday",
        class: "myDateInput",
        value: "01/10/2020",
        placeholder: "Select",
        yearMinus: 0,
        fromDate: "10/02/2020",
        toDate: "10/02/2021",
        disabledDate: [
          "02/10/2020",
          "03/10/2020",
          "04/10/2020",
          "05/10/2020",
          "06/10/2020",
        ],
        locale: {
          format: "DD-MM-YYYY",
          weekday: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
          startsWeeks: 0,
          todayBtn: "Today",
          clearBtn: "Clear",
          closeBtn: "Close",
        },
        changeEvent: (value) => {
          // console.log(value + " selected!");
        },
        disableInput: false,
      };
    },
    total() {
      return parseFloat((this.newSubTotal + this.taxed).toFixed(2));
    },
    newSubTotal() {
      return parseFloat((this.subTotal - this.discountedAmount).toFixed(2));
    },
    taxed() {
      let taxed = 0;
      this.taxRate.forEach(({ value }) => {
        taxed += value;
      });
      let fraction = taxed / 100;
      let result = fraction * this.newSubTotal;
      return parseFloat(result.toFixed(2));
    },
    discountCode() {
      let fallback = { discAmount: 0 };
      let pr = this.dealer.promotions.find((x) => x.id === this.promotion);
      let qty = 0;
      this.orderedProducts.forEach((x) => {
        qty += x.qty;
      });
      if (pr && pr.id) {
        pr = JSON.parse(JSON.stringify(pr));
        if (this.subTotal <= pr.minPurchase) {
          pr.minError = true;
          pr.value = 0;
          pr.discAmount = 0;
        } else if (pr.type === "percentage") {
          let decimal = pr.value / 100;
          pr.discAmount = decimal * this.subTotal;
          // console.log("ITS A PERCENTAGE", pr.discAmount);
        } else if (pr.type === "dollar") {
          pr.discAmount = pr.value > this.subTotal ? this.subTotal : pr.value;
          pr.discAmount = pr.discAmount * qty;
          // console.log("ITS A DOLLAR", pr.discAmount);
        } else {
          return fallback;
        }

        return pr;
      }

      return fallback;
    },
    availablePromos() {
      let promos = this.dealer.promotions;

      // console.log("promos", promos);

      return promos.filter((promo) => {
        let { expiry = null } = promo;

        // console.log("promo", promo);
        // console.log("expiry", expiry);

        if (expiry == null) {
          //sj
          expiry = new Date();
          expiry.setDate(expiry.getDate() + 1000);
          expiry = expiry.getTime();
        } else expiry = new Date(expiry).getTime();
        let today = Date.now();

        // console.log("promo-->", promo);

        return promo.active && today < expiry;
      });
    },
    promotions() {
      return [
        { label: "None", value: "" },
        ...this.availablePromos.map((pr) => ({
          label: `${pr.code} - ${pr.name} `,
          value: pr.id,
        })),
      ];
    },
    subTotal() {
      let subTotal = 0;
      this.orderedProducts.forEach((prd) => {
        subTotal += prd.price * prd.qty;
      });
      return parseFloat(subTotal.toFixed(2));
    },
    orderedProducts() {
      let arr = this.products.map((prd) => {
        return (prd.qty = parseInt(prd.qty)), prd;
      });
      // console.log("ARRRRR", arr);
      return arr.filter((prd) => !isNaN(prd.qty) && prd.qty > 0);
    },
    taxRate() {
      let newSubTotal = this.newSubTotal;

      let {
        hst = 0,
        gst = 0,
        qst = 0,
        pst = 0,
        taxpercentage = 0,
      } = this.dealer;
      let taxed = [];

      let getAmount = (tx) => {
        tx = parseFloat(tx);
        if (isNaN(tx)) return 0;
        let fraction = tx / 100;
        return parseFloat((newSubTotal * fraction).toFixed(2));
      };

      if (hst)
        taxed.push({
          label: `HST (${hst}%)`,
          value: hst,
          amount: getAmount(hst),
        });
      if (qst)
        taxed.push({
          label: `QST (${qst}%)`,
          value: qst,
          amount: getAmount(qst),
        });
      if (gst)
        taxed.push({
          label: `GST (${gst}%)`,
          value: gst,
          amount: getAmount(gst),
        });
      if (pst)
        taxed.push({
          label: `PST (${pst}%)`,
          value: pst,
          amount: getAmount(pst),
        });
      if (taxpercentage)
        taxed.push({
          name: `TAX (${taxpercentage}%)`,
          value: taxpercentage,
          amount: getAmount(taxpercentage),
        });

      return taxed;
    },
    areaLabel() {
      return this.client.country === "CA" ? "Postal Code" : "Zipcode";
    },
    regionOptions() {
      return {
        CA: regions.CA,
        US: regions.US.map((d) => ({ label: d, value: d })),
      };
    },
  },
  methods: {
    formatDate,
    manualEntry() {
      this.client = {
        id: "",
        firstName: "",
        lastName: "",
        email: "",
        cEmail: "",
        phone: "",

        address: "",
        city: "",
        code: "",
        region: "ON",
        country: "CA",
      };
      this.clientSearch = false;
    },
    refreshFoundUser(user) {
      let {
        id = "",
        address = "",
        city = "",
        country = "",
        email = "",
        firstName = "",
        lastName = "",
        phone = "",
        region = "",
      } = user;

      email = email.toLowerCase();

      this.client = {
        ...this.client,
        cEmail: email,
        address,
        city,
        country,
        email,
        firstName,
        lastName,
        phone,
        region,
        id,
      };
    },
    search() {
      this.showClientEmailError = false;
      this.noClientFound = false;
      let email = this.clientEmail;

      if (!validateEmail(email)) return (this.showClientEmailError = true);
      this.$store.commit("LOAD", { is: true });
      this.$api
        .get(`/orders/client?email=${email}`)
        .then(({ data }) => {
          if (!data.id) {
            return (this.noClientFound = true);
          } else {
            // console.log("FOUNDDDDDDD", data);

            this.refreshFoundUser(data);
            this.clientSearch = false;
          }
        })
        .catch((err) => {
          this.noClientFound = true;
        })
        .finally(() => {
          this.$store.commit("LOAD", { is: false });
        });
    },
    callback() {
      // console.log("cALLED BACK");
    },

    finalize() {
      this.$store.commit("LOAD", { is: true });
      this.$api
        .post("/orders", this.sendOBJ)
        .then(({ data }) => {
          this.$store.commit("SET_MODAL", {
            callback: () => this.$router.push("/"),
            danger: false,
            okText: "Okay",
            confirm: false,
            header: "Great!",
            body: `The order (#${data.orderId}) successfully went through.`,
          });
        })
        .catch((err) => {
          let body =
            "There was a problem creating your order. Please try again.";
          if (err?.response?.data?.message)
            body = err.response.data.message + ". Please try again.";
          this.$store.commit("SET_MODAL", {
            callback: this.callback,
            danger: true,
            okText: "Okay",
            confirm: false,
            header: "Uh oh",
            body,
          });
          // console.log("err.", err.response);
        })
        .finally(() => {
          this.$store.commit("LOAD", { is: false });
        });
    },
    checkCC(val) {
      if (val.length < 14) return false;
      return !isNaN(parseFloat(val.trim())) && this.ccBrand;
    },
    checkDigits(val, type) {
      let num = parseFloat(val.trim());
      if (isNaN(num)) return false;
      else if (type === "mm") return num < 13 && num > 0;
      return true;
    },
    formatCC(value) {
      var v = value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
      var matches = v.match(/\d{4,16}/g);
      var match = (matches && matches[0]) || "";
      var parts = [];

      for (i = 0, len = match.length; i < len; i += 4) {
        parts.push(match.substring(i, i + 4));
      }

      if (parts.length) {
        return parts.join(" ");
      } else {
        return value;
      }
    },
    applyCC(e) {
      // console.log("VALUE", e.target.value);
      // console.log("VALUE", this.payment.cc);
      this.payment.cc += "x";
    },
    increase(prd) {
      prd = this.products.find((x) => x.id === prd.id);
      prd.qty++;
    },
    decrease(prd) {
      let { qty } = prd;
      prd.qty = qty <= 1 ? 0 : qty - 1;
    },
    formatPrice,
    back() {
      if (this.step === 0) return this.$router.push("/");
      else this.step--;
    },
    validateEmail,
    isValidPostal: (postal) =>
      /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/.test(postal),
    isValidZip: (zip) => /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zip),
    test(v) {
      this.client = {
        id: "",
        firstName: "Praj",
        lastName: "Tester",
        email: "prajeevan@redngen.com",
        cEmail: "prajeevan@redngen.com",
        phone: "6473005048",

        address: "a4sd56",
        city: "asd",
        code: "Y1A9D9",
        region: "ON",
        country: "CA",
      };
    },
    async isStep2Valid() {
      let valid = true;
      if (this.partner.length === 0) {
        if (this.payment.cc.length < 14 || !this.ccBrand) valid = false;
        else if (!this.billing.copyShipping) {
          Object.entries(this.billing).forEach(([key, value]) => {
            if (!valid) return;
            else if (key === "copyShipping") return;
            else if (key === "code") {
              valid =
                this.client.country === "CA"
                  ? this.isValidPostal(value)
                  : this.isValidZip(value);
            } else {
              // console.log("KEYYY CHECK", value);
              valid = Boolean(value.trim());
            }
          });
        } else if (this.discountCode.minPurchase && this.discountCode.minError)
          valid = false;
      }

      return valid;
    },
    async isStep1Valid() {
      let valid = true;

      if (this.partner.length && this.orderID.trim().length === 0)
        valid = false;
      if (this.orderedProducts.length === 0) valid = false;
      else if (!this.deliveryDate) valid = false;
      else if (this.discountCode.minPurchase && this.discountCode.minError)
        valid = false;

      return valid;
    },
    async isStep0Valid() {
      let valid = true;
      // console.log("CHECKING CLIENT", this.client);
      Object.entries(this.client).forEach(([key, value]) => {
        if (!valid) return;
        else if (key === "id") return;
        else if (key === "code") {
          valid =
            this.client.country === "CA"
              ? this.isValidPostal(value)
              : this.isValidZip(value);
        } else if (key === "email") {
          valid = validateEmail(value);
        } else if (key === "cEmail") {
          valid =
            value.trim().toLowerCase() ===
            this.client.email.toLowerCase().trim();
        } else valid = Boolean(value.trim());
      });
      if (!valid) return false;
      if (this.client.id.trim()) return true;
      return true;
      let { data: found } = await this.$api.get(
        `/orders/client?email=${this.client.email}`
      );

      if (found.id) {
        this.$store.commit("SET_MODAL", {
          callback: () => this.refreshFoundUser(found),
          danger: false,
          okText: "Update user",
          confirm: true,
          header: "User already Exists",
          body: `A user with the email ${this.client.email.toLowerCase()} already exists. Would you like to pull information from the database and continue? or you can cancel to try another email.`,
        });
        return false;
      } else return true;
    },
    gotoNextStep() {
      // console.log("GOING TO NEXT STEP");
      if (this.step === this.maxStep) return this.finalize();
      this.step++;
    },
    async next() {
      // console.log("GOING NEXT", this[`isStep${this.step}Valid`]);
      if (
        this[`isStep${this.step}Valid`] &&
        (await this[`isStep${this.step}Valid`]())
      ) {
        // console.log("VALID", this[`isStep${this.step}Valid`]());
        return this.gotoNextStep();
      }
      return (this.showErrors = true);
    },
  },
  watch: {
    partner(val) {
      this.orderID = "";
    },
    clientSearch(val) {
      if (!val) {
        this.clientEmail = "";
        this.showErrors = false;
      }
    },
    step(val, oldVal) {
      window.scrollTo(0, 0);
      this.loadingDealer = false;
      this.disableButtons = false;
      this.hideNext = false;
      this.showErrors = false;

      if (oldVal === 1 && val === 0) {
      } else if (oldVal === 0 && val === 1) {
        this.loadingDealer = true;
        this.disableButtons = true;
        let code = this.client.code.trim();
        if (this.client.country === "CA") {
          code = code.substr(0, 3);
        }

        this.$api
          .get(`/stores/code/${code}`)
          .then(({ data }) => {
            let { products, optIns, ...store } = data;
            let maxDescLength = 180;
            this.products = products.map((prd) => {
              if (prd.description.length > maxDescLength)
                prd.description =
                  prd.description.substr(0, maxDescLength) + "...";
              if (prd.image && prd.image.length) prd.image = prd.image[0];
              let foundOpt = optIns.find((x) => x.product === prd.id);
              if (foundOpt?.price) prd.price = foundOpt.price;
              prd.qty = 0;
              return prd;
            });
            this.dealer = store;
          })
          .catch(() => {
            this.products = [];
            this.dealer = {};
            this.hideNext = true;
          })
          .finally(() => {
            this.loadingDealer = false;
            this.disableButtons = false;
          });
      }
    },
    "client.country"(val) {
      this.client.region = val === "CA" ? "ON" : "CA";
    },
    "billing.country"(val) {
      this.billing.region = val === "CA" ? "ON" : "CA";
    },
  },
  data: () => ({
    partners: [],
    partner: "",
    orderID: "",
    paved: false,
    dev: false,
    phase: 1,
    noClientFound: false,
    showClientEmailError: false,
    clientEmail: "",
    clientSearch: false,
    maxStep: 2,
    comments: "",
    showModal: false,
    payment: {
      cc: "",
      cvv: "",
      exp: "",
    },
    hideNext: false,
    disableButtons: false,
    loadingDealer: false,
    deliveryDate: null,
    init: {
      step0: false,
      step1: false,
      step2: false,
    },
    regions,
    step: 0,
    showErrors: false,
    dealer: {},
    products: [],
    promotion: "",
    billing: {
      copyShipping: false,
      address: "",
      city: "",
      code: "",
      region: "ON",
      country: "CA",

      firstName: "",
      lastName: "",
      email: "",
      phone: "",
    },
    client: {
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      cEmail: "",
      phone: "",

      address: "",
      city: "",
      code: "",
      region: "ON",
      country: "CA",
    },
    maxDays: 730,
  }),
  created() {
    window.cc = cc_brand_id;
    if (localStorage.dev) this.dev = 1;
    this.$api.get("/partners").then(({ data }) => {
      data = data.map(({ code, name }) => ({ label: name, value: code }));
      this.partners = [{ label: "None", value: "" }, ...data];
    });
  },
};
</script>

<style lang="scss">
.choose-payment > label:not(.active) {
  filter: grayscale(80);
  opacity: 0.3;
}
.home-page-grid {
  margin-top: 3vw;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5vw;
  @media screen and (min-width: 968px) {
    grid-template-columns: repeat(4, 1fr);
  }
}
.step:not(.active) {
  color: rgb(229, 231, 235);
}
.dash-item {
  cursor: pointer;
  &:before {
    content: "";
  }
  border: 1px solid var(--green);
  min-height: 200px;
  font-weight: bold;
  svg {
    margin: 0 auto 5px;
    width: 40%;
  }
  &:hover {
    background: #fffbdb;
  }
}
.product-table-row {
  > .product-row-item:not(:first-child) {
    border-left: 1px solid #eee;
  }
}
</style>
