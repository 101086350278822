import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {$api,baseURL} from './assets/helper'
import Maska from 'maska'





import '@/assets/styles/tailwind.css';
import '@/assets/styles/components.css';
import '@/assets/styles/custom.css';

const app = createApp(App)
app.config.globalProperties.$api = $api;
app.config.globalProperties.$url = baseURL;
app.config.globalProperties.$modal = e => store.commit('SET_MODAL',e);

window.$api = $api
app.use(Maska)
// app.directive('maska', Maska.maska);
app.use(store)
.use(router)

app.directive("click-outside", {
    beforeMount: function (el, binding) {
        // Define ourClickEventHandler
        const ourClickEventHandler = event => {
            if (!el.contains(event.target) && el !== event.target) {
                // as we are attaching an click event listern to the document (below)
                // ensure the events target is outside the element or a child of it
                binding.value(event); // before binding it
            }
        };
        // attached the handler to the element so we can remove it later easily
        el.__vueClickEventHandler__ = ourClickEventHandler;

        // attaching ourClickEventHandler to a listener on the document here
        document.addEventListener("click", ourClickEventHandler);
    },
    unmounted: function (el) {
        // Remove Event Listener
        document.removeEventListener("click", el.__vueClickEventHandler__);
    }
})

// window.addEventListener('resize', e => {

// })

  
app.mount('#app')