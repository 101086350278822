<template>
  <PageTitle>
    <div class="flex justify-between items-baseline px-4">
      <div>
        <router-link
          to="/orders"
          class="text-blue-500 inline-flex cursor-pointer hover:text-blue-800"
        >
          <Icons i="arrow-left" class="w-4 h-4 hover:round" />
        </router-link>
        Order Card
        <span class="text-red-800" v-if="!order.mergedToNav && isSuper"
          >(not merged to NAV)</span
        >
      </div>
      <div class="print-hide print-font-12 space-x-4 flex">
        <Button
          class="min-w-max"
          label="Merge To Nav"
          size="small"
          v-if="!order.mergedToNav && isSuper"
          @click="mergeNav"
          type="primary"
        />
        <Button class="min-w-max" label="Print" size="small" @click="print" />
      </div>
    </div>
  </PageTitle>

  <div
    class="grid grid-cols-12 gap-6 mt-3 mb-5 container mx-auto mt-12 px-4"
    v-if="order?.id"
  >
    <div class="col-span-12 sm:col-span-3 order-card-sm">
      <ul class="text-gray-500 text-sm space-y-2">
        <li class="sm:pr-9 sm:mb-2 print-hide">
          <div class="sm:mb-8">
            <FormSelect
              label="Change Status"
              v-model="newStatus"
              :options="statusOptions"
              :required="true"
              selectClass="bg-gray-300 text-gray-700"
            />
          </div>
        </li>

        <li class="mt-3">
          Order ID: <b>{{ order.orderId }}</b>
        </li>
        <li>
          Transaction ID: <b>{{ order?.paymentResult?.id }}</b>
        </li>
        <li>
          Order Date: <b>{{ formatDate(order?.createdAt) }}</b>
        </li>
        <li>
          Order Status:
          <b :class="statusColor">{{ orderStatuses[order.status] }}</b>
        </li>
        <li v-if="order.updatedBy?.email">
          Last updated by:
          <b>
            {{ order.updatedBy?.email }}
            <!-- {{ order.updatedBy.firstName }}
            {{ order.updatedBy.lastName[0] }}. -->
          </b>
        </li>
        <li v-if="order.createdBy && order.createdBy?.firstName !== 'Ngen'">
          Created By:
          <b
            >{{ order.createdBy.username }}
            <!-- {{ order.createdBy.lastName[0] }}. -->
          </b>
        </li>
        <li v-else-if="order.createdBy?.firstName == 'Ngen'">
          Created By: <b>EDI</b>
        </li>
        <li v-else>Created By: <b> Online </b></li>
        <li v-if="order.store?.name && (isSuper || isCSR)">
          Dealer Name:
          <b
            >{{ order.store.name }}
            <!-- {{ order.createdBy.lastName[0] }}. -->
          </b>
        </li>

        <!-- <li>Updated By Email: {{ order?.updatedBy?.email }}</li> -->
      </ul>
      <h3 class="text-lg text-green-600 mt-6 sm:mt-10">Shipping Details</h3>
      <ul class="text-gray-500 text-sm space-y-2">
        <li class="mt-3">
          Name: <b>{{ customer.firstName }} {{ customer.lastName }}</b>
        </li>
        <li class="mt-3">
          Email: <b>{{ customer.email }}</b>
        </li>
        <li class="mt-3">
          Phone: <b>{{ customer.phone }}</b>
        </li>
        <li class="mt-3">
          Address: <b>{{ customer.address }}</b>
        </li>
        <li class="mt-3">
          City: <b>{{ customer.city }}</b>
        </li>
        <li class="mt-3">
          Province/State: <b>{{ customer.regionLabel }}</b>
        </li>
        <li class="mt-3">
          Country: <b>{{ customer.countryLabel }}</b>
        </li>
        <li class="mt-3">
          {{ customer.country === "CA" ? "Postal Code" : "Zipcode" }}:
          <b>{{ customer.code }}</b>
        </li>
      </ul>
    </div>

    <div class="col-span-12 order-card-lg">
      <div
        class="product-list mt-8 border-gray-200 rounded overflow-hidden divide-y divide-fuchsia-300"
        style="border-width: 1px"
      >
        <div
          class="cat-header grid grid-cols-6 gap-6 bg-gray-100 product-table-row"
        >
          <div
            class="col-span-3 text-gray-600 py-3 px-4 product-row-item font-bold"
          >
            PRODUCTS
          </div>
          <div
            class="col-span-1 text-gray-600 py-3 px-4 product-row-item font-bold"
          >
            <span class="hidden sm:block">QUANTITY</span
            ><span class="sm:hidden">QTY</span>
          </div>
          <div
            class="col-span-2 text-gray-600 py-3 px-4 product-row-item font-bold"
          >
            PRICE
          </div>
        </div>
        <div
          class="grid grid-cols-6 gap-6 product-table-row"
          v-for="prd in products"
          :key="prd.id"
        >
          <div class="col-span-3 py-3 px-4 product-row-item flex items-center">
            <img
              :src="$url + prd.image.url"
              class="w-12 h-12 md:w-32 md:h-32 object-cover"
            />
            <div class="ml-4">
              <div class="text-gray-600 font-bold">
                {{ prd.name }}
              </div>
            </div>
          </div>
          <div
            class="col-span-1 text-green-600 py-3 px-4 product-row-item font-bold flex items-center justify-center"
          >
            <div class="text-gray-600 font-bold">
              {{ prd.qty }}
            </div>
          </div>
          <div
            class="col-span-2 text-gray-600 py-3 px-4 product-row-item font-bold flex items-center"
          >
            {{ formatPrice(prd.price) }}
          </div>
        </div>
      </div>

      <div class="flex sm:grid grid-cols-6 gap-6 mt-8">
        <div
          class="col-span-3 sm:py-1 sm:px-4 product-row-item flex items-center print-hide"
        ></div>
        <div
          class="flex-1 col-span-1 sm:col-span-4 text-gray-600 py-1 sm:px-4 product-row-item flex items-center justify-end"
        >
          Sub Total
        </div>
        <div
          class="flex-1 col-span-2 sm:col-span-2 text-gray-600 py-1 sm:px-4 product-row-item flex items-center"
        >
          {{ formatPrice(order.subTotal) }}
        </div>
      </div>
      <div class="flex sm:grid grid-cols-6 gap-6">
        <div
          class="col-span-3 py-1 sm:px-4 product-row-item flex items-center print-hide"
        ></div>
        <div
          class="flex-1 col-span-1 text-gray-600 py-1 sm:px-4 product-row-item flex items-center justify-end"
        >
          Discount
        </div>
        <div
          class="flex-1 col-span-2 text-gray-600 py-1 sm:px-4 product-row-item flex items-center"
        >
          <!-- {{ formatPrice(order.discount) }} -->
          {{
            typeof order.discount == "number"
              ? formatPrice(order.discount)
              : "N/A"
          }}
        </div>
      </div>
      <div class="flex sm:grid grid-cols-6 gap-6">
        <div
          class="col-span-3 py-1 sm:px-4 product-row-item flex items-center print-hide"
        ></div>
        <div
          class="flex-1 sm:col-span-1 text-gray-600 py-1 sm:px-4 product-row-item flex items-center justify-end"
        >
          Tax
        </div>
        <div
          class="flex-1 sm:col-span-2 text-gray-600 py-1 sm:px-4 product-row-item flex items-center"
        >
          {{ formatPrice(order.tax) }}
        </div>
      </div>

      <div class="flex sm:grid grid-cols-6 gap-6">
        <div
          class="sm:col-span-3 py-3 sm:px-4 product-row-item flex items-center print-hide"
        ></div>
        <div
          class="flex-1 sm:col-span-1 text-gray-600 py-3 sm:px-4 product-row-item font-bold flex items-center justify-end"
          :class="order.status === 'refund' ? 'line-through' : ''"
        >
          Grand Total
        </div>
        <div
          class="flex-1 sm:col-span-2 text-gray-600 py-3 sm:px-4 product-row-item font-bold flex items-center"
          :class="order.status === 'refund' ? 'line-through' : ''"
        >
          {{ formatPrice(order.total) }}
        </div>
      </div>
      <div
        class="flex sm:grid grid-cols-6 gap-6"
        v-if="order.status === 'refund'"
      >
        <div
          class="sm:col-span-3 sm:px-4 product-row-item flex items-center print-hide"
        ></div>
        <div
          class="flex-1 sm:col-span-1 sm:px-4 product-row-item font-bold flex items-center justify-end text-red-800"
        >
          Refunded
        </div>
        <div
          class="flex-1 sm:col-span-2 sm:px-4 product-row-item font-bold flex items-center text-red-800"
        >
          {{ formatPrice(order.total) }}
        </div>
      </div>
      <hr class="my-5" />
      <div class="mt-8 space-y-3">
        <div class="mt-5">
          <p v-if="!pitChange">
            <span class="mr-2">Pit# </span>
            <span v-if="!pitChange" class="text-lg text-green-700 font-bold">
              {{ order.pit || "None provided" }}
              <a
                href="#"
                @click.prevent="changePit"
                class="text-blue-800"
                v-if="isSuper"
                >(change)</a
              >
            </span>
          </p>
          <template v-else>
            <div class="flex flex-wrap items-stretch w-full mb-4 relative">
              <input
                placeholder="Pit # here"
                v-model="pit"
                type="text"
                class="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border h-10 border-gray-300 px-3 relative"
              />
              <div class="flex -mr-px">
                <span
                  class="flex items-center leading-normal bg-grey-lighter rounded rounded-l-none border border-l-0 border-grey-light px-3 whitespace-no-wrap text-white text-sm bg-green-600 cursor-pointer hover:bg-green-800"
                  @click="updatePit"
                >
                  <Icons i="check" class="w-5 h-5" />
                </span>
              </div>
              <div class="flex -mr-px">
                <span
                  class="flex items-center leading-normal bg-grey-lighter rounded rounded-l-none border border-l-0 border-grey-light px-3 whitespace-no-wrap text-white bg-red-600 text-sm cursor-pointer hover:bg-red-800"
                  @click="resetPit"
                >
                  <Icons i="close" class="w-5 h-5" />
                </span>
              </div>
            </div>
          </template>
        </div>
        <div class="mt-5" v-if="order.partner?.name">
          Partner: <b class="text-purple-400">{{ order.partner?.name }}</b>
        </div>
        <p>
          Delivery Date:
          <span class="text-lg text-green-700 font-bold">{{
            formatDate(order.deliveryDate, "EEEE MMMM dd, yyyy") || "N/A"
          }}</span>
        </p>
        <p v-if="order.snapshot?.earliest && order.snapshot?.latest">
          Estimated Delivery Date Range:
          <span class="text-lg text-green-700 font-bold">
            <!-- {{ formatDate(order.deliveryDate, "EEEE MMMM dd, yyyy") }} -->
            <!-- {{ formatDate(earlyDate, "EEEE MMMM dd, yyyy") }} to
            {{ formatDate(latestDate, "EEEE MMMM dd, yyyy") }} -->
            {{ order.snapshot?.earliest || "" }} to
            {{ order.snapshot?.latest || "" }}
          </span>
          <br />
        </p>
        <p>
          Permission to use driveway:
          <span class="text-lg text-green-700 font-bold">{{
            order.paved ? "Yes" : "No"
          }}</span>
        </p>
        <p>
          Comments:
          <span class="text-lg text-green-700 font-bold">{{
            order.comments ? order.comments : "N/A"
          }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle";
import { formatPrice, formatDate, getRegion } from "@/assets/helper";
import { addDays, subDays, compareDesc } from "date-fns";
import Datepicker from "vue3-datepicker";
import FormSelect from "@/components/Forms/FormSelect";
import FormInput from "@/components/Forms/FormInput";
import Button from "@/components/Button";
import Icons from "@/components/Icons";

export default {
  components: {
    PageTitle,
    FormSelect,
    Button,
    FormInput,
    Icons,
  },
  watch: {
    // status(val, oldVal) {
    //   console.log(this.status);
    //   this.$forceUpdate();
    // },
    justUpdated_Email(val, oldVal) {
      if (val !== oldVal) {
        this.$forceUpdate();
      }
    },
  },
  computed: {
    // earliestDate(dates) {
    //   let oldDate = new Date(dates);
    //   let newDate = oldDate.setDate(oldDate.getDate + 2);
    //   return newDate;
    // },
    // latestDate() {
    //   let date = order.deliveryDate + 2 + this.deliveryOffset;
    //   return date;
    // },
    products() {
      return this.order.orderItems.map((item) => {
        let { product } = item;
        product.qty = item.quantity;
        product.image = product.image[0];
        product.price = item.price;
        return product;
      });
    },
    customer() {
      let customer = {};
      if (!this.order) return {};
      else if (this.order?.customer?.id) customer = this.order.customer;
      else if (this.order?.user?.id) customer = this.order.user;
      customer.regionLabel = customer.region;
      customer.countryLabel = customer.country;

      if (customer.region?.length < 4) {
        customer.regionLabel = getRegion(customer.country, customer.region);
      }
      if (customer.country?.length < 4) {
        customer.countryLabel =
          customer.country === "CA" ? "Canada" : "United States";
      }
      return customer;
    },
    newStatus: {
      get() {
        return this.order.status;
      },
      set(val) {
        this.changeStatus(val);
      },
    },
    statusOptions() {
      return Object.entries(this.orderStatuses).map(([key, value]) => ({
        label: value,
        value: key,
      }));
    },
    orderStatuses() {
      return {
        pending: "Pending",
        assigned: "Assigned",
        completed: "Completed",
        cancelled: "Cancelled",
        refund: "Refunded",
      };
    },
    statusColor() {
      let colors = {
        pending: "text-indigo-800",
        assigned: "text-orange-600",
        completed: "text-green-800",
        cancelled: "text-red-800",
        refund: "text-red-800",
      };
      let status = this.order.status;
      return colors[status];
    },
    isSuper() {
      return this.$store.getters.isSuper;
    },
    isCSR() {
      return this.$store.getters.isCSR;
    },
  },
  data: () => ({
    statusChangeError: false,
    order: {},
    pitChange: false,
    pit: "",
    justUpdated: false,
    justUpdated_Email: "",
    deliveryOffset: 2,
    earlyDate: "",
    latestDate: "",
    deliveryDetails: "",
  }),
  methods: {
    mergeNav() {
      this.$store.commit("LOAD", { is: true, msg: "Mergin to NAV" });

      const fail = () => {
        this.$store.commit("SET_MODAL", {
          danger: true,
          confirm: false,
          header: `Uh oh`,
          body: `There was an error Mergin to NAV. Please try again`,
        });
      };
      const success = () => {
        this.order.mergedToNav = true;
        this.$store.commit("SET_MODAL", {
          danger: false,
          confirm: false,
          header: `Successfully Merged`,
          body: `This order was successfully merged to NAV.`,
        });
      };

      this.$api
        .post(`/orders/nav/${this.order.id}`)
        .then(({ data }) => {
          if (data.success) {
            return success();
          } else fail();
        })
        .catch(fail)
        .finally(() => {
          this.$store.commit("LOAD", { is: false, msg: "Mergin to NAV" });
        });
    },
    resetPit() {
      this.pitChange = false;
      this.pit = "";
    },
    changePit() {
      this.pitChange = true;
      this.pit = this.order.pit || "";
    },
    updatePit() {
      this.pitChangeError = false;
      this.$store.commit("LOAD", { is: true, msg: "Updating Order" });
      this.$api
        .put(`/orders/${this.order.id}`, { pit: this.pit })
        .then(({ data }) => {
          this.order.pit = data.pit;
          this.resetPit();
        })
        .catch(() => {
          this.pitChangeError = true;
          this.$store.commit("SET_MODAL", {
            danger: true,
            confirm: false,
            header: `Uh oh`,
            body: `There was an error changing Pit #. Please try again`,
          });
        })
        .finally(() => {
          this.$store.commit("LOAD", { is: false, msg: "" });
        });
    },
    print() {
      window.print();
    },
    formatPrice,
    async confirmStatus(status) {
      this.statusChangeError = false;
      this.$store.commit("LOAD", { is: true, msg: "Updating Order" });
      this.$api
        .put(`/orders/${this.order.id}`, { status })
        .then(({ data }) => {
          // this.order.status = status;
          // console.log("previousEditBy", data.previousEditBy);
          this.order = data;
          this.justUpdated = true;
          this.justUpdated_Email = data.previousEditBy.email;
        })
        .catch(() => {
          this.$store.commit("SET_MODAL", {
            danger: true,
            confirm: false,
            header: `Uh oh`,
            body: `There was an error changing status. Please try again`,
          });
          this.statusChangeError = true;
        })
        .finally(() => {
          this.$store.commit("LOAD", { is: false, msg: "" });
        });
    },
    changeStatus(newStatus) {
      let status = this.orderStatuses[newStatus];
      this.$store.commit("SET_MODAL", {
        callback: () => this.confirmStatus(newStatus),
        danger: false,
        okText: "Change Status",
        confirm: true,
        header: `Status to ${status}`,
        body: `This will change the order status to ${status}.`,
      });
    },
    formatDate,
  },
  async created() {
    this.$store.commit("LOAD", {
      is: true,
      msg: "Retrieving Order Information",
    });
    let { data } = await this.$api.get(`/orders/${this.$route.params.id}`);
    // console.log("Data", data);

    if (data.id) {
      this.order = data;
      // let { data: storeData } = await this.$api.get(
      //   `/stores/info/${data.store.id}`
      // );
      // // this.console.log(storeData);
      // this.deliveryOffset = storeData.deliveryOffset;
      // this.earlyDate = addDays(new Date(data.deliveryDate), 1);
      // this.latestDate = addDays(
      //   new Date(data.deliveryDate),
      //   storeData.deliveryOffset
      // );
    }
    this.$store.commit("LOAD", { is: false, msg: "" });
  },
};
</script>

<style lang="scss">
.order-card-lg {
  @media screen and (min-width: 640px) {
    grid-column: span 9 / span 9;
  }
}
</style>

<style scoped lang="scss">
@media screen {
  .print-show {
    display: none;
  }
}
@media print {
  .order-card-sm {
    width: 100%;
  }
  .print-hide {
    display: none;
  }
  .order-card-lg {
    width: 100%;
  }
  .print-font-12 {
    font-size: 12px;
  }
}
</style>
