<template>
  <div>
    <PageTitle>
      <div class="flex justify-between">
        <div>Partners</div>
        <div>
          <Button
            label="Add Partners"
            size="small"
            icon="plus"
            style="height: 30px"
            to="/newpartner"
            type="primary"
          />
        </div>
      </div>
    </PageTitle>

    <div class="container px-4 mx-auto">
      <table class="table-auto w-full table-styled" v-if="items.length">
        <thead>
          <tr>
            <th class="bg-green-50 text-left" style="padding-left: 1rem">
              PARTNERS NAME
            </th>
            <th class="bg-green-50"># of PARTNERS</th>
          </tr>
        </thead>
        <tbody class="td-centers">
          <tr v-for="item in queriedResults" :key="item.id">
            <td class="text-left" style="text-align: left; padding-left: 1rem">
              <router-link
                class="text-green-700 hover:text-green-800 font-bold"
                :to="`/partners/${item.id}`"
                >{{ item.name }}
              </router-link>
            </td>
            <td style="padding: 0 1rem">{{ item.code }}</td>
          </tr>
        </tbody>
      </table>
      <div
        class="w-full flex flex-col items-center justify-center py-7 text-gray-300"
        v-else
      >
        <Icons i="sadface" class="w-16" />
        <p class="text-gray-500 mt-3">
          Sorry, No Partners created yet. Please click Add to get started!
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle";
import Icons from "@/components/Icons";
import Button from "@/components/Button";
import FormInput from "@/components/Forms/FormInput";
import { formatPrice, formatDate, baseURL } from "@/assets/helper";
import Datepicker from "vue3-datepicker";
import $qs from "qs-stringify";

export default {
  components: {
    PageTitle,
    FormInput,
    Datepicker,
    Icons,
    Button,
  },
  data: () => ({
    query: "",
    items: [],
    filtered: [],
  }),
  watch: {
    async query(val) {
      if (val.length < 3) return (this.filtered = []);
      let query = $qs({
        _where: {
          _or: [
            { name_contains: val },
            { phone_contains: val },
            { email_contains: val },
          ],
        },
      });
      let { data } = await this.$api.get(`/stores/autocomplete?${query}`);

      if (data.length) this.filtered = data;
    },
  },
  methods: {
    getImage(prd) {
      if (prd.image?.url) return `${baseURL}${prd.image.url}`;
      return "https://picsum.photos/500/400";
    },
    formatPrice,
    formatDate,
  },
  computed: {
    queriedResults() {
      let result = this.items;
      // console.log(result,'queriedResults')
      if (this.query.length > 2) result = this.filtered;
      return result;
    },
    disabledDateFrom() {},
  },
  async created() {
    this.$store.commit("LOAD", { is: true });
    let { data } = await this.$api.get("/partners");
    this.items = data;
    this.$store.commit("LOAD", { is: false });
  },
};
</script>

<style></style>
