import axios from "axios";
import { format } from "date-fns";
import regions from "./resources/regions";

export function formatDate(date, fmt = "MM/dd/yyyy") {
  let dateObj = new Date(date);
  if (dateObj.toString().includes("Invalid")) return date;

  return format(dateObj, fmt);
}
export const deleteFile = (id) => {
  return $api.delete(`/upload/files/${id}`);
};
export const attachImage = ({ file, ref, id, field }) => {
  const formData = new FormData();
  formData.append(`files`, file, file.id);
  formData.append(`ref`, ref);
  formData.append(`refId`, id);
  formData.append(`field`, field);

  return $api.post(`/upload`, formData, {
    "Content-Type": "multipart/form-data",
  });
};

export const baseURL = 'https://api.lessmess.ca'
// export const baseURL = "http://localhost:1337";

export function formatPrice(price) {
  price = parseFloat(parseFloat(price).toFixed(2));
  return (
    "$" +
    price.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  );
}

export function cc_brand_id(cur_val) {
  var sel_brand;

  // the regular expressions check for possible matches as you type, hence the OR operators based on the number of chars
  // regexp string length {0} provided for soonest detection of beginning of the card numbers this way it could be used for BIN CODE detection also

  //JCB
  let jcb_regex = new RegExp("^(?:2131|1800|35)[0-9]{0,}$"); //2131, 1800, 35 (3528-3589)
  // American Express
  let amex_regex = new RegExp("^3[47][0-9]{0,}$"); //34, 37
  // Diners Club
  let diners_regex = new RegExp("^3(?:0[0-59]{1}|[689])[0-9]{0,}$"); //300-305, 309, 36, 38-39
  // Visa
  let visa_regex = new RegExp("^4[0-9]{0,}$"); //4
  // MasterCard
  let mastercard_regex = new RegExp(
    "^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[01]|2720)[0-9]{0,}$"
  ); //2221-2720, 51-55

  let maestro_regex = new RegExp("^(5[06789]|6)[0-9]{0,}$"); //always growing in the range: 60-69, started with / not something else, but starting 5 must be encoded as mastercard anyway
  //Discover
  let discover_regex = new RegExp(
    "^(6011|65|64[4-9]|62212[6-9]|6221[3-9]|622[2-8]|6229[01]|62292[0-5])[0-9]{0,}$"
  );
  ////6011, 622126-622925, 644-649, 65

  // get rid of anything but numbers
  cur_val = cur_val.replace(/\D/g, "");

  // checks per each, as their could be multiple hits
  //fix: ordering matter in detection, otherwise can give false results in rare cases
  if (cur_val.match(jcb_regex)) {
    sel_brand = false;
  } else if (cur_val.match(amex_regex)) {
    sel_brand = "AMEX";
  } else if (cur_val.match(diners_regex)) {
    sel_brand = false;
  } else if (cur_val.match(visa_regex)) {
    sel_brand = "VISA";
  } else if (cur_val.match(mastercard_regex)) {
    sel_brand = "MASTERCARD";
  } else if (cur_val.match(discover_regex)) {
    sel_brand = false;
  } else if (cur_val.match(maestro_regex)) {
    if (cur_val[0] == "5") {
      //started 5 must be mastercard
      sel_brand = "MASTERCARD";
    } else {
      sel_brand = false; //maestro is all 60-69 which is not something else, thats why this condition in the end
    }
  } else {
    sel_brand = false;
  }

  return sel_brand;
}
export const $api = axios.create({
  baseURL,
});

export const validateEmail = (email) => {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const Login = (store) => {
  return new Promise(async (resolve, reject) => {
    let jwt = getCookie("lmjt") || "";

    if (!jwt && !store.state.user) return reject();

    $api.defaults.headers["Authorization"] = `Bearer ${jwt}`;

    $api
      .get(`/users/me`)
      .then(({ data: user }) => {
        // console.log(user);
        store.commit("SET_USER", user);
        return resolve(user);
      })
      .catch((err) => {
        let user = {};
        store.commit("SET_USER", user);
        // console.log("ERROR IN VUEAPPLEMIDDLEWARE", err);
        resolve({ user });
      });
    if (store.state.user.role.type == "storeadmin") {
      $api
        .get(`/stores/base`)
        .then(({ data: userStore }) => {
          store.commit("SET_STORE", userStore);
          return resolve(userStore);
        })
        .catch((err) => {
          let userStore = {};
          store.commit("SET_STORE", userStore);
          // console.log("ERROR IN VUEAPPLEMIDDLEWARE", err);
          resolve({ userStore });
        });
    }
  });
};
export const saveAuth = (data, store) => {
  if (typeof data === "object" && data.jwt) {
    setCookie("lmjt", data.jwt);
    $api.defaults.headers["Authorization"] = `Bearer ${data.jwt}`;
    if (data.user && data.user.id) store.commit("SET_USER", data.user);
    // console.log(this.$store.state);
    return // console.log("ADDED");
  } else {
    clearAuth(store);
  }
};

export const clearAuth = (store) => {
  // localStorage.clear()
  setCookie("lmjt", "");
  store.commit("SET_USER", {});
  $api.defaults.headers["Authorization"] = ``;
};

export const getCookie = (cname) => {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const setCookie = (cname, cvalue, exdays = "21") => {
  // var d = new Date()
  // d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
  // var expires = "expires=" + d.toUTCString();
  let cook = cname + "=" + cvalue;
  // console.log('IS DEVELOPMENT', location.href.includes('localhost'))

  if (!location.href.includes("localhost")) {
    if (location.href.includes("ngenagency.com"))
      cook += `; domain=lessmessapp.ngenagency.com`;
    else cook += `; domain=admin.lessmess.ca`;
  }

  document.cookie = cook + "; path=/";
};

export const checkCookie = (cookie) => {
  var cookie = getCookie(cookie);
  return user != "";
};

export const getRegion = (ctr, reg) => {
  if (regions[ctr]) {
    let found = regions[ctr].find((x) => x.value === reg);
    if (found) return found.label;
  }
  return "N/A";
};
