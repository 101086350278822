<template>
  <div>
    <PageTitle>Admin Dashboard</PageTitle>
    <div class="home-page-grid container mx-auto px-4">
      <router-link
        v-if="isSuper || isCSR"
        to="/neworder"
        class="dash-item text-green-600 text-center flex flex-col justify-around"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 104.676 112.924">
          <g
            id="Group_902"
            data-name="Group 902"
            transform="translate(1080.078 616.222)"
          >
            <path
              id="Path_620"
              data-name="Path 620"
              d="M-1003.6-534.7v26.383a1.58,1.58,0,0,1-1.575,1.575h-69.884a1.58,1.58,0,0,1-1.574-1.575V-611.2a1.58,1.58,0,0,1,1.574-1.574h52.338v14.7a5.031,5.031,0,0,0,5.024,5.024h14.1v20.6l3.449-3.449v-19.473a1.719,1.719,0,0,0-.505-1.219l-18.923-18.924a1.58,1.58,0,0,0-.587-.49,1.718,1.718,0,0,0-.829-.212h-54.062a5.03,5.03,0,0,0-5.024,5.024v102.876a5.03,5.03,0,0,0,5.024,5.024h69.884a5.031,5.031,0,0,0,5.024-5.024v-29.832Zm-15.671-75.629,13.832,13.832h-12.258a1.58,1.58,0,0,1-1.574-1.575Z"
              transform="translate(0)"
              fill="#34833a"
            />
            <path
              id="Path_621"
              data-name="Path 621"
              d="M-804.123-444.826l-5.832-5.832a4.73,4.73,0,0,0-6.681,0l-40.32,40.324a1.714,1.714,0,0,0-.436.736l-4.1,14.167a1.724,1.724,0,0,0,2.13,2.15l14.081-4.079a1.716,1.716,0,0,0,.841-.463l40.322-40.322A4.73,4.73,0,0,0-804.123-444.826Zm-53.2,47.369,2.427-8.379,5.951,5.951Zm11.66-4.025-7.635-7.635,34.013-34.013,7.635,7.635Zm39.1-39.1-2.651,2.651-7.635-7.635,2.651-2.651a1.28,1.28,0,0,1,1.8,0l5.832,5.832A1.28,1.28,0,0,1-806.562-440.584Z"
              transform="translate(-172.661 -129.735)"
              fill="#34833a"
            />
            <path
              id="Path_622"
              data-name="Path 622"
              d="M-999.732-406.928h27.294a1.725,1.725,0,0,0,1.725-1.725,1.725,1.725,0,0,0-1.725-1.725h-27.294a1.725,1.725,0,0,0-1.725,1.725A1.725,1.725,0,0,0-999.732-406.928Z"
              transform="translate(-62.125 -162.655)"
              fill="#34833a"
            />
            <path
              id="Path_623"
              data-name="Path 623"
              d="M-954.518-355.762a1.725,1.725,0,0,0-1.725-1.724h-43.49a1.725,1.725,0,0,0-1.725,1.724,1.725,1.725,0,0,0,1.725,1.725h43.49A1.725,1.725,0,0,0-954.518-355.762Z"
              transform="translate(-62.125 -204.449)"
              fill="#34833a"
            />
            <path
              id="Path_624"
              data-name="Path 624"
              d="M-964.81-302.871a1.725,1.725,0,0,0-1.725-1.725h-33.2a1.725,1.725,0,0,0-1.725,1.725,1.725,1.725,0,0,0,1.725,1.725h33.2A1.725,1.725,0,0,0-964.81-302.871Z"
              transform="translate(-62.125 -246.241)"
              fill="#34833a"
            />
            <path
              id="Path_625"
              data-name="Path 625"
              d="M-972.139-249.981a1.725,1.725,0,0,0-1.725-1.724h-25.869a1.725,1.725,0,0,0-1.725,1.724,1.725,1.725,0,0,0,1.725,1.725h25.869A1.725,1.725,0,0,0-972.139-249.981Z"
              transform="translate(-62.125 -288.035)"
              fill="#34833a"
            />
            <path
              id="Path_626"
              data-name="Path 626"
              d="M-999.732-198.815a1.725,1.725,0,0,0-1.725,1.725,1.725,1.725,0,0,0,1.725,1.724h21.66a1.725,1.725,0,0,0,1.725-1.724,1.725,1.725,0,0,0-1.725-1.725Z"
              transform="translate(-62.125 -329.828)"
              fill="#34833a"
            />
          </g>
        </svg>

        <div>NEW ORDER</div>
      </router-link>
      <router-link
        to="/orders"
        class="dash-item text-green-600 text-center flex flex-col justify-around"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 114.932 100.45">
          <g
            id="Group_932"
            data-name="Group 932"
            transform="translate(-1698.157 -927.98)"
          >
            <path
              id="Path_649"
              data-name="Path 649"
              d="M1811.959,1009.587a3.951,3.951,0,0,0-3.154-1.573h-15.4a2.433,2.433,0,1,0,0,4.865H1807.7l-12.413,40.217h-62.042l-10.651-40.217h14.764a2.433,2.433,0,1,0,0-4.865h-16.208l-3.105-11.648-.018-.059a4.088,4.088,0,0,0-3.952-2.849l-13.278.249a2.433,2.433,0,0,0-2.387,2.478,2.442,2.442,0,0,0,2.441,2.387h.036l12.647-.237,18.1,67.891.018.059a4.1,4.1,0,0,0,3.877,2.849h.052l56.137-.127a2.433,2.433,0,0,0-.005-4.865l-55.565.126-1.681-6.306h61.473a4.052,4.052,0,0,0,3.854-2.973l12.857-41.654.024-.083A4.242,4.242,0,0,0,1811.959,1009.587Z"
              transform="translate(0 -62.232)"
              fill="#34833a"
              stroke="#fff"
              stroke-width="0.5"
            />
            <path
              id="Path_650"
              data-name="Path 650"
              d="M3268.505,2733.927a8.5,8.5,0,1,0,8.5,8.5A8.508,8.508,0,0,0,3268.505,2733.927Zm0,12.861a4.363,4.363,0,1,1,4.364-4.363A4.368,4.368,0,0,1,3268.505,2746.788Z"
              transform="translate(-1489.86 -1722.743)"
              fill="#34833a"
              stroke="#fff"
              stroke-width="0.5"
            />
            <path
              id="Path_651"
              data-name="Path 651"
              d="M2570.914,2733.927a8.5,8.5,0,1,0,8.5,8.5A8.508,8.508,0,0,0,2570.914,2733.927Zm0,12.861a4.363,4.363,0,1,1,4.364-4.363A4.368,4.368,0,0,1,2570.914,2746.788Z"
              transform="translate(-824.316 -1722.743)"
              fill="#34833a"
              stroke="#fff"
              stroke-width="0.5"
            />
            <path
              id="Path_652"
              data-name="Path 652"
              d="M2726.841,962.346a19.925,19.925,0,1,0-4.282-6.352A19.914,19.914,0,0,0,2726.841,962.346Zm2.924-25.339a15.85,15.85,0,1,1-4.643,11.208A15.746,15.746,0,0,1,2729.764,937.007Z"
              transform="translate(-975.602)"
              fill="#34833a"
              stroke="#fff"
              stroke-width="0.5"
            />
            <path
              id="Path_653"
              data-name="Path 653"
              d="M2918.219,1180.568l8.712,7.8,11.962-14.258a2.2,2.2,0,0,0-.273-3.089h0a2.192,2.192,0,0,0-3.088.273l-9.051,10.784-5.336-4.779a2.192,2.192,0,0,0-2.926,3.266Z"
              transform="translate(-1163.077 -231.153)"
              fill="#34833a"
              stroke="#fff"
              stroke-width="0.5"
            />
          </g>
        </svg>

        <div>ORDERS</div>
      </router-link>

      <router-link
        v-if="isSuper"
        to="/dealers"
        class="dash-item text-green-600 text-center flex flex-col justify-around"
      >
        <Icons i="dealers" />
        <div>DEALERS</div>
      </router-link>

      <router-link
        v-if="isSuper"
        to="/customers"
        class="dash-item text-green-600 text-center flex flex-col justify-around"
      >
        <Icons i="customers" />
        <div>CUSTOMERS</div>
      </router-link>

      <router-link
        v-if="isSuper"
        to="/products"
        class="dash-item text-green-600 text-center flex flex-col justify-around items-center"
      >
        <img src="@/assets/img/prd.png" style="width: 45%" />
        <div>PRODUCTS</div>
      </router-link>
      <router-link
        v-if="isSuper"
        to="/categories"
        class="dash-item text-green-600 text-center flex flex-col justify-around items-center"
      >
        <svg fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
          />
        </svg>
        <div>PRODUCT CATEGORIES</div>
      </router-link>
      <router-link
        to="/promotions"
        v-if="isSuper || isStoreAdmin"
        class="dash-item text-green-600 text-center flex flex-col justify-around"
      >
        <Icons i="promotions" />
        <div>PROMOTIONS</div>
      </router-link>
      <router-link
        v-if="isSuper"
        to="/cms"
        class="dash-item text-green-600 text-center flex flex-col justify-around"
      >
        <svg viewBox="0 0 122.367 94.562">
          <g transform="translate(-1515.815 -509.209)">
            <path
              data-name="Path 627"
              d="M1635.58,509.459H1518.417a2.349,2.349,0,0,0-2.352,2.349v77.464a2.349,2.349,0,0,0,2.352,2.349h51.129l-3.263,7.2h-11.209a2.349,2.349,0,1,0,0,4.7h12.681c.015,0,.029,0,.044,0s.044,0,.067,0h18.268c.023,0,.044,0,.067,0s.029,0,.043,0h3.965a2.349,2.349,0,1,0,0-4.7H1587.7l-3.348-7.2h5.857a2.349,2.349,0,1,0,0-4.7h-9.565c-.029,0-.056,0-.085,0h-59.789V581.8h69.439a2.349,2.349,0,1,0,0-4.7h-69.439V514.158h112.459v18.4a2.352,2.352,0,0,0,4.7,0V511.808A2.349,2.349,0,0,0,1635.58,509.459Zm-53.07,89.358h-11.062l3.263-7.2h4.451Z"
              transform="translate(0 0)"
              fill="#34833a"
              stroke="#fff"
              stroke-width="0.5"
            />
            <path
              data-name="Path 628"
              d="M1608.244,541.986a2.358,2.358,0,0,0-3.326,0l-24.485,24.485a2.352,2.352,0,0,0,3.326,3.327l24.485-24.485A2.354,2.354,0,0,0,1608.244,541.986Z"
              transform="translate(-23.288 -11.645)"
              fill="#34833a"
              stroke="#fff"
              stroke-width="0.5"
            />
            <path
              data-name="Path 629"
              d="M1607.61,581.75a2.348,2.348,0,0,0,1.663-.686l10.236-10.236a2.352,2.352,0,0,0-3.326-3.327l-10.236,10.237a2.349,2.349,0,0,0,1.663,4.013Z"
              transform="translate(-32.619 -20.976)"
              fill="#34833a"
              stroke="#fff"
              stroke-width="0.5"
            />
            <path
              data-name="Path 630"
              d="M1676.62,553.509h-27.39a7.27,7.27,0,0,0-7.329,7.191v51.734a7.27,7.27,0,0,0,7.329,7.192h27.39a7.271,7.271,0,0,0,7.332-7.192V560.7A7.27,7.27,0,0,0,1676.62,553.509Zm2.628,58.925a2.6,2.6,0,0,1-2.628,2.493h-27.39a2.565,2.565,0,0,1-2.625-2.493V560.7a2.6,2.6,0,0,1,2.625-2.492h27.39a2.6,2.6,0,0,1,2.628,2.492Z"
              transform="translate(-46.019 -16.11)"
              fill="#34833a"
              stroke="#fff"
              stroke-width="0.5"
            />
            <path
              data-name="Path 631"
              d="M1672.055,637.768a2.315,2.315,0,0,0-.684,1.658,2.509,2.509,0,0,0,.684,1.663,2.3,2.3,0,0,0,3.317-.052,2.291,2.291,0,0,0,.684-1.611,2.342,2.342,0,0,0-4-1.658Z"
              transform="translate(-56.797 -46.671)"
              fill="#34833a"
              stroke="#fff"
              stroke-width="0.5"
            />
          </g>
        </svg>

        <div>WEB CONTENT</div>
      </router-link>

      <router-link
        v-if="isSuper"
        to="/partners"
        class="dash-item text-green-600 text-center flex flex-col justify-around items-center"
      >
        <Icons i="duplicate" />
        <div>PARTNERS</div>
      </router-link>

      <router-link
        v-if="isSuper"
        to="/edi"
        class="dash-item text-green-600 text-center flex flex-col justify-around items-center"
      >
        <svg fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
          ></path>
        </svg>
        <div>EDI UPLOAD</div>
      </router-link>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle";
import Icons from "@/components/Icons";

export default {
  name: "Home",
  components: {
    PageTitle,
    Icons,
  },
  computed: {
    isSuper() {
      return this.$store.getters.isSuper;
    },
    isCSR() {
      return this.$store.getters.isCSR;
    },
    isStoreAdmin() {
      return this.$store.getters.isStoreAdmin;
    },
  },
  created() {
    // // console.log(this.$store.state)
  },
};
</script>

<style lang="scss">
.home-page-grid {
  margin-top: 3vw;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5vw;
  @media screen and (min-width: 968px) {
    grid-template-columns: repeat(4, 1fr);
  }
}
.dash-item {
  cursor: pointer;
  &:before {
    content: "";
  }
  border: 1px solid var(--green);
  min-height: 200px;
  font-weight: bold;
  svg {
    margin: 0 auto 5px;
    width: 40%;
  }
  &:hover {
    background: #fffbdb;
  }
  &.disabled {
    opacity: 0.4;
    cursor: not-allowed;
    pointer-events: none;
  }
}
</style>
