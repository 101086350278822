<template>
    <Modal :okClose="false" v-if="modalShow" header="Add Service Area Postal Code" @cancel="resetModal" @close="resetModal" ok-text="Add"  @ok="addCode" :icon="false" :confirm="true">
        <template v-slot:body>
            <div class="text-sm text-gray-500 space-y-4 mt-3">
                <div class="flex space-x-3">
                    <FormInput class="flex-1" :show-errors="true" label="Postal Code FSA" v-model="newCode" type="text" :required="true" :maxlen="6" uppercase :check="validFSA" />
                </div>
                <p v-if="duplicate" class="text-red-800">
                    This postal is already being serviced by another dealer.
                </p>
            </div>
        </template>
    </Modal>
    <PageTitle>
        <div class="flex justify-between items-center px-4 sm:px-0">
            <div>
                <router-link :to="backLink" class="text-blue-500 inline-flex cursor-pointer hover:text-blue-800">
                    <Icons i="arrow-left" class="w-4 h-4 hover:round" />
                </router-link>
                <span class="text-gray-400 ml-3" v-if="item.name">
                    Edit Postal Codes Services for
                    <span class="text-gray-500 ml-2">{{item.name}}</span>
                </span> 

            </div>
            <div class="print-hide print-font-12">
                <Button label="Add Postal Code" size="small" style="min-width:100px;" @click="add" type="primary" icon="plus"/>
            </div>
        </div>
    </PageTitle>

    <div class="mt-10 container px-4 mx-auto mb-24 ">
        
        <label class="block text-sm font-medium text-gray-700 text-xl mb-6">Postal Codes</label>
        <table class="table-auto w-full table-styled">
            <thead>
                <tr>
                    <th class="bg-green-50 ">POSTAL CODE</th>
                    <th class="bg-green-50">&nbsp;</th>
                </tr>
            </thead>
            <tbody class="td-centers">
                <tr v-for="area in areas" :key="area.id" >
                    <td class="flex items-center">
                        <div class="flex-1 px-4 flex items-center">
                            <div class="ml-4">
                                <div class=" font-bold">
                                    {{area.code}}
                                </div>
                            </div>
                        </div>
                    </td>
                  
                    <td style="width:50px;">
                        <Icons i="trash" @click="deleteArea(area)" class="w-6 h-6 text-red-500 cursor-pointer hover:text-red-800" />
                    </td>
                </tr>
            
            </tbody>
        </table>
        <div class="w-full flex flex-col items-center justify-center py-7 text-gray-300" v-if="!areas || areas.length === 0">
            <Icons i="sadface" class="w-16" />
            <p class="text-gray-500 mt-3">
                No Postal codes are chosen for this dealer. Please click the <b>Add</b> button on top to get started.
            </p>
        </div>
        
    </div>
</template>

<script>

import PageTitle from '@/components/PageTitle'
import {formatPrice,validateEmail} from '@/assets/helper'
import FormInput from "@/components/Forms/FormInput";
import FormSelect from "@/components/Forms/FormSelect";
import Button from "@/components/Button";
import Icons from "@/components/Icons";
import regions from '@/assets/resources/regions'
import Modal from "@/components/Modal"

export default {
    components:{
        FormInput,
        FormSelect,
        Button,
        Icons,
        PageTitle,
        Modal
    },
    data:( () => ({
        modalShow:false,
        newCode:'',
        showErrors:false,
        original:{},
        allPromotions:[],
        duplicate:'',
        item:{
            name:'',
            email:'',
            phone:'',
            city:'',
            region:'ON',
            country:'CA',
            code:'',
            address:'',
            openMon:false,
            openTue:false,
            openWed:false,
            openThu:false,
            openFri:false,
            openSat:false,
            openSun:false
        },
        showErrors:false,
        optIns:[],
        products:[],
        areas:[],
        promotions:[],
        defaultProducts:[],
        deliveryOverrides:[],
    })),
    computed:{
        backLink(){
            return this.editMode? `/dealers/${this.$route.params.id}` : '/dealers'
        },
        editMode(){
            return Boolean(this.$route.params.id) && Boolean(this.original.id)
        },
        regionOptions(){
            return {
                'CA':regions.CA,
                'USA':regions.USA.map(d => ({label:d,value:d,}))
            }
        }
    },
    watch:{
        'item.country'(val){
            this.item.region = val === 'CA' ? 'ON' : 'CA'
        },
    },
    methods:{
        validFSA: x => x.length === 3 && (/[a-zA-Z]/).test(x[0]) && !isNaN(parseInt(x[1])) && (/[a-zA-Z]/).test(x[2]),
        resetModal(){
            // console.log('KLDJFSKJDF')
            this.modalShow = false
            this.newCode = ''
        },
        async addCode(){
            this.duplicate = ''
            // console.log('ADD CODEEEEE', this.newCode)
            let found = this.areas.find(x => x.code.toUpperCase() === this.newCode.toUpperCase())
            if (found) return this.duplicate = 'local'
            this.$store.commit('LOAD', {is:true})
            let {data} = await this.$api.get('/areas/?code_contains='+this.newCode)
            this.$store.commit('LOAD', {is:false})


            let newCodeObj = {}

            if (data && data.length){
                if (data[0].store?.id){
                    return this.duplicate = 'external'
                } else {
                    newCodeObj = data[0]
                }
            }

            if (!newCodeObj.id){
                this.$store.commit('LOAD', {is:true})
                let {data:newAreaAdded} = await this.$api.post('/areas',{code:this.newCode})
                if (newAreaAdded?.id) newCodeObj = newAreaAdded
                this.$store.commit('LOAD', {is:false})
            }
            
            if (newCodeObj?.id){
                let areas = this.areas.map(x => x.id)
                areas.push(newCodeObj.id)
                this.$store.commit('LOAD', {is:true})
                this.$api.put(`/stores/${this.original.id}`, {areas}).then( ({data:updated}) => {
                    if (updated?.id){
                        this.areas.push(newCodeObj)
                        this.$store.commit('SET_MODAL',{
                            danger:false,
                            confirm:false,
                            header:`Success`,
                            body:`Successfully Added the Code`,
                        })
                    }
                })
                .catch( () => {
                    this.$store.commit('SET_MODAL',{
                        danger:true,
                        confirm:false,
                        header:`Uh oh,`,
                        body:`There was an error while updating. Please try again.`,
                    })
                })
                .finally( () => {
                    this.resetModal()
                    this.$store.commit('LOAD', {is:false})
                })
                
            }
        },
        deleteConfirm(id){
            this.$store.commit('LOAD',{is:true})
            this.$api.delete('/areas/'+id).then( () => {
                this.$store.commit('SET_MODAL',{
                    danger:false,
                    confirm:false,
                    header:`Successfully deleted`,
                    body:`This Area code has been successfully deleted.`,
                })
                this.areas = this.areas.filter(x => x.id !== id)
            }).catch( () => {
                this.$store.commit('SET_MODAL',{
                    danger:true,
                    confirm:false,
                    header:`Uh oh,`,
                    body:`An error occured trying to delete this postal code. Please try again.`,
                })
            }).finally( () => {
                this.$store.commit('LOAD',{is:false})
            })

        },
        deleteArea(item){
            let {id} = item
            this.$store.commit('SET_MODAL',{
                callback:() => this.deleteConfirm(id),
                danger:true,
                confirm:true,
                okText:'Delete',
                header:`This will remove the Postal Code: ${item.code.toUpperCase()}`,
                body:`Are you sure you want to remove this Postal code for this dealer?`,
            })
        },
        add(){
            this.modalShow = true
            // console.log('MODAL SHOW', this.modalShow)
        },
        validateEmail,
        isValidPostal: postal => (/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/).test(postal),
        isValidZip: zip =>  /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zip),
        isValid(){
            let valid = true
            
            if (!this.item.name.trim() || !validateEmail(this.item.email)) valid = false 

            return valid
        },
        submit(){

            if (!this.isValid()) return this.showErrors = true

            let optedProducts = this.defaultProducts.filter(x => x.optIn).map(x => ({
                cost:x.cost,
                product:x.id,
                store:this.original.id
            }))

            let productsToAdd = this.products.filter(x => !x.isDefault).map(x => x.id)
            productsToAdd = [...productsToAdd,...optedProducts.map(x => x.product)]

            let promotions = this.allPromotions.filter(x => x.optIn).map(x => x.id)
            
            let sendObj = {...this.item,optIns:optedProducts,products:productsToAdd,promotions} 

            if (this.editMode){
                               
                this.$api.put(`/stores/${this.original.id}`, sendObj)
                    .then( ({data}) => {
                        this.$store.commit('SET_MODAL',{
                            callback: () => this.$router.push(`/dealers/${data.id}`),
                            danger:false,
                            confirm:false,
                            header:'Success',
                            body:`You have successfully updated this dealer ${data.name}`
                        })
                    }).catch( () => {
                        this.$store.commit('SET_MODAL',{
                            danger:true,
                            confirm:false,
                            header:'Error',
                            body:`An error Occurred while updating. Please try again`
                        })
                    })

            } else {

                this.$api.post(`/stores`, sendObj).then( ({data}) => {
                    this.$store.commit('SET_MODAL',{
                        callback: () => this.$router.push(`/dealers/${data.id}`),
                        danger:false,
                        confirm:false,
                        header:'Success',
                        body:`You have successfully created Dealer: ${data.name}`
                    })
                }).catch( () => {
                    this.$store.commit('SET_MODAL',{
                        danger:true,
                        confirm:false,
                        header:'Uh oh,',
                        body:`An error Occurred while updating.`
                    })
                })

            }
            
        },
        opted(e,f){
            // console.log('EEEEEEE',e)
            // console.log('FFFFFFFFF',f)
            // console.log('************')
        },
        formatPrice,
    },
    async created(){
        
        

        if (this.$route.params.id){

            this.$store.commit('LOAD', {is:true,msg:'Retrieving Dealer Information'})
        
            let {data} = await this.$api.get(`/stores/${this.$route.params.id}`)
            if (data.id){

                let {products,optIns,areas,promotions,deliveryOverrides,...dealer} = data
                this.deliveryOverrides = deliveryOverrides
                this.products = products
                this.optIns = optIns
                this.promotions = promotions
                this.areas = areas
    
                delete dealer.categories
                delete dealer.created_by
                delete dealer.updated_by
    
                this.original = dealer 
    
                let {name,email,phone,city,region,country,code,address,openMon,openTue,openWed,openThu,openFri,openSat,openSun} = dealer 
                this.item = {name,email,phone,city,region,country,code,address,openMon,openTue,openWed,openThu,openFri,openSat,openSun}
            }
            
        }

        let dealerProducts = this.products
        let optIns = this.optIns
        let promotions = this.promotions.map(x => x.id)
        
        let {data:products} = await this.$api.get(`/products/?isDefault=1`)
        let {data:allPromotions} = await this.$api.get(`/promotions?_limit=-1`)

        allPromotions = allPromotions.map(x => {
            x.optIn = promotions.includes(x.id)
            let {value,type} = x
            let valueLabel = value + '%'
            if (type === 'dollar') valueLabel = formatPrice(value)
            x.valueLabel = `${valueLabel}` 
            return x
        })
        
        this.allPromotions = allPromotions

        products = products.map(prd => {
            let dPrd = dealerProducts.find(x => x.id === prd.id)
            let optIn = optIns.find(x => x.product === prd.id)
            
            if (dPrd?.id && optIn?.id){
                prd.optIn = true
                prd.cost = optIn.cost
            } else {
                prd.optIn = false
                prd.cost = 0
            }

            prd.image = prd.image[0]
            if (!prd.image) prd.image = {url:''}
            return prd
        })

        this.defaultProducts = products

        this.$store.commit('LOAD', {is:false,msg:''})
    }
}
</script>

<style>

</style>