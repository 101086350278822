<template>
  <div>
    <PageTitle>
      <div class="flex justify-between">
        <div>View Promotions</div>
        <div>
          <Button
            label="Create Promotion"
            size="small"
            icon="plus"
            style="height: 30px"
            @click="addPromo"
            type="primary"
          />
        </div>
      </div>
    </PageTitle>

    <Modal
      :okClose="false"
      v-if="modalInfo.show"
      :header="promotion.id ? 'Edit Promotion' : 'New Promotion'"
      @close="closeModal"
      :ok-text="promotion.id ? 'Update' : 'Create'"
      @ok="modalSubmit"
      :icon="false"
      :confirm="true"
    >
      <template v-slot:topbar>
        <Toggle
          v-model="promotion.active"
          activeText="Active Promo"
          inactiveText="Inactive Promo"
        />
      </template>
      <template v-slot:body>
        <div class="text-sm text-gray-500 space-y-4 mt-3">
          <div class="flex space-x-3">
            <FormInput
              class="flex-1"
              :show-errors="showErrors"
              label="Name"
              v-model="promotion.name"
              type="text"
              :required="true"
            />
            <FormInput
              :uppercase="true"
              class="flex-1"
              :show-errors="showErrors"
              :label="isSuper ? 'CODE' : 'CODE [' + storeCodeName + '-CODE]'"
              placeholder="eg. FEB15OFF"
              v-model="promotion.code"
              type="text"
              :required="true"
              :check="(val) => val.indexOf(' ') === -1"
              check-error="No Spaces allowed"
            />
          </div>
          <div class="flex space-x-3">
            <FormInput
              class="flex-1"
              :show-errors="showErrors"
              label="Discount"
              v-model="promotion.value"
              type="number"
              :required="true"
              :check="(val) => val > 0"
            />
            <FormSelect
              :show-errors="showErrors"
              class="flex-1"
              label="Dollar / Percentage"
              v-model="promotion.type"
              :options="[
                { label: 'Percentage (%)', value: 'percentage' },
                { label: 'Dollar ($)', value: 'dollar' },
              ]"
              :required="true"
            />
          </div>
          <div class="flex space-x-3">
            <FormInput
              class="flex-1"
              :show-errors="showErrors"
              label="Minimum Purchase ($)"
              v-model="promotion.minPurchase"
              type="number"
            />
            <div>
              <label class="block text-sm font-medium text-gray-700"
                >Expiry Date
                <a
                  href="#"
                  v-if="promotion.expiry !== ''"
                  @click.prevent="promotion.expiry = ''"
                  class="ml-2 text-sm text-blue-500"
                  >(clear)</a
                ></label
              >
              <Datepicker
                class="mt-1 ring-green-500 block shadow-sm sm:text-sm border-gray-300 rounded-md"
                v-model="promotion.expiry"
                :lower-limit="new Date()"
              />
            </div>
          </div>
          <div>
            <label class="block mb-2 text-sm font-medium text-gray-700"
              >One time ?</label
            >
            <div>
              <Toggle
                v-model="promotion.oneTime"
                activeText="Yes"
                inactiveText="No"
              />
            </div>
          </div>
        </div>
      </template>
    </Modal>

    <div class="container px-4 mx-auto">
      <table class="table-auto w-full table-styled">
        <thead>
          <tr>
            <th class="bg-green-50">NAME</th>
            <th class="bg-green-50">CODE</th>
            <th class="bg-green-50">DISCOUNT</th>
            <th class="bg-green-50">DETAILS</th>
            <th class="bg-green-50">&nbsp;</th>
          </tr>
        </thead>
        <tbody class="td-centers">
          <tr v-for="promo in items" :key="promo.id">
            <td>{{ promo.name }}</td>
            <td class="">
              <span
                >{{ promo.code }}
                <span class="text-red-800" v-if="!promo.active"
                  >(Inactive)</span
                ></span
              >
            </td>
            <td class="">
              <span>{{ getValue(promo) }}</span>
            </td>
            <td class="detail-spec">
              <span v-if="promo.oneTime">One Time</span>
              <span v-if="promo.expiry && promo.expiry.trim()"
                >Expires: {{ formatDate(promo.expiry, "MMM/dd/yyyy") }}</span
              >
            </td>
            <td class="w-32">
              <div class="inline-flex space-x-5">
                <Icons
                  v-if="isSuper ? true : !promo.createdBySuper"
                  i="pen"
                  @click="edit(promo)"
                  class="w-6 h-6 text-green-500 cursor-pointer hover:text-green-800"
                />
                <Icons
                  i="trash"
                  @click="deletePromo(promo)"
                  class="w-6 h-6 text-gray-500 cursor-pointer hover:text-red-800"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Datepicker from "vue3-datepicker";
import PageTitle from "@/components/PageTitle";
import Icons from "@/components/Icons";
import Button from "@/components/Button";
import FormInput from "@/components/Forms/FormInput";
import Toggle from "@/components/Forms/Toggle";
import FormSelect from "@/components/Forms/FormSelect";
import { formatPrice, formatDate } from "@/assets/helper";
import Modal from "@/components/Modal";

export default {
  components: {
    PageTitle,
    FormInput,
    Datepicker,
    Icons,
    Toggle,
    Modal,
    Button,
    FormSelect,
  },
  data: () => ({
    items: [],
    showErrors: false,
    modalInfo: {
      show: false,
    },
    promotion: {
      id: "",
      name: "",
      code: "",
      value: 1,
      type: "dollar",
      minPurchase: 0,
      active: true,
      expiry: "",
    },
  }),
  watch: {
    async queryString(val) {
      this.$store.commit("LOAD", { is: true });
      let { data } = await this.$api.get("/orders" + val);
      this.filterResults = data;
      this.$store.commit("LOAD", { is: false });
    },
    filter: {
      deep: true,
      handler(val) {
        let lookup = {
          from: "createdAt_gte",
          to: "createdAt_lte",
          dealer: "store",
        };
        let queryString = [];
        Object.entries(val).forEach(([key, value]) => {
          if (value && lookup[key]) {
            if (key === "from" || (key === "to" && value.toISOString))
              value = value.toISOString();
            queryString.push(`${lookup[key]}=${value}`);
          }
        });
        queryString = `?${queryString.join("&")}`;
        this.queryString = queryString;
        // console.log("QUERY STRING", queryString);
      },
    },
    async "dealerSearch.query"(val) {
      if (val.length < 3) return (this.dealerSearch.results = []);
      let { data } = await this.$api.get(`/stores?name_contains=${val}`);
      if (data.length) this.dealerSearch.results = data;
    },
  },
  methods: {
    deleteConfirm(id) {
      this.$store.commit("LOAD", { is: true });
      this.$api
        .delete(`/promotions/${id}`)
        .then(({ data }) => {
          this.items = this.items.filter((x) => x.id !== data.id);
        })
        .catch((err) => {
          this.$store.commit("SET_MODAL", {
            danger: true,
            confirm: false,
            header: `Uh oh`,
            body:
              `There was an error deleting. Please try again ` +
              err.response.data.message,
          });
        })
        .finally(() => {
          this.$store.commit("LOAD", { is: false });
        });
    },
    deletePromo(promo) {
      let { id } = promo;
      this.$store.commit("SET_MODAL", {
        callback: () => this.deleteConfirm(id),
        danger: true,
        confirm: true,
        okText: "Delete",
        header: `This cannot be undone`,
        body: `Are you sure you want to delete this Promotion?`,
      });
    },
    modalSubmit() {
      let promo = this.promotion;

      if (!promo.name || !promo.code || !promo.value || !promo.type)
        return (this.showErrors = true);

      if (this.isSuper) {
        promo.code = promo.code.toUpperCase().trim();
      } else {
        promo.code = (this.storeCodeName + "-" + promo.code)
          .toUpperCase()
          .trim();
      }

      if (promo.code.indexOf(" ") !== -1) return (this.showErrors = true);

      this.$store.commit("LOAD", { is: true });
      promo = JSON.parse(JSON.stringify(promo));
      if (promo.expiry) {
        promo.expiry = new Date(promo.expiry).toISOString();
      } else promo.expiry = null;
      // console.log("PROMO EXPIRY", promo.expiry);

      if (promo.id) {
        this.$api
          .put(`/promotions/${promo.id}`, promo)
          .then(({ data }) => {
            if (data.id) {
              this.items = this.items.map((x) => (x.id === data.id ? data : x));
              this.$store.commit("SET_MODAL", {
                callback: this.closeModal,
                danger: false,
                confirm: false,
                header: `Success`,
                body: `Created Promo Code: ${data.code}`,
              });
            }
          })
          .catch((err) => {
            this.$store.commit("SET_MODAL", {
              danger: true,
              confirm: false,
              header: `Uh oh`,
              body:
                `An error occurred while creating this promotion. Please try again` +
                err.response.data.message,
            });
          })
          .finally(() => {
            this.$store.commit("LOAD", { is: false });
          });
      } else {
        delete promo.id;
        // console.log("NEW PRMOTIONS", promo);
        this.$api
          .post("/promotions", promo)
          .then(({ data }) => {
            if (data.id) {
              this.items.push(data);
              this.$store.commit("SET_MODAL", {
                callback: this.closeModal,
                danger: false,
                confirm: false,
                header: `Success`,
                body: `Created Promo Code: ${data.code}`,
              });
            }
          })
          .catch((err) => {
            this.$store.commit("SET_MODAL", {
              danger: true,
              confirm: false,
              header: `Uh oh`,
              body:
                `An error occurred while creating this promotion. Please try again ` +
                err.response.data.message,
            });
          })
          .finally(() => {
            this.$store.commit("LOAD", { is: false });
          });
      }
    },
    addPromo() {
      this.modalInfo.show = true;
    },
    closeModal() {
      this.promotion = {
        id: "",
        name: "",
        code: "",
        value: 1,
        type: "dollar",
        minPurchase: 0,
        expiry: "",
        active: true,
      };
      this.showErrors = false;
      this.modalInfo = {
        show: false,
      };
    },
    edit(promo) {
      this.showErrors = false;
      promo = JSON.parse(JSON.stringify(promo));
      promo.expiry = promo.expiry ? new Date(promo.expiry) : "";
      this.promotion = promo;
      this.modalInfo = {
        show: true,
      };
    },
    getValue(promo) {
      let value = promo.value;
      if (promo.type === "percentage") value = `${value}%`;
      else value = formatPrice(value);
      return value;
    },

    formatPrice,
    formatDate,
  },
  computed: {
    isSuper() {
      return this.$store.getters.isSuper;
    },
    isStoreAdmin() {
      return this.$store.getters.isStoreAdmin;
    },
    storeCodeName() {
      let storeName = this.$store.state.store.name;
      return storeName.toUpperCase().replace(/\s/g, "");
    },
  },
  async created() {
    this.$store.commit("LOAD", { is: true });
    if (this.isSuper) {
      // console.log(this.isSuper);
      var { data: allPromotions } = await this.$api.get("/promotions?_limit=-1");
    } else if (this.isStoreAdmin) {
      var { data: allPromotions } = await this.$api.get("/storePromotions");
    }

    // console.log(allPromotions, "allPromotions init");

    allPromotions = allPromotions
      .filter((x) => !x.default)
      .map((x) => {
        if (!x.hasOwnProperty("expiry")) x.expiry = "";
        return x;
      });
    this.items = allPromotions;
    this.$store.commit("LOAD", { is: false });
  },
};
</script>

<style>
.detail-spec > span + span:before {
  content: " / ";
}
</style>
