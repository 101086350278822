<template>
    <div class="w-full md:w-3/5 mx-auto mb-10">

        <div class="shadow-md">
            <template v-for="section in sections">
                <template v-if="section.fields?.length && lang === section.lang">

                    <div class="tab w-full overflow-hidden border-t"  :key="section.value">
                        <input class="absolute opacity-0" :id="section.label" type="radio" name="accordionPages">
                        <label class="block p-5 leading-normal cursor-pointer" :for="section.label">{{section.label}}</label>
                        <div class="tab-content overflow-hidden border-l-2 bg-gray-100 border-green-200 leading-normal">
                            <div class="p-5">
                                <div v-for="field in section.fields" :key="field.label">
                                    <template v-if="pageFields[section.slug] && pageFields[section.slug].fields && pageFields[section.slug].fields.hasOwnProperty(field.model)">
                                        <template v-if="field.type === 'textarea'">
                                            <p class="text-gray-600 mb-2">{{field.label}}</p>
                                            <textarea v-model="pageFields[section.slug].fields[field.model]" class="w-full md:w-1/2" />
                                        </template>
                                    </template>
                                </div>
                                <Button label="Save" type="primary" style="padding:10px 30px" @click="updateFields(section)" />
                            </div>
                        </div>
                    </div>

                </template>
            </template>
           
        </div>
    </div>
</template>

<script>
import Button from '@/components/Button'
export default {
    props:{
        lang:{
            type:String,
            default:'en'
        }
    },
    components:{
        Button,
    },
    data:( () => ({
        sections:[
            {
                label:'Home Page',value:'home',slug:'home',lang:'en',
                fields:[
                    {
                        label:'What We Offer',
                        type:'textarea',
                        model:'whatWeOffer',
                    }
                ],
            },
            {
                label:'Home Page (French)',value:'home_fr',slug:'home',lang:'fr',
                fields:[
                    {
                        label:'What We Offer (French)',
                        type:'textarea',
                        model:'whatWeOffer_fr',
                    }
                ],
            },


            {
                label:'Home Page (USA)',value:'home_en_usa',slug:'home',lang:'en_usa',
                fields:[
                    {
                        label:'What We Offer (English(USA))',
                        type:'textarea',
                        model:'whatWeOffer_en_usa',
                    }
                ],
            },

            
            {
                label:'About Page',value:'home',slug:'about',lang:'en',
                fields:[
                    {
                        label:'Quality Standards',
                        type:'textarea',
                        model:'qualityStandards',
                    },
                    {
                        label:'Environmental Responsibility',
                        type:'textarea',
                        model:'environment',
                    },
                    {
                        label:'Our History',
                        type:'textarea',
                        model:'history',
                    },
                    {
                        label:'Our Mission',
                        type:'textarea',
                        model:'mission',
                    },
                    {
                        label:'Our Vision',
                        type:'textarea',
                        model:'vision',
                    },
                    {
                        label:'Brand Values',
                        type:'textarea',
                        model:'brandValues',
                    },
                ],
            },
            {
                label:'About Page (French)',value:'home_fr',slug:'about',lang:'fr',
                fields:[
                    {
                        label:'Quality Standards (French)',
                        type:'textarea',
                        model:'qualityStandards_fr',
                    },
                    {
                        label:'Environmental Responsibility (French)',
                        type:'textarea',
                        model:'environment_fr',
                    },
                    {
                        label:'Our History (French)',
                        type:'textarea',
                        model:'history_fr',
                    },
                    {
                        label:'Our Mission (French)',
                        type:'textarea',
                        model:'mission_fr',
                    },
                    {
                        label:'Our Vision (French)',
                        type:'textarea',
                        model:'vision_fr',
                    },
                    {
                        label:'Brand Values (French)',
                        type:'textarea',
                        model:'brandValues_fr',
                    },
                ]
            },



            {
                label:'About Page (USA)',value:'home_en_usa',slug:'about',lang:'en_usa',
                fields:[
                    {
                        label:'Quality Standards (English(USA))',
                        type:'textarea',
                        model:'qualityStandards_en_usa',
                    },
                    {
                        label:'Environmental Responsibility (English(USA))',
                        type:'textarea',
                        model:'environment_en_usa',
                    },
                    {
                        label:'Our History (English(USA))',
                        type:'textarea',
                        model:'history_en_usa',
                    },
                    {
                        label:'Our Mission (English(USA))',
                        type:'textarea',
                        model:'mission_en_usa',
                    },
                    {
                        label:'Our Vision (English(USA))',
                        type:'textarea',
                        model:'vision_en_usa',
                    },
                    {
                        label:'Brand Values (English(USA))',
                        type:'textarea',
                        model:'brandValues_en_usa',
                    },
                ]
            },











        ],
        pageFields:{
            home:{
                url:'/home-page',
                fields:{
                    whatWeOffer:'',
                    whatWeOffer_fr:'',

                    whatWeOffer_en_usa: '',
                },
                
            },
            about:{
                url:'/aboutus',
                fields:{
                    qualityStandards:'',
                    qualityStandards_fr:'',

                    qualityStandards_en_usa:'',



                    environment:'',
                    environment_fr:'',

                    environment_en_usa:'',


                    history:'',
                    history_fr:'',

                    history_en_usa:'',

                    mission:'',
                    mission_fr:'',

                    mission_en_usa:'',




                    vision:'',
                    vision_fr:'',

                    vision_en_usa:'',



                    brandValues:'',
                    brandValues_fr:'',

                    brandValues_en_usa:'',



                },
            },
        },
        
    })),
    methods:{
        async updateFields(section){
            let {fields:sectionFields,slug} = section
            let page = this.pageFields[slug]
            if (!page) return 

            let pageFields = page.fields

            let updateObj = {}
            sectionFields.forEach(secField => {
                if (pageFields.hasOwnProperty(secField.model)){
                    updateObj[secField.model] = pageFields[secField.model]
                }
            })
            // console.log('UPDATEOBJ url => ' + page.url, updateObj)
            
            if (!page.url || !page.url.trim()) return 

            this.$store.commit('LOAD', {is:true})
            await this.$api.put(page.url, updateObj)
            this.$store.commit('LOAD', {is:false})
        }
    },
    async created(){
        this.$store.commit('LOAD', {is:true})

        let {data:home} = await this.$api.get('/home-page')
        let {whatWeOffer = '',whatWeOffer_fr = '',whatWeOffer_en_usa = ''} = home
        this.pageFields.home.fields = {
            whatWeOffer,whatWeOffer_fr,whatWeOffer_en_usa
        }

        let {data:about} = await this.$api.get('/aboutus')
        let {brandValues,
            brandValues_fr,

            brandValues_en_usa,

            environment,
            environment_fr,

            environment_en_usa,


            history,
            history_fr,

            history_en_usa,


            mission,
            mission_fr,

            mission_en_usa,


            qualityStandards,
            qualityStandards_fr,

            qualityStandards_en_usa,


            vision,
            vision_en_usa,
            vision_fr} = about
            
            
            
        this.pageFields.about.fields = {
            brandValues,
            brandValues_fr,

            brandValues_en_usa,


            environment,
            environment_fr,

            environment_en_usa,



            history,
            history_fr,

            history_en_usa,

            mission,
            mission_fr,

            mission_en_usa,


            qualityStandards,
            qualityStandards_fr,

            qualityStandards_en_usa,


            vision,
            vision_fr,

            vision_en_usa

        }
       

        this.$store.commit('LOAD', {is:false})
    }

}
</script>

<style>
    /* Tab content - closed */
    
</style>