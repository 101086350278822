<template>
    <div class="mb-10">
        <PageTitle>
            <div class="flex justify-between">
                <div>
                    Products
                </div>
                <div class="flex justify-end flex-basis-fix">
                    <Button label="Edit Products" size="small" :icon="editProductsSeen ? 'remove' : 'plus'" style="height:30px;" @click="editProducts" type="primary" />
                    <Button label="Add Product" size="small" icon="plus" style="height:30px;" to="/newproduct" type="primary" />
                </div>
            </div>
        </PageTitle>

        <div class="container px-4 mx-auto">

            <table class="table-auto w-full table-styled" v-if="items.length">
                <thead>
                    <tr>
                        <th class="bg-green-50" v-if="editProductsSeen">ORDER</th>
                        <th class="bg-green-50">PRODUCT</th>
                        <th class="bg-green-50">CATEGORY</th>
                        <th class="bg-green-50">SELLING PRICE</th>
                        <th class="bg-green-50">SKU</th>
                        <th class="bg-green-50">Duplicate</th>
                    </tr>
                </thead>

                <draggable @update="log" tag="tbody" class="td-centers" handle=".handle" v-model="items" >
                    <tr v-for="prd in queriedResults" :key="prd.id">

                        <td v-if="editProductsSeen"> 
                            <i class="fa fa-align-justify handle">
                                <img src="@/assets/img/move.svg" alt=" ">
                            </i>
                        </td>

                        <td class="flex items-center">
                            <div class="flex-1 px-4 flex items-center">
                                <img :src="getImage(prd)" class="w-12 h-12 md:w-32 md:h-32 object-cover" />
                                <div class="ml-4 text-left">
                                    <router-link :to="`/products/${prd.id}`" class="text-gray-600 font-bold hover:text-green-600 text-green-500">
                                        {{prd.name}} <em class="text-gray-600 font-normal" v-if="prd.memo?.trim()">({{prd.memo}})</em>
                                    </router-link>
                                    <small class="block text-sm text-gray-500">
                                        {{prd.description.substr(0,100)}}...
                                    </small>
                                </div>
                            </div>
                        </td>
                        <td class="text-left" style="text-align:left; padding:0 1rem;">{{prd.categories[0].name}}</td>
                        <td>{{formatPrice(prd.price)}}</td>
                        <td style="padding:0 1rem">{{prd.sku}}</td>
                        <td style="padding:0 1rem">
                            <span class="p-2 bg-green-200 inline-block rounded-full text-green-800 hover:bg-green-100 cursor-pointer" @click="duplicate(prd)">
                                <Icons i="duplicate" class="w-4 h-4" />
                            </span>
                        </td>
                    </tr>
                </draggable>

                <!-- <tbody class="td-centers">
                    <tr v-for="prd in queriedResults" :key="prd.id">

                        <td v-if="editProductsSeen"> 
                            <i class="fa fa-align-justify handle">
                                <img src="@/assets/img/move.svg" alt=" ">
                            </i>
                        </td>

                        <td class="flex items-center">
                            <div class="flex-1 px-4 flex items-center">
                                <img :src="getImage(prd)" class="w-12 h-12 md:w-32 md:h-32 object-cover" />
                                <div class="ml-4 text-left">
                                    <router-link :to="`/products/${prd.id}`" class="text-gray-600 font-bold hover:text-green-600 text-green-500">
                                        {{prd.name}} <em class="text-gray-600 font-normal" v-if="prd.memo?.trim()">({{prd.memo}})</em>
                                    </router-link>
                                    <small class="block text-sm text-gray-500">
                                        {{prd.description.substr(0,100)}}...
                                    </small>
                                </div>
                            </div>
                        </td>
                        <td class="text-left" style="text-align:left; padding:0 1rem;">{{prd.categories[0].name}}</td>
                        <td>{{formatPrice(prd.price)}}</td>
                        <td style="padding:0 1rem">{{prd.sku}}</td>
                        <td style="padding:0 1rem">
                            <span class="p-2 bg-green-200 inline-block rounded-full text-green-800 hover:bg-green-100 cursor-pointer" @click="duplicate(prd)">
                                <Icons i="duplicate" class="w-4 h-4" />
                            </span>
                        </td>
                    </tr>
                </tbody> -->
                
            </table>
            <div class="w-full flex flex-col items-center justify-center py-7 text-gray-300" v-if="!items.length">
                <Icons i="sadface" class="w-16" />
                <p class="text-gray-500 mt-3">
                    Sorry, No products were added. Please click Add to get started.
                </p>
            </div>

            <div class="flex flex-basis-fix">
                <Button label="Save Order" size="small" style="height:30px;" @click="saveOrder" type="primary"  v-if="editProductsSeen" class="mt-5"/>
                <Button label="Cancel Order" size="small" style="height:30px;" @click="cancelOrder" type="primary"  v-if="editProductsSeen" class="mt-5"/>
            </div>
            
        </div>
        
    </div>
</template>

<script>
import PageTitle from '@/components/PageTitle'
import Icons from '@/components/Icons'
import Button from '@/components/Button'
import FormInput from '@/components/Forms/FormInput'
import {formatPrice,formatDate,baseURL} from '@/assets/helper'
import Datepicker from 'vue3-datepicker'
import $qs from 'qs-stringify'
import { VueDraggableNext } from 'vue-draggable-next'

export default {
    components:{
        PageTitle,
        FormInput,
        Datepicker,
        Icons,
        Button,
        draggable: VueDraggableNext,
    },
    data:( () => ({
        query:'',
        items:[],
        filtered:[],
        editProductsSeen: false,
        order:[]
    })),
    watch:{
        async query(val){
            if (val.length < 3) return this.filtered = []
            let query = $qs({ _where: { _or: [{ name_contains: val }, { phone_contains: val }, { email_contains: val }] } })
            let {data} = await this.$api.get(`/stores/autocomplete?${query}`)
            if (data.length) this.filtered = data
        }
    },
    methods:{
        duplicateConfirm(prd){
            // console.log('DUPLIVCAET', prd)
            delete prd.id
            delete prd._id
            let {updated_by,updatedAt,createdAt,_id,id,published_at,memo,created_by,...product} = prd 
            product = JSON.parse(JSON.stringify(product))
            delete product.image
            product.memo = 'Duplicated'
            product.categories = product.categories.map(x => x.id)
            product.slug = product.slug + '-copy'
            this.$store.commit('LOAD', {is:true})
            this.$api.post('/products', product).then( ({data}) => {
                this.$router.push(`/products/${data.id}`)
            }).catch( () => {
                this.$store.commit('SET_MODAL',{
                    danger:true,
                    confirm:false,
                    header:'Uh oh',
                    body:`There was an error duplicating. Please try again`
                })
            }).finally( () => {
                this.$store.commit('LOAD', {is:false})
            })

        },
        duplicate(prd){
            this.$store.commit('SET_MODAL',{
                callback: () => this.duplicateConfirm(prd),
                danger:false,
                confirm:true,
                header:'Duplicate',
                body:`You are about to duplicate ${prd.name} ${prd.memo?.trim()? `(${prd.memo})` : ''}`
            })
        },
        getImage(prd){
            if (prd.imageObj?.url) return `${baseURL}${prd.imageObj.url}`
            return ''
        },
        formatPrice,
        formatDate,
        log(event) {
            // console.log('changed order');
        },
        editProducts() {
            this.editProductsSeen = !this.editProductsSeen;
        },
        saveOrder(){
            let sendObj = JSON.parse(JSON.stringify(this.items))
            let changeorder = sendObj.map(el => {
                return el.id
            })

            this.$api.put(`/products/changeorder`, changeorder)
                .then( () => {
                    this.$store.commit('SET_MODAL',{
                        callback: () => this.$router.push(`/products`),
                        danger:false,
                        confirm:false,
                        header:'Success',
                        body:`You have successfully updated Products.`
                    }),
                    this.editOrderSeen = false
                }).catch( () => {
                    this.$store.commit('SET_MODAL',{
                        danger:true,
                        confirm:false,
                        header:'Error',
                        body:`An error Occurred while updating. Please try again`
                    })
                }).finally( () => {
                    this.$store.commit('LOAD',{is:false})
                })

        },
        cancelOrder() {
            this.items = this.order
        }
    },
    computed:{
        queriedResults(){
            let result = this.items
            if (this.query.length > 2) result = this.filtered
            return result.map(x => {
                x.imageObj = x.image[0]
                return x
                
            })
        },
        disabledDateFrom(){

        }
    },
    async created(){
        this.$store.commit('LOAD', {is:true})
        let {data} = await this.$api.get('/products')
        // this.items = data
        this.items = data.sort((a, b) => { return a.order - b.order });
        this.order = data.sort((a, b) => { return a.order - b.order });
        this.$store.commit('LOAD', {is:false})
    }
}
</script>

<style scoped>
 .flex-basis-fix>a, .flex-basis-fix>button{
    flex-basis: auto !important;
}
i.handle{
    cursor:pointer;
    display: inline-block;
}
</style>