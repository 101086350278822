<template>
   
    <transition name="modal">
        <div class="modal-wrapper-outer">
            <div class="modal-mask" @click="close('mask')" :style="`z-index:${z};`">
            </div>
            <div class="modal-wrapper pointer-events-none">
                <div class="pointer-events-all-all inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                    <div class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div class="sm:flex sm:items-start w-full">
                            <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10 modal-icon-holder" :class="iconClasses" v-if="icon !== false">
                                <slot name="icon">
                                    
                                    <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true" v-if="icon === 'danger'">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path>
                                    </svg>
                                    <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" v-else-if="icon === 'cart'">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" v-else-if="icon === 'check'">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                                    </svg>
                                    <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" v-else>
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                </slot>
                                
                            </div>
                            <div class="mt-3 text-center sm:mt-0 sm:mx-4 sm:text-left flex-1">

                                <div class="text-lg leading-6 font-medium text-gray-900 flex justify-between pb-3 w-full" id="modal-headline" style="border-bottom:1px solid #aaa;">
                                    <slot name="header">
                                        {{header}}
                                    </slot>
                                    <slot name="topbar">
                                    </slot>
                                </div>

                                <div class="mt-2">
                                    <slot name="body">
                                        <p class="text-sm text-gray-500">
                                            {{body}}
                                        </p>
                                    </slot>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse rounded-b-lg">
                            <button @click="ok" type="button" :class="okClass" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2  text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
                                {{okText}}
                            </button>
                            <template v-if="confirm">
                                <button @click="cancel" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                                    {{cancelText}}
                                </button>
                            </template>
                        
                    </div>
                </div>
                <div class="modal-container" v-if="false">

                    <div class="modal-header">
                        
                    </div>

                    <div class="modal-body">
                        
                    </div>

                    <div class="modal-footer">
                        <slot name="footer">
                        default footer
                        <button class="modal-default-button" @click="$emit('close')">
                            OK
                        </button>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    props:{
        z:{
            type:Number,
            default:999,
        },
        icon:{
            type:[String,Boolean],
            default:'info'
        },
        maskClosable:{
            type:Boolean,
            default:true
        },
        confirm:{
            type:Boolean,
            default:false,
        },
        okText:{
            type:String,
            default:'Okay',
        },
        okClass:{
            type:String,
            default:'bg-green-500 text-white  hover:bg-green-700'
        },  
        body:{
            type:String,
            default:'Body here...'
        },
        header:{
            type:String,
            default:'Header'
        },
        cancelText:{
            type:String,
            default:'Cancel',
        },
        iconClasses:{
            type:String,
            default:'bg-green-100 text-green-500'
        },
        okClose:{
            type:Boolean,
            default:true
        }
    },
    data:( () => ({
        icons:{
            danger:1,
            success:1,
            cart:1,
        }
    })),
    methods:{
        cancel(){
            if (this.confirm){
                this.$emit('cancel')
            } 

            this.$emit('close')
        },
        ok(){
            
            this.$emit('ok')
            if (this.confirm) {
                if (this.okClose) this.$emit('close')
                return
            }

            this.$emit('close')
            
        },
        close(type){
            if (!type) {
                // console.log('CLOSING', type,this.maskClosable)
                return this.$emit('close')
            }
            else if (type === 'mask' && this.maskClosable){
                if (!this.confirm) this.$emit('ok')
                return this.$emit('close')
            }

        }
    }
}
</script>

<style>

    .modal-mask {
        position: fixed;
        z-index: 999;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: table;
        transition: opacity 0.3s ease;
    }

    .modal-wrapper {
        position:fixed;
        left:0;
        top:0;
        right:0;
        bottom:0;
    }
    .modal-wrapper {
   
        position:fixed;
        left:0;
        right:0;
        bottom:0;
        top:0;
        display:flex;
        place-items: center;
        place-content: center;
        z-index:1000;
    }

    .modal-container {
        width: 300px;
        margin: 0px auto;
        padding: 20px 30px;
        background-color: #fff;
        border-radius: 2px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
        transition: all 0.3s ease;
    }

    .modal-header h3 {
        margin-top: 0;
        color: #42b983;
    }

    .modal-body {
        margin: 20px 0;
    }

    .modal-default-button {
        display: block;
        margin-top: 1rem;
    }

    .modal-enter {
        opacity: 0;
    }

    .modal-leave-active {
        opacity: 0;
    }

    .modal-enter .modal-container,
    .modal-leave-active .modal-container {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
    .modal-icon-holder svg{
        width:1.5rem;
        height:1.5rem;
    }

</style>