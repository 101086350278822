<template>
    <div class="">
        <div class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
            <input :checked="modelValue" @change="$emit('update:modelValue', $event.target.checked)" type="checkbox" name="toggle" :id="key" class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"/>
            <label :for="key" class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"></label>
        </div>
        <label :for="key" class="text-xs" :class="modelValue ? 'text-green-700' : 'text-red-600'">{{modelValue? activeText : inactiveText}}</label>
    </div>
</template>

<script>
import { nanoid } from 'nanoid'
import Icons from '@/components/Icons'
export default {
    components:{Icons},
    props:{
        modelValue:{
            type:Boolean,
            required:true
        },
        activeText:{
            type:String,
            default:'Active',
        },
        inactiveText:{
            type:String,
            default:'Inactive',
        },
    },
    data:( () => ({
        key:`w-`+Date.now()
    })),
    created(){
        let rand = Math.floor(Math.random() * 100)
        rand = Date.now() + rand
        this.key = `w-`+ rand
    }

  

}
</script>
<style>
    .toggle-checkbox:checked {
        @apply: right-0 border-green-400;
        right: 0;
        border-color: #68D391;
    }
    .toggle-checkbox:checked + .toggle-label {
        @apply: bg-green-400;
        background-color: #68D391;
    }
</style>
