<template>
    <table class="table-auto w-full table-styled">
        <thead>
            <tr>
                <th class="bg-green-50">Blog Name</th>
                <th class="bg-green-50">
                    <a href="#" class="flex justify-center items-center cursor-pointer sort-by relative" @click.prevent="sortBy('createdAt')">
                        <span class="mr-2">Published Date</span>
                    </a>
                </th>
                <th class="bg-green-50">&nbsp;</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="blog in blogs" :key="blog.id">
                <td style="padding-left:.5rem">
                    <router-link :to="`/cms/blog/${blog.slug}`" class="text-green-600 hover:text-green-700">{{blog.title}}</router-link>
                </td>
                <td>{{formatDate(blog.date)}}</td>
                <td>
                    <router-link :to="`/cms/blog/${blog.slug}`" class="text-green-600 hover:text-green-700">
                        <Icons i="pen" class="w-4 h-4" />
                    </router-link>
                </td>
            </tr>
            
        </tbody>
    </table>
    <div class="w-full flex flex-col items-center justify-center py-7 text-gray-300" v-if="!blogs.length">
        <Icons i="sadface" class="w-16" />
        <p class="text-gray-500 mt-3">
            You have no Blogs added for {{langName}}
        </p>
    </div>
    <div class="my-10 w-full text-center justify-end flex space-x-4">
        <Button label="New Blog" to="/cms/newblog" type="primary" icon="plus" class="min-w-max" />
    </div>
</template>

<script>
import {formatDate} from '@/assets/helper'
import Icons from '@/components/Icons'
import Button from '@/components/Button'
export default {
    components:{
        Icons,
        Button,
    },
    props:{
        lang:{
            type:String,
            default:'en'
        }
    },
    data:( () => ({
        blogs_en:[],
        blogs_fr:[],

        blogs_en_usa:[]

    })),
    methods:{
        formatDate
    },
    computed:{
        langName(){
            return this.lang === 'en' ? 'English' : this.lang === 'en_usa' ? 'English(USA)' : 'French'
        },
        blogs(){
            return this.$data[`blogs_${this.lang}`].map(x => {
                x.title = x.title.substr(0,50) + '...'
                return x
            })
        }
    },
    async created(){
        this.$store.commit('LOAD', {is:true})

        let {data:blogs} = await this.$api.get('/blogs?_limit=-1')

        let en = []
        let fr = []

        let en_usa = []

        blogs.forEach(x => {
            if (x.language === 'en') en.push(x)
            if (x.language === 'fr') fr.push(x)
            if (x.language === 'en_usa') en_usa.push(x)

        })
        this.blogs_en =en 
        this.blogs_fr = fr

        this.blogs_en_usa = en_usa


        // console.log('asdas',en)
        this.$store.commit('LOAD', {is:false})
    }
}
</script>

<style>

</style>