<template>
    <div class="flex bg-grey-lighter" :class="disabled ? 'opacity-30 cursor-not-allowed pointer-events-none' : ''">
        <label class="w-64 flex flex-col justify-center items-center px-2 py-4 bg-white text-blue rounded-lg shadow-lg tracking-wide border border-blue cursor-pointer hover:bg-green-100">
            <svg class="w-5 h-5" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
            </svg>
            <span class="mt-2 text-base leading-normal">{{label}}</span>
            <input accept="text/csv" type='file' class="hidden" id="file" ref="file" v-on:change="readURL" v-if="image"/>
            <input type='file' class="hidden" id="file" ref="file" v-on:change="readURL" v-else/>
        </label>
    </div>
</template>

<script>
export default {
    props:{
        image:{
            type:Boolean,
            default:false,
        },
        label:{
            type:String,
            default:'Add an image',
        },
        disabled:{
            type:Boolean,
            default:false,
        },
        modelValue:{
            type:Array,
            default:''
        },
        showErrors:{
            type:Boolean,
            default:false,
        },
        partnerSelected:{
            type:String,
            default:'',
        }
    },
    data:( () => ({
        img:'',
    })),
    watch:{
        img(val){
            this.$emit('update:modelValue',val)
        }
    },
    methods:{
        handleUpload(file){
                
                const throwErr = (body = err) => {
                    this.$modal({
                        danger:true,
                        confirm:false,
                        header:'Uh oh',
                        body
                    })
                }

                const formData = new FormData();
                const partner = this.partnerSelected;

                formData.append('files', file, file.name);
                formData.append('partner', partner );
                
                this.$api.post('/orders/upload',formData).then( ({data}) => {
                       this.$store.commit('SET_MODAL',{
                            callback: () => this.$router.push('/'),
                            danger:false,
                            okText:'Okay',
                            confirm:false,
                            header:'Great!',
                            body:`The EDI file is uploaded.`
                        })
                })
                .catch( err => {
                    throwErr(err.response.data.message)
                })
                .finally( () => {
                    this.$store.commit('LOAD', {is:false})
                })
                
        },
        readURL(input) {
            if (input?.target?.files?.[0]) {
                let file = input.target.files[0]
                var reader = new FileReader();
                
                reader.onload = (e) => {
                    let url = e.target.result
                    // console.log('FILEEEEEEE", file', file)
                    this.handleUpload(file)
                    // this.$emit('uploaded',{id:Date.now(),file,url})
                }
                
                reader.readAsDataURL(input.target.files[0]); 
            }
        },
    },
    mounted(){
       
    }
}
</script>

<style scoped>

</style>