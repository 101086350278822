<template>
    <div class="mb-5 mt-s-lg container px-4 mx-auto" :style="border? `border-bottom:1px solid rgb(107, 114, 128)` : ''">
        <h2 class="page-header-font text-gray-500 font-bold text-xl pb-3">
            <slot />
        </h2>
    </div>
</template>
<script>
export default {
    props:{
        border:{
            default:true,
            type:Boolean,
        }
    }
}
</script>
<style >
    h2.page-header-font{
        font-size:clamp(25px, 3vw,);
    }
</style>