<template>
  <div>
    <PageTitle>
        <div class="flex justify-between">
            <div>
                EDI UPLOAD
            </div>
        </div>
    </PageTitle>
   
    <!-- map -->
    <div class="steps-wrapper flex gap-5 px-4 container mx-auto mb-10 pb-3 pt-10 pointer-events-none">
        <div class="step" :class="step === 0 ? 'active' : ''">
            <div class="step-label">Step 1</div>
            <span>Select the Partner</span>
        </div>
        <div class="step arrow" :class="step === 0 ? 'active text-gray-500' : ''">
            <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" style="width:50px;">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
            </svg>
        </div>
        <div class="step" :class="step === 1 ? 'active' : ''">
            <div class="step-label">Step 2</div>
            <span>Upload EDI</span>
        </div>
        <!-- 
        <div class="step arrow"  :class="step === 1 ? 'active text-gray-500' : ''">
            <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" style="width:50px;">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
            </svg>
        </div>
        <div class="step"  :class="step === 2 ? 'active' : ''">
            <div class="step-label">Step 3</div>
            <span>Ready To Import</span>
        </div> 
        -->
    </div>

    <div class="container mx-auto px-4">
        <!-- first step -->
        <template v-if="step === 0">
            <form style="max-width:900px;">
                <div class="grid grid-cols-6 gap-6">
                    <FormSelect :show-errors="showErrors" class="col-span-6 sm:col-span-3" label="Choose Partner" v-model="partner" :options="partners"/>
                </div>
            </form>
        </template>

        <!-- second step -->
        <template v-else-if="step === 1">
                <h2>Selected Partner: <span class="text-green font-bold">{{this.partner}}</span></h2><br />
                <h2>Selected File: <span class="text-green font-bold">{{this.uploadEDI}}</span></h2><br />
                <EdiSelector v-model="uploadEDI" :removable="true" :url="true" label="Import File" :partner="this.partner" />
        </template>

        <!-- third step -->
        <!-- <template v-else-if="step === 2">
             <h2>Selected Partner: <span class="text-green font-bold">{{this.partner}}</span></h2><br />
             <h2>Selected File: <span class="text-green font-bold">{{this.uploadEDI}}</span></h2><br />
        </template> -->

        <div class="flex justify-start my-10 space-x-3">
            <Button to="/" label="Cancel" type="secondary" class="order-2 sm:order-1 " v-if="step === 0" />
            <Button label="Back" type="secondary" class="order-2 sm:order-1 " v-if="step === 1 || step === 2" @click="back" />
           
            <Button label="Next" type="primary" class="sm:order-2 min-w-max " @click="next" v-if="step !== 1"/>
            <!-- <Button label="Import" type="primary" class="sm:order-2 min-w-max " @click="importandmerge" v-if="step === 2"/> -->
        </div>
       
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle";
import Button from "@/components/Button";
import FormSelect from "@/components/Forms/FormSelect";
import Icons from '@/components/Icons'
import EdiSelector from '@/components/Forms/EdiSelector'

export default {
    name: "Home",
    components: {
        PageTitle,
        FormSelect,
        Button,
        Icons,
        EdiSelector
    },
    data:( () => ({
        partners:[],
        partner:'',
        maxStep:2,
        step:0,
        showErrors:false,

        uploadEDI: '',
    })),
    computed:{
        
    },
    methods:{
        back(){
            if (this.step === 0) return this.$router.push('/')
            else this.step--
        },
        async isStep0Valid(){
            let valid = true
            if(this.partner.length == 0 ) valid = false
            if (!valid) return false
            else return valid
        },
        async isStep1Valid(){
            let valid = true
            if (this.uploadEDI.length == 0) valid = false
            if (!valid) return false
            else return valid
        },
        async isStep2Valid(){
         
        },
        gotoNextStep(){
            // console.log('GOING TO NEXT STEP')
            if (this.step === this.maxStep) return this.finalize() 
            this.step++
        },
        async next(){
            // console.log('GOING NEXT',this[`isStep${this.step}Valid`])
            if (this[`isStep${this.step}Valid`] && await this[`isStep${this.step}Valid`]()) {
                // console.log('VALID',this[`isStep${this.step}Valid`]())
                return this.gotoNextStep()
            }
            return this.showErrors = true
        },
    },
    watch:{
      
    },
    created(){
        if (localStorage.dev) this.dev = 1
        this.$api.get('/partners').then( ({data}) => {
            // data = data.map( ({code,name}) => ({label:name,value:code}))
            // this.partners = [
            //     {label:'None',value:''},
            //     ...data
            // ]
            this.partners = [
                {label:'None',value:''},
                {label:'Amazon',value:'Amazon'},
                {label:'Costco',value:'Costco'},
                {label:'Walmart',value:'Walmart'}
            ]
        })

  
    },
}
</script>

<style lang="scss">
    .choose-payment > label:not(.active){
        filter:grayscale(80);
        opacity:0.3;
    }
    .home-page-grid {
        margin-top:3vw;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap:5vw;
        @media screen and (min-width: 968px) {
            grid-template-columns: repeat(4, 1fr);
        }
    }
    .step:not(.active){
        color: rgb(229, 231, 235);
    }
    .dash-item{
        cursor:pointer;
        &:before{
          content:'';
        }
        border:1px solid var(--green);
        min-height:200px;
        font-weight:bold;
        svg{
            margin:0 auto 5px;
            width:40%;
        }
        &:hover{
          background:#fffbdb;
        }
    }
    .product-table-row{
        > .product-row-item:not(:first-child){
            border-left:1px solid #eee;
        }
    }
</style>