<template>
  <div>
    <PageTitle>
      <div class="flex justify-between">
        <div>Dealer View Orders</div>
      </div>
    </PageTitle>

    <div class="container px-4 mx-auto">
      <div class="grid grid-cols-6 gap-x-6 gap-y-2 mt-3 mb-5">
        <div class="col-span-1">
          <label class="block text-sm font-medium text-gray-700"
            >Start Date
            <span
              class="text-blue-400 text-xs ml-1 cursor-pointer hover:text-blue-500"
              v-if="filter.from"
              @click="filter.from = null"
              >clear</span
            ></label
          >
          <Datepicker
            class="mt-1 w-full ring-green-500 block shadow-sm sm:text-sm border-gray-300 rounded-md"
            v-model="filter.from"
            :upper-limit="filter.to"
          />
        </div>
        <div class="col-span-1">
          <label class="block text-sm font-medium text-gray-700"
            >End Date
            <span
              class="text-blue-400 text-xs ml-1 cursor-pointer hover:text-blue-500"
              v-if="filter.to"
              @click="filter.to = null"
              >clear</span
            ></label
          >
          <Datepicker
            class="mt-1 w-full ring-green-500 block shadow-sm sm:text-sm border-gray-300 rounded-md"
            v-model="filter.to"
            :lower-limit="filter.from"
          />
        </div>

        <div class="col-span-2">
          <FormInput label="Order #" v-model="filter['orderId_contains']" />
        </div>

        <div class="col-span-2 relative" v-click-outside="hideResults">
          <template v-if="filter.dealer">
            <label class="block text-sm font-medium text-gray-700"
              >Selected Dealer</label
            >
            <div
              class="mt-1 py-2 px-5 bg-gray-100 inline-flex items-center rounded-md"
            >
              {{ chosenDealer.name }}
              <div
                class="ml-3 cursor-pointer hover:bg-gray-300 w-5 h-5 bg-gray-400 rounded-full text-white flex items-center justify-center"
                @click="revertDealer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  class="w-3 h-3"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>
            </div>
          </template>
        </div>

        <template v-if="advanced">
          <div class="col-span-1">
            <FormInput
              v-model="filter['customer.firstName_contains']"
              label="Customer Firstname"
            />
          </div>
          <div class="col-span-1">
            <FormInput
              v-model="filter['customer.lastName_contains']"
              label="Customer Lastname"
            />
          </div>
          <div class="col-span-2">
            <FormInput
              v-model="filter['customer.email_contains']"
              label="Customer Email"
            />
          </div>
          <div class="col-span-2">
            <FormInput
              v-model="filter['customer.phone_contains']"
              label="Customer Phone"
            />
          </div>
          <div class="col-span-2">
            <!-- ,{label:'All Partners',value:'all'},{label:'No Partner',value:'none'}, -->
            <FormSelect
              label="Partner"
              :options="[
                { label: 'N/A', value: '' },
                { label: 'Walmart', value: 'walcan' },
                { label: 'Costco', value: 'costco' },
                { label: 'Amazon', value: 'amazon' },
              ]"
              v-model="filter['partner.code']"
            />
          </div>
          <div class="col-span-2">
            <FormSelect
              label="Order Status"
              :options="statusOptions"
              v-model="filter.status"
            />
          </div>

          <div class="col-span-2">
            <FormInput
              label="Customer Address"
              v-model="filter['customer.address_contains']"
            />
          </div>
        </template>
      </div>
      <div class="flex my-4 justify-between">
        <div class="flex space-x-4">
          <!-- <a href="#" class="border-2  px-3 py-2 rounded-md" :class="advanced ? 'border-gray-600 text-gray-600' : 'border-green-800 text-green-800'" @click.prevent="advanced=!advanced">{{advanced? 'HIDE ADVANCED SEARCH' : 'SHOW ADVANCED SEARCH'}}</a> -->
          <a
            href="#"
            class="border-2 border-red-400 text-red-400 px-3 py-2 rounded-md"
            @click.prevent="clear"
            v-if="searchMode"
            >CLEAR SEARCH</a
          >
        </div>

        <div class="flex space-x-4">
          <a
            href="#"
            class="bg-green-600 text-white px-5 py-2 rounded-md"
            @click.prevent="exportData"
            v-if="searchMode"
            >EXPORT DATA</a
          >
          <a
            href="#"
            class="bg-green-600 text-white px-5 py-2 rounded-md"
            @click.prevent="(e) => getFilteredOrders()"
            >SEARCH</a
          >
        </div>
      </div>
      <hr class="my-4" />
      <div class="flex justify-between my-4 items-center">
        Found {{ searchMode ? filterTotal : orderTotal }} Orders
        <div class="flex justify-end items-center space-x-4">
          <Icons
            i="arrow-left"
            class="w-4 h-4 cursor-pointer hover:text-green-600"
            :class="page <= 1 ? 'text-gray-300 pointer-events-none' : ''"
            @click="prevPage"
          />
          <!-- <span>Page {{page}} / {{maxPages}}</span> -->

          <div class="myPagination">
            <span
              @click="clickPagination"
              v-if="page - 2 != 0 && page - 2 > 0 && page - 2 != 1"
            >
              1
            </span>
            <span
              class="paginationDot"
              v-if="
                page - 2 != 0 && page - 2 > 0 && page - 2 != 1 && page - 2 != 2
              "
            >
              ...
            </span>
            <span @click="clickPagination" v-if="page - 2 != 0 && page - 2 > 0">
              {{ page - 2 }}
            </span>
            <span
              @click="clickPagination"
              v-if="page - 1 != 0 && page - 2 >= 0"
            >
              {{ page - 1 }}
            </span>
            <span @click="clickPagination" class="activePagination">
              {{ page }}
            </span>
            <span @click="clickPagination" v-if="page != maxPages">
              {{ page + 1 }}
            </span>
            <span
              @click="clickPagination"
              v-if="page + 1 != maxPages && page != maxPages"
            >
              {{ page + 2 }}
            </span>
            <span
              class="paginationDot"
              v-if="
                page + 2 != maxPages &&
                page != maxPages &&
                page + 1 != maxPages &&
                page + 3 != maxPages
              "
            >
              ...
            </span>
            <span
              @click="clickPagination"
              v-if="
                page + 2 != maxPages && page != maxPages && page + 1 != maxPages
              "
            >
              {{ maxPages }}
            </span>
          </div>

          <Icons
            i="arrow-left"
            class="w-4 h-4 rotate-180 cursor-pointer hover:text-green-600"
            :class="page >= maxPages ? 'text-gray-300 pointer-events-none' : ''"
            @click="nextPage"
          />
        </div>
      </div>
      <table class="table-auto w-full table-styled">
        <thead>
          <tr>
            <th class="bg-green-50">ORDER #</th>

            <th class="bg-green-50">STATUS</th>

            <th class="bg-green-50">TYPE</th>

            <th class="bg-green-50">Transaction #</th>
            <th class="bg-green-50">
              <a
                href="#"
                class="flex justify-center items-center cursor-pointer sort-by relative"
                :class="sort.createdAt"
                @click.prevent="sortBy('createdAt')"
              >
                <span class="mr-2">ORDER DATE</span>
              </a>
            </th>
            <th class="w-36 bg-green-50">
              <a
                href="#"
                class="flex justify-center items-center cursor-pointer sort-by relative"
                :class="sort.deliveryDate"
                @click.prevent="sortBy('deliveryDate')"
              >
                <span class="mr-2">DELIVERY DATE</span>
              </a>
            </th>

            <!-- <th class="bg-green-50">CUSTOMER</th> -->

            <th class="bg-green-50">First Name</th>
            <th class="bg-green-50">Last Name</th>

            <th class="bg-green-50">
              <a
                href="#"
                class="flex justify-center items-center cursor-pointer sort-by relative"
                :class="sort['store.name']"
                @click.prevent="sortBy('store.name')"
              >
                <span class="mr-2">DEALER</span>
              </a>
            </th>
            <th class="bg-green-50">CITY</th>

            <th class="bg-green-50">Address</th>

            <th class="bg-green-50">AMOUNT</th>
            <th class="bg-green-50">NAV</th>
          </tr>
        </thead>
        <tbody class="td-centers">
          <tr v-for="ord in queriedResults" :key="ord.id">
            <td>
              <span
                v-if="ord.partner?.name"
                class="px-2 py-1 bg-blue-100 text-blue-400 text-xs rounded-lg"
                >{{ ord.partner.name[0] }}</span
              >
              <router-link
                :to="`/orders/${ord.id}`"
                class="text-green-600 hover:text-green-800 font-semibold"
                >{{ ord.orderId }}</router-link
              >
            </td>

            <td>{{ ord.status }}</td>

            <td>
              <span>
                {{ typeOfProduct(ord.orderItems) }}
              </span>
            </td>

            <td>
              {{
                ord && ord.paymentResult && ord.paymentResult.id
                  ? ord.paymentResult.id
                  : ""
              }}
            </td>
            <td>{{ formatDate(ord.createdAt) }}</td>
            <td>{{ formatDate(ord.deliveryDate) }}</td>

            <!-- 
                        <td>
                            <span v-if="ord?.customer?.firstName">
                                {{ord.customer.firstName}} {{ord.customer.lastName? ord.customer.lastName[0] + '.' : ''}} 
                            </span>
                            <span v-else>
                                {{ord.customer.email || 'N/A'}} 
                            </span>
                        </td>
                        -->

            <td>{{ ord?.customer?.firstName }}</td>
            <td>{{ ord?.customer?.lastName }}</td>

            <td>
              <div class="flex justify-center">
                <span>{{ ord.store.name }}</span>
                <svg
                  v-if="false"
                  class="w-5 h-5 hover:text-gray-700 text-gray-400 cursor-pointer ml-2"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  @click="chooseDealer(ord.store)"
                >
                  <path d="M9 9a2 2 0 114 0 2 2 0 01-4 0z" />
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a4 4 0 00-3.446 6.032l-2.261 2.26a1 1 0 101.414 1.415l2.261-2.261A4 4 0 1011 5z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
            </td>
            <td>{{ ord?.user?.city || ord?.customer?.city }}</td>

            <td>{{ ord.customer.address }}</td>

            <td>{{ formatPrice(ord.total) }}</td>
            <td>
              <div
                v-if="ord.mergedToNav"
                class="flex justify-center text-gray-400"
              >
                <Icons i="check" class="w-4 h-4" />
              </div>
              <button
                @click="mergeNav(ord)"
                v-else
                class="text-xs bg-green-600 text-white px-3 py-1 rounded-sm"
              >
                MERGE
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="flex justify-between mt-4 items-center">
        Found {{ searchMode ? filterTotal : orderTotal }} Orders
        <div class="flex justify-end items-center space-x-4">
          <Icons
            i="arrow-left"
            class="w-4 h-4 cursor-pointer hover:text-green-600"
            :class="page <= 1 ? 'text-gray-300 pointer-events-none' : ''"
            @click="prevPage"
          />
          <!-- <span>Page {{page}} / {{maxPages}}</span> -->

          <div class="myPagination">
            <span
              @click="clickPagination"
              v-if="page - 2 != 0 && page - 2 > 0 && page - 2 != 1"
            >
              1
            </span>
            <span
              class="paginationDot"
              v-if="
                page - 2 != 0 && page - 2 > 0 && page - 2 != 1 && page - 2 != 2
              "
            >
              ...
            </span>
            <span @click="clickPagination" v-if="page - 2 != 0 && page - 2 > 0">
              {{ page - 2 }}
            </span>
            <span
              @click="clickPagination"
              v-if="page - 1 != 0 && page - 2 >= 0"
            >
              {{ page - 1 }}
            </span>
            <span @click="clickPagination" class="activePagination">
              {{ page }}
            </span>
            <span @click="clickPagination" v-if="page != maxPages">
              {{ page + 1 }}
            </span>
            <span
              @click="clickPagination"
              v-if="page + 1 != maxPages && page != maxPages"
            >
              {{ page + 2 }}
            </span>
            <span
              class="paginationDot"
              v-if="
                page + 2 != maxPages &&
                page != maxPages &&
                page + 1 != maxPages &&
                page + 3 != maxPages
              "
            >
              ...
            </span>
            <span
              @click="clickPagination"
              v-if="
                page + 2 != maxPages && page != maxPages && page + 1 != maxPages
              "
            >
              {{ maxPages }}
            </span>
          </div>

          <Icons
            i="arrow-left"
            class="w-4 h-4 rotate-180 cursor-pointer hover:text-green-600"
            :class="page >= maxPages ? 'text-gray-300 pointer-events-none' : ''"
            @click="nextPage"
          />
        </div>
      </div>

      <div class="flex space-x-4 my-5">
        <a
          href="#"
          class="bg-green-600 text-white px-5 py-2 rounded-md"
          @click.prevent="(e) => getFilteredCancelledOrder()"
          >Cancelled Orders</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle";
import Icons from "@/components/Icons";
import AutoComplete from "@/components/Forms/AutoComplete";
import FormInput from "@/components/Forms/FormInput";
import FormSelect from "@/components/Forms/FormSelect";
import SortIcon from "@/components/SortIcon";
import { formatPrice, formatDate } from "@/assets/helper";
import Datepicker from "vue3-datepicker";

export default {
  components: {
    PageTitle,
    FormInput,
    Datepicker,
    AutoComplete,
    SortIcon,
    FormSelect,
    Icons,
  },
  data: () => ({
    orderTotal: 0,
    filterTotal: 0,
    searchMode: false,
    limit: 60,
    page: 1,
    advanced: true,
    queryString: "",
    queryStringArr: [],
    sortString: "",
    chosenDealer: {},
    orders: [],
    filterResults: [],
    firstTime: true,
    dealerSearch: {
      query: "",
      results: [],
    },
    statusOptions: [
      { label: "Any", value: "" },
      { label: "Pending", value: "pending" },
      { label: "Assigned", value: "assigned" },
      { label: "Completed", value: "completed" },
      // {label:'Cancelled',value:'cancelled'},
    ],
    sort: {
      "store.name": "",
      deliveryDate: "",
      createdAt: "DESC",
    },
    filter: {
      from: null,
      to: null,
      orderId_contains: "",
      dealer: "",
      status: "",
      "customer.firstName_contains": "",
      "customer.lastName_contains": "",
      "customer.email_contains": "",
      "customer.phone_contains": "",
      "customer.city_contains": "",
      "customer.address_contains": "",

      "customer.address": "",

      "partner.code": "",
      mergedToNav: "",
    },
  }),
  watch: {
    queryStringArr(val, oldVal) {
      let val1 = val.join(",");
      let oldVal1 = oldVal.join(",");
      // console.log("SAME AS BEFORE ????", val1 === oldVal1);
    },
    sort: {
      immediate: true,
      deep: true,
      handler(val) {
        this.sortToString();
      },
    },
    filter: {
      deep: true,
      handler() {
        this.filterTransform();
      },
    },
    advanced(val) {
      if (!val) {
        this.filter = {
          ...this.filter,
          dealer: "",
          status: "",
          "customer.firstName": "",
          "customer.lastName": "",
          "customer.email": "",
          "customer.phone": "",
          "customer.city": "",
        };
      }
    },
    async "dealerSearch.query"(val) {
      if (val.length < 3) return (this.dealerSearch.results = []);

      let { data } = await this.$api.get(
        `/stores/autocomplete?name_contains=${val}`
      );
      if (data.length) this.dealerSearch.results = data;
    },
    page(val, test) {
      // console.log(val, test, "pageWatch");
      this.$store.commit("savePage", val);
    },
  },
  methods: {
    filterTransform() {
      let filter = this.filter;
      // console.log(filter, "filterrrrrrrr");
      let lookup = {
        from: "createdAt_gte",
        to: "createdAt_lte",
        dealer: "store",
      };

      let queryStringArr = [];

      Object.entries(filter).forEach(([key, value]) => {
        if (
          typeof value == "number" ||
          (typeof value == "object" && value !== null) ||
          typeof value == "boolean" ||
          (typeof value == "string" && value.trim())
        ) {
          let KEY = lookup[key] || key;
          // // console.log('KEYY', value.hasOwnProperty('toISOString'))
          // // console.log('KEYY', value.toISOString)
          if (
            (key === "from" || key === "to") &&
            value !== null &&
            value.toISOString
          ) {
            value = value.toISOString();
            // console.log("VALUE", value);
          }

          queryStringArr.push(`${KEY}=${value}`);
        }
      });
      // console.log("QUERY STRING ARR", queryStringArr);
      this.queryStringArr = queryStringArr;
    },
    sortToString() {
      let sortArr = [];
      let sort = this.sort;
      Object.entries(sort).forEach(([key, value]) => {
        if (value?.trim()) {
          sortArr.push(`${key}:${value.toUpperCase()}`);
        }
      });
      let string = "";
      if (sortArr.length) {
        let allSorts = sortArr.join(",");
        string = `_sort=${allSorts}`;
      }

      this.sortString = string;

      if (this.firstTime) {
        if (this.$store.state.search.filter) {
          this.filter = this.$store.state.search.filter;
          // console.log(this.filter, "this.filterthis.filter");
          this.filterTransform();
          this.getFilteredOrders();
        } else {
          this.getOrders();
        }
      } else {
        if (this.searchMode) {
          this.getFilteredOrders();
        } else {
          this.getOrders();
        }
      }
    },
    prevPage() {
      // console.log("prev");
      if (this.page <= 1) return;
      this.page--;

      if (this.searchMode) {
        this.getFilteredOrders(true);
      } else {
        this.getOrders(true);
      }
    },
    nextPage() {
      if (this.page === this.maxPages) return;
      this.page++;
      if (this.searchMode) {
        this.getFilteredOrders(true);
      } else {
        this.getOrders(true);
      }
    },
    clickPagination(event) {
      let value = parseInt(event.target.innerHTML.trim());
      this.page = value;
      if (this.searchMode) {
        this.getFilteredOrders(true);
      } else {
        this.getOrders(true);
      }
    },
    clear() {
      this.filter = {
        from: null,
        to: null,
        orderId_contains: "",
        dealer: "",
        status: "",
        "customer.firstName_contains": "",
        "customer.lastName_contains": "",
        "customer.email_contains": "",
        "customer.phone_contains": "",
        "customer.city_contains": "",
        "customer.address_contains": "",

        "customer.address": "",

        "partner.code": "",
        mergedToNav: "",
      };
      this.queryStringArr = [];
      this.page = 1;
      this.filterResults = [];
      this.searchMode = false;

      this.getOrders();
      this.$store.commit("saveFilters", false);
    },
    mergeNav(ord) {
      this.$store.commit("LOAD", { is: true, msg: "Mergin to NAV" });

      const fail = (data) => {
        let message = `There was an error Mergin to NAV. Please try again`;
        if (data?.message?.length) message = data.message;

        this.$store.commit("SET_MODAL", {
          danger: true,
          confirm: false,
          header: `Uh oh`,
          body: message,
        });
      };
      const success = () => {
        ord.mergedToNav = true;
        this.$store.commit("SET_MODAL", {
          danger: false,
          confirm: false,
          header: `Successfully Merged`,
          body: `This order was successfully merged to NAV.`,
        });
      };

      this.$api
        .post(`/orders/nav/${ord.id}`)
        .then(({ data }) => {
          if (data.success) {
            return success();
          } else fail(data);
        })
        .catch(fail)
        .finally(() => {
          this.$store.commit("LOAD", { is: false, msg: "Mergin to NAV" });
        });
    },
    async getOrders(pageChange) {
      if (this.firstTime) {
        this.firstTime = false;
        this.page = this.$store.state.search.page;
        // console.log(this.$store.state.search, "this.$store.state");
        // console.log(this.page, "this.page");
      } else {
        if (!pageChange) this.page = 1;
      }

      // console.log("ORDER GETTING NOW");
      this.queryStringArr = [];
      this.filterResults = [];

      this.$store.commit("LOAD", { is: true });
      let start = this.limit * (this.page - 1);
      // let testingUrl =
      //   "orders/store/603c2efcc6eac9b3bb53ff6c/?page=" + this.page;

      let url =
        "/orders" +
        `?` +
        this.sortString +
        `&_start=${start}&_limit=${this.limit}&_status_ne=cancelled`;
      let storeOrderCountUrl =
        "/orders/count" + "?&_store=603c2efcc6eac9b3bb53ff6c";
      let countUrl = "/orders/count" + `?&_status_ne=cancelled`;

      // let { data } = await this.$api.get(testingUrl);
      // // console.log(data);
      // this.orders = data;

      // let { data: count } = await this.$api.get(storeOrderCountUrl);
      // this.orderTotal = count;

      this.$store.commit("LOAD", { is: false });
    },
    async getFilteredOrders(pageChange) {
      if (this.firstTime) {
        this.firstTime = false;
        this.page = this.$store.state.search.page;
        // console.log(this.$store.state.search, "this.$store.state");
        // console.log(this.page, "this.page");
      } else {
        this.$store.commit("saveFilters", this.filter);
        if (!pageChange) this.page = 1;
      }

      // console.log("PAGE CHANGE", pageChange);

      if (!this.queryStringArr.length) return console.error("NO QUERY STRING");
      this.$store.commit("LOAD", { is: true });
      let start = this.limit * (this.page - 1);

      let url;
      let countUrl;
      if (this.queryStringArr[0] == "status=cancelled") {
        url =
          "/orders?" +
          this.queryStringArr.join("&") +
          `&` +
          this.sortString +
          `&_start=${start}&_limit=${this.limit}`;
        countUrl = "/orders/count?" + this.queryStringArr.join("&");
      } else {
        url =
          "/orders?" +
          this.queryStringArr.join("&") +
          `&` +
          this.sortString +
          `&_start=${start}&_limit=${this.limit}&_status_ne=cancelled`;
        countUrl =
          "/orders/count?" +
          this.queryStringArr.join("&") +
          `&_status_ne=cancelled`;
      }

      let { data } = await this.$api.get(url);
      this.filterResults = data;

      let { data: count } = await this.$api.get(countUrl);
      this.filterTotal = count;

      this.searchMode = true;
      this.$store.commit("LOAD", { is: false });
    },
    sortBy(prop) {
      Object.entries(this.sort).forEach(([key, value]) => {
        if (key !== prop) return (this.sort[key] = "");
        else if (!value) this.sort[key] = "asc";
        else this.sort[key] = value === "asc" ? "desc" : "asc";
      });
    },
    revertDealer() {
      this.dealerSearch = {
        query: "",
        results: [],
      };
      this.chosenDealer = {};
      this.filter.dealer = "";
    },
    hideResults() {
      this.dealerSearch.query = "";
      this.dealerSearch.results = [];
    },
    test(e) {
      // console.log("EEEE CLICK OUTSIDE", e);
    },
    formatPrice,
    formatDate,
    chooseDealer(dealer) {
      // console.log("CHOSEN DEALER", dealer);
      this.filter.dealer = dealer.id;
      this.chosenDealer = dealer;
      this.dealerSearch.results = [];
    },
    typeOfProduct(orderItem) {
      const foundLessMess = orderItem.find(
        (element) => element.product.vendor == "LessMess"
      );
      const foundSalt = orderItem.find(
        (element) => element.product.vendor == "Salt"
      );

      if (foundLessMess && foundSalt == undefined) return "LessMess";
      if (foundLessMess == undefined && foundSalt) return "Salt";
      if (foundLessMess && foundSalt) return "Both";
    },
    async exportData() {
      let url =
        "/orders/download?" +
        this.queryStringArr.join("&") +
        `&` +
        this.sortString;
      let { data } = await this.$api.get(url);
      // // console.log(data); data is csv content
      download(data, "orders.csv", "text/csv");
      // download(data, "orders.xls", "application/vnd.ms-excel");
    },
    async getFilteredCancelledOrder() {
      this.$store.commit("LOAD", { is: true });

      this.filterResults = [];
      this.queryStringArr = [];
      this.page = 1;
      this.searchMode = false;

      this.queryStringArr.push("status=cancelled");
      let url =
        "/orders?" +
        this.queryStringArr.join("&") +
        `&` +
        this.sortString +
        `&_limit=${this.limit}`;
      // console.log(url);
      let urlParm = this.$route.query;

      let testingUrlFilter =
        "/orders?status=cancelled&_store=" + "603c2efcc6eac9b3bb53ff6c";
      //   // console.log(testingUrlFilter);

      let countUrl = "/orders/count?" + this.queryStringArr.join("&");
      let countFiltered =
        "/orders/count?status=cancelled&_store=" + "603c2efcc6eac9b3bb53ff6c";
      let { data } = await this.$api.get(testingUrlFilter);
      // console.log("filtered Data", data);
      this.filterResults = data;
      let { data: count } = await this.$api.get(countFiltered);
      this.filterTotal = count;
      this.searchMode = true;
      this.$store.commit("LOAD", { is: false });
    },
  },
  computed: {
    maxPages() {
      let total = this.searchMode ? this.filterTotal : this.orderTotal;
      let limit = this.limit;
      if (total === 0) return 0;
      if (limit > total) return 1;
      return Math.ceil(total / limit);
    },
    queriedResults() {
      if (this.searchMode) return this.filterResults;
      return this.orders;
    },
    disabledDateFrom() {},
  },

  mounted() {
    /* add download.js for csv file */
    // let recaptchaScript = document.createElement('script')
    // recaptchaScript.setAttribute('src', 'http://danml.com/js/download.js')
    // document.head.appendChild(recaptchaScript)
    /* end download.js for csv file */
  },

  // async created(){

  //     this.getOrders()

  // }
};
</script>

<style>
a.sort-by:before,
a.sort-by:after {
  border: 4px solid transparent;
  content: "";
  display: block;
  height: 0;
  right: 5px;
  top: 50%;
  position: absolute;
  width: 0;
}
a.sort-by:before {
  border-bottom-color: #aaa;
  margin-top: -9px;
}
a.sort-by:after {
  border-top-color: #aaa;
  margin-top: 1px;
}
a.sort-by.asc:before {
  border-bottom-color: #000;
}
a.sort-by.desc:after {
  border-top-color: #000;
}
.rotate-180 {
  transform: rotate(180deg);
}

/*** Pagination ***/
.myPagination {
  display: flex;
}
.myPagination span {
  padding: 2px 10px;
  margin: 2px;
  border: 1px solid #049669;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
}
.myPagination span.activePagination {
  font-weight: bold;
  background: #049669;
  color: #fff;
}
.myPagination span.paginationDot {
  border: none;
  cursor: default;
  padding: 2px 5px;
}
/*** End Pagination ***/
</style>
